import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Text from "../../global/input/Text";
import Button from "../../global/input/Button";
import { useDispatch, useSelector } from "react-redux";
import { setNavigation } from "../../../redux/slices/navigationSlice";
import { setUser } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router";
import Checkbox from "../../global/input/Checkbox";
import { setEmails, createSchool } from "../../../redux/slices/collegeSlice";
import StaticSelect from "../../global/input/StaticSelect";
import displaySvgIcon from "../../../utilities/displaySvgIcon";
import { useParams } from "react-router-dom";
import toTitleCase from "../../../utilities/toTitleCase";
import nashvilleCollege from "../../../assets/images/college-nashville.png";
import universityCollege from "../../../assets/images/college-university.png";
import ivyCollege from "../../../assets/images/college-ivy.png";

const ImageMap = {
  "Nashville Community": nashvilleCollege,
  "University College": universityCollege,
  "Ivy-League University": ivyCollege,
};

const SchoolNewContent = () => {
    // Access the dynamic parameter from the URL
  let { value } = useParams();

  console.log("School Value:", value);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const collegeData = useSelector((state) => state.college.schools);
//   const value = collegeData.length;

  const collegeOptions = collegeData.filter((school, index, self) => 
  self.findIndex((s) => s.label === school.label) === index
);

  /**
   * Create a selected school object
   * @param {*} items
   * @returns
   */
  const getDefaultValues = (items) => {
    // const filteredData = items.filter((item) => {
    //   // console.log("College1:", item);
    //   // console.log("Value1:", value);
    //   if (item.value === value.toString()) {
    //     return true;
    //   }
    //   return false;
    // });

    // console.log("filteredData:", filteredData[0]);
    // const selectedData = filteredData.length > 0 ? filteredData[0] : {};

    const defaultValues = {
    //   schoolName: {},
    //   {
    //     label: selectedData.label,
    //     value: selectedData.value,
    //   },
      userType: {
        label: "Instructor",
        value: 1,
      },
    //   email: selectedData.email,
      default: false,
      defaulted: false,
      validated: false,
    };
    // console.log("Default Values:", defaultValues);
    return defaultValues;
  };


  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: getDefaultValues(collegeOptions),
  });

  const userOptions = [
    {
      value: "1",
      label: "Instructor",
      type: "instructor",
    },
    {
      value: "2",
      label: "Student",
      type: "student",
    },
  ];

  /**
   * React-Select college selector handler
   * @param {*} data
   * @param {*} event
   */
  const collegeSelectHandler = async (data, event) => {
    // console.log("CollegeSelected:", data);
    setValue("schoolName", data);
  };

  /**
   * React-Select userType handler
   * @param {*} data
   */
  const userTypeSelectHandler = (data) => {
    // console.log("UserType election Data:", data);
    setValue("userType", data);
  };

  /**
   * New react-select dropdown icon function
   * @param {*} svg
   * @returns
   */
  const DropdownIndicator = (svg) => svg;

  const handleSubmitForm = async (data, event) => {
     // Prevent the default form submission behavior
     event.preventDefault();
     // Stop the event propagation to prevent it from bubbling up
     event.stopPropagation();
     
     // const newContent = "AccountSettingsContent";
    const page = "Account";
    const path = "/account/settings";

    // console.log("Signin Data:", data);

    const newSchoolData = {
      value: value,
      label: data.schoolName.label,
      role: toTitleCase(data?.userType?.label),
      email: data.email,
      default: data.default,
      defaulted: data.default,
    //   validated: data.validated,
    };
    
    console.log("New School Data:", newSchoolData);
    // // dispatch school:
    const newState = await dispatch(createSchool({newData: newSchoolData}));
    const newDataObject = newState?.payload?.newData;
    console.log("NEWSTATE:", newDataObject);
    const newDefaultData = newDataObject?.defaultSchool;
    console.log("AddSchool NewUser:", newDefaultData );

   
if (newDataObject?.value && newDataObject.default === true) {
    dispatch(setUser({
      value: newDataObject.value,
      email: newDataObject.email,
      schoolName: newDataObject.label,
      schoolImage: ImageMap[newDataObject.label],
      userType: newDataObject.role,
      default: newDataObject.default,
      defaultSchool: {
        email: newDataObject.email,
        schoolName: newDataObject.label,
        schoolImage: ImageMap[newDataObject.label],
        userType: newDataObject.role,
        value: newDataObject.value,
      },
    }));
}
   
    const navResponse = await dispatch(setNavigation({ page, path }));
    navigate(`${process.env.PUBLIC_URL}${navResponse.payload.path}`);
  };

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className="school-edit-content"
    >
      <div className="header">
        <h3 className="title">Add new profile</h3>
      </div>

      <div className="login-group">
        <div className="form-group">
          <StaticSelect
            labelClass="label-element"
            isMulti={false}
            placeholder="Type in your institution"
            name="schoolName"
            label="Institution"
            options={collegeOptions}
            control={control}
            DropdownIndicator={() =>
              DropdownIndicator(displaySvgIcon("dropdown"))
            }
            card
            selectHandler={collegeSelectHandler}
            required={true}
          />

          <StaticSelect
            labelClass="label-element"
            isMulti={false}
            placeholder="select one"
            name="userType"
            label="Role"
            options={userOptions}
            control={control}
            DropdownIndicator={() =>
              DropdownIndicator(displaySvgIcon("dropdown"))
            }
            card
            selectHandler={userTypeSelectHandler}
            required={true}
          />
          <Text
            displayType="row"
            register={register}
            className="form-input"
            id="formGroupEmail"
            testId="email"
            label="Email address"
            labelClass="label-element"
            name="email"
            required={true}
            placeholder=".edu"
          />

          <div className="row">
            <Button
              type="button"
              label={getDefaultValues(collegeOptions).validated ? "Verified" : "Get Verification"}
              variant="attention"
              size="medium"
              className={`adopt-btn ${getDefaultValues(collegeOptions).validated ? "verified" : "verify" }`}
              disabled={!getDefaultValues(collegeOptions).validated}
            />
          </div>

          <Checkbox
            displayType="row"
            register={register}
            formLabel="form-label"
            className={"form-input"}
            id="formGrouptermAgreement"
            testId="default"
            label="set as default"
            labelClass="label-element"
            name="default"
            required={false}
            displayQuestion={true}
          />
        </div>
        <footer>
          <Button
            type="button"
            displayType="segment"
            label="Cancel"
            // variant="attention"
            // size="medium"
            className="left adopt-btn"
            newContent="AccountSettiingsContent"
            newPage="Accountt"
            newPath="/account/settings"
          />
          <Button
            name="school-info"
            type="submit"
            label="Save"
            variant="attention"
            size="medium"
            className="right adopt-btn"
          />
        </footer>
      </div>
    </form>
  );
};

export default SchoolNewContent;