import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/slices/userSlice";

const StudentAccessContent1 = (props) => {
  const PriceMsgMap = {
    student: {
      etext: {
        inclusive: "$33.97 4mo access",
        bookstore: "$43.97 4mo access",
      },
      bundle: {
        inclusive: "$89.00 / 18wk access",
        bookstore: "$119.00 / 18wk access",
      },
    },
    instructor: "Instructor use only",
  };

  const userData = useSelector((state) => state.user);
  //   useEffect(()=> {
  //     dispatchEvent(setUser(courseType: ))
  //   }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 80);
  }, []);

  return (
    <>
      <section className="student-access">
        <p>
          Your school and bookstore has an inclusive access deal with Pearson
          that allows for easier first-day-of-school access and potentially
          lower prices for your students.
        </p>

        <p>
          If you select the inclusive access, students will still have the
          opportunity to opt out of the delivery system, typically before the
          add/drop date of your school.
        </p>
      </section>
      <section className="choose-type">
        <div>
          <h3>Inclusive access</h3>
          <p>
            Price is added to classes student fee. Not only is it less
            expensive, but students have access on day 1
          </p>
          <button
            name="inclusive"
            className="primary"
            onClick={props.createCourse}
          >
            {userData.courseType == "student" && userData.packageType == "eText bundle" ? PriceMsgMap[userData.courseType].bundle.inclusive
             : (userData.courseType == "student" && userData.packageType == "eTextbook" ? PriceMsgMap[userData.courseType].etext.inclusive : PriceMsgMap[userData.courseType] )}
             {/* : (userData.courseType == "instructor" ? PriceMsgMap[userData.courseType].instructor : "")} */}
          </button>
        </div>
        <div>
          <h3>Get from the bookstore</h3>
          <p>Students purchase through the bookstore. 2 week temp access</p>
          <button
            name="bookstore"
            onClick={props.createCourse}
            className="primary"
          >
            {userData.courseType == "student" && userData.packageType == "eTextbook"
              ? PriceMsgMap[userData.courseType].etext.bookstore 
              : (userData.courseType == "student" && userData.packageType == "eText bundle" ? PriceMsgMap[userData.courseType].bundle.bookstore : PriceMsgMap[userData.courseType])}
          </button>
        </div>
      </section>
    </>
  );
};

export default StudentAccessContent1;
