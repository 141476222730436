import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import adjunctUser from "../../../../redux/data/adjunctUserData";
import universityLogo from "../../../../assets/images/lms-college-link-account-logo.png";
import Button from "../../../global/input/Button";

const LinkAccount = (props) => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  
  useEffect(() => {
    let newUserData;
    if (props.userType === 'adjunct') {
      newUserData = {...adjunctUser};
    } else {
      newUserData = {...userData}
    }
  }, [props]) 

  return (
    <form
      className="link-account"
      //   onSubmit={handleSubmit(handleSubmitForm)}
    >
      <div className="header">
        <h3 className="title">{`Welcome back${
          userData?.firstName ? ", " + userData?.firstName : ""
        }`}</h3>
        <p>
          Is this the Pearson account you want to link to your Canvas account
        </p>
      </div>{" "}
      {/* HEADER */}
      <div className="row image-container">
        <div className="user-container">
          <div className="avatar" />
          <div className="account-info">
            <p>{userData?.firstName + " " +userData?.lastName}</p>
            {/* <p>{userData?.userType}</p> */}
            <p>Instructor</p>
            <p>{userData?.lmsCourseName}</p>
            <p>{userData?.lmsSchoolName}</p>
          </div>
        </div>
        {userData?.schoolImage ? (
          <img width="358px" src={universityLogo} alt={"school"} />
        ) : (
          <></>
        )}
      </div>
      <footer>
        <div className="row">
          <div className="col">

            {props.userType === 'adjunct' ? 
              <>
                <Button
                  type="button"
                  displayType="segment"
                  label="Yes, link the accounts"
                  variant="attention"
                  size="medium"
                  className="adopt-btn left"
                  newContent="LinkingAccount"
                  newPage="Start"
                  newPath="/adjunct/lms/linking"
                />
                <a href="!#" style={{'display': 'block', 'textAlign': 'center', 'margin': '16px'}}>No, this is not the right account</a>
              </> 
              : 
              <Button
                type="button"
                displayType="segment"
                label="Yes, link the accounts"
                variant="attention"
                size="medium"
                className="adopt-btn left"
                newContent="LinkingAccount"
                newPage="Start"
                newPath="/lms/linking"
              />
            }
          </div>
        </div>
        {
          props.userType != 'adjunct' ?
            <Button
              label="No, I need a different account"
              variant="attention"
              size="medium"
              className="adopt-btn right disabled"
              disabled={true}
              //   clickHandler={handleSubmitForm}
              // newContent="ProductContent1"
              // newPage="CourseSetup"
              // newPath="/setup/product"
            /> 
          : 
            <></>
        }
      </footer>
    </form>
  );
};

export default LinkAccount;
