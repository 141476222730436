import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import displaySvgIcon from "../../utilities/displaySvgIcon";
import { useDispatch, useSelector } from "react-redux";
import UserIcon from "./UserIcon";
import Account from "./modals/Account";
import { setNavigation } from "../../redux/slices/navigationSlice";

const NavbarLaunch = () => {
  // console.log("User Data:", userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const [showAccountModal, setShowAccountModal] = useState(false);

  const handleAccountProfile = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setShowAccountModal(!showAccountModal);
    // console.log("Account Profile clicked");
  };

//   const handleSignIn = async (event) => {
//     event.preventDefault();
//     event.stopPropagation();

//     const path = "/account/signin";
//     const page = "Signin";
//     const navResponse = await dispatch(setNavigation({ path, page }));
//     // console.log("Response:", navResponse);
//     navigate(`${process.env.PUBLIC_URL}${navResponse.payload.path}`);
//   };

  return (
    <div className="nav lms-launch">
      {showAccountModal ? (
        <Account setShowAccount={setShowAccountModal} />
      ) : (
        <></>
      )}
      <div className="logo">{displaySvgIcon("pearson-logo")}</div>
      <ol>
        <li className="none" role="presentation" aria-current="false">
       
            <button className="disabled" disabled
            >
              help
            </button>
        </li>
      </ol>
    </div>
  );
};

export default NavbarLaunch;
