import React from "react";
import { useDispatch, useSelector } from "react-redux";

const CourseSetup = ({ children }) => {
  const dispatch = useDispatch();

  const userSchool = useSelector((state) => state.user).schoolName;
  // console.log("UserSchool:", userSchool);

  const navigationData = useSelector((state) => state.navigation);
  const helpImage = navigationData.helpButton;
  // Inline styles for the helpImage
  const helpImageStyles = {
    position: "fixed",
    top: "45%",
    left: "0",
    zIndex: "100",
  };

  return (
    // <div className="image-cover">
    <section
      className={`course-setup ${
        userSchool.indexOf("Nashville") > -1
          ? "nashville"
          : userSchool.indexOf("Ivy-League") > -1
          ? "ivyleague"
          : ""
      }`}
    >
      {children}
      {helpImage && (
        <img src={helpImage} alt="Help button" style={helpImageStyles} />
      )}
    </section>
    //   </div>
  );
};

export default CourseSetup;
