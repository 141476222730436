
import { useDispatch, useSelector } from "react-redux";
import Button from "../../global/input/Button";
import { useNavigate } from "react-router";
import { setNavigation } from "../../../redux/slices/navigationSlice";

const CourseTypeContent1 = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const clickHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let newPath = "/setup/product";
        const path = newPath;
        const response = await dispatch(setNavigation({ path }));
        navigate(`${process.env.PUBLIC_URL}${response.payload.path}`);
      };
    return(
        <div className="page-layout">
            <h1>Set up title</h1>

            <h2>Adoption type</h2>

            <p className="step-instructions">What type of course are you creating?</p>

            <section className="choose-type">
                <div>
                    <h3>Student-use</h3>
                    <p>Use this course type for student enrollment.</p>
                    <button name="student" className="primary" onClick={clickHandler}>Set up Student-use Course</button>
                </div>
                <div>
                    <h3>Instructor-use</h3>
                    <p>Not for student enrollment. Use this course type to organize your course structure and customize assignments. Some MyLab series support conversion of Instructor-use Courses to Coordinator Courses.</p>
                    <button name="instructor" className="primary" onClick={props.chooseAccessType}>Set up Instructor-use Course</button>
                </div>
            </section>
        </div>
    )
}

export default CourseTypeContent1