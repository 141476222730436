import React from 'react';
import Button from "../../../../global/input/Button";
import { useDispatch, useSelector } from "react-redux";

const AddSchoolProfile = ({label, setShowAccount, setDisplayToggle}) => {
  const collegeData = useSelector((state) => state.college.schools);
  const value = collegeData.length;
 
  const handleClick = (event) => {
    // event.preventDefault();
    // event.stopPropagation();
    setShowAccount(false);
    setDisplayToggle(false);  
  }
    return (
        <footer className="modal-footer">
        <div className="row">
          <div className="col">
            <Button
              type="button"
              displayType="segment"
              label={label}
              // variant="attention"
              // size="medium"
              className="modal-toggle-btn"
              // setShowModal={handleClick}
              newContent="SchoolNewContent"
              newPage="School"
              newPath={`/school/new/${value}`}
            />
          </div>
        </div>
      </footer>
    );
};

export default AddSchoolProfile;