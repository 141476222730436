import React from "react";

const EditButton = ({ handler, label, college }) => {
  const className = label.split(" ")[0];
  return (
    <span>
      <button
        type="button"
        className={`edit-btn ${className}`}
        {...(handler && college && {
          onClick: (e) => handler(e, college),
        })}
      >
        {label}
      </button>
    </span>
  );
};

export default EditButton;
