import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import displaySvgIcon from "../../utilities/displaySvgIcon";
import { useDispatch, useSelector } from "react-redux";
import UserIcon from "./UserIcon";
import Account from "./modals/Account";
import { setNavigation } from "../../redux/slices/navigationSlice";
// import Avatar from "../../../src/assets/images/Account.png";
import Avatar from "../../../src/assets/images/instructor.png";

// assets/images/avatar.png";

const LmsCourseHeader = () => {
  // console.log("User Data:", userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const [showAccountModal, setShowAccountModal] = useState(false);

  const handleAccountProfile = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setShowAccountModal(!showAccountModal);
    // console.log("Account Profile clicked");
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const path = "/account/signin";
    const page = "Signin";
    const navResponse = await dispatch(setNavigation({ path, page }));
    // console.log("Response:", navResponse);
    navigate(`${process.env.PUBLIC_URL}${navResponse.payload.path}`);
  };
  return (
    <div className="lms-course-header">
      {/* <div className="logo">{displaySvgIcon("pearson-logo")}</div> */}
      <div className="header-title">MyLab Math</div>
      <ol>
        <li className="none" role="presentation" aria-current="false">
          <span className="right-nav-title">Student view</span>
        </li>
        <li className="none" role="presentation" aria-current="false">
          <span>{displaySvgIcon("toggle-header")}</span>
        </li>
        <li className="none" role="presentation" aria-current="false">
          <span className="lms-avatar-group"><img style={{borderRadius: "50px", height: "32px", marginRight: "6px"}} src={Avatar} alt="Account Avatar" className="avatar-image" />{displaySvgIcon("gray-dropdown")}</span>
        </li>
        {/* <li className="none" role="presentation" aria-current="false">
          {userData?.signedIn ? (
            <button onClick={handleAccountProfile}>
              <UserIcon userData={userData} />
            </button>
          ) : (
            <button onClick={handleSignIn}>sign in</button>
          )}
        </li> */}
      </ol>
    </div>
  );
};

export default LmsCourseHeader;
