import React from "react";
import { setUser } from "../slices/userSlice";
import nashvilleCollege from "../../assets/images/college-nashville.png";
import universityCollege from "../../assets/images/college-university.png";
import ivyCollege from "../../assets/images/college-ivy.png";
import { useDispatch, useSelector } from "react-redux";

const ImageMap = {
  "Nashville Community": nashvilleCollege,
  "University College": universityCollege,
  "Ivy-League University": ivyCollege,
};

export const getCollegeReducer = (state, action) => {
  return state; // Assuming 'user' is the key for user state in Redux store
};

export const setDefaultCollege = (state, action) => {
  let newUser = {};
  console.log("Set Default College:", action.payload);
  console.log("State:", state);
  const { collegeValue, college } = action.payload;
  if (college) {
    // Dispatch the setUser action with the default college data
    const { value, label, role, email, defaulted } = college;
    newUser = {
      value,
      schoolName: label,
      userType: role,
      email,
      schoolImage: ImageMap[label],
      default: defaulted,
    };
    console.log("New User:", newUser);
    // state.dispatch(setUser(newUser));
  }

  // Return a new state object with updated schools array
  return {
    ...state,
    /* This part may not be doing anything*/
    schools: state.schools.map((school) => {
      if (school.value === collegeValue) {
        return { ...school, default: true, defaulted: true };
      } else {
        console.log("ElseCollege:", school);
        // const newSchoolObj =  { ...school, default: false};
        // newSchoolObj.defaulted = false;
        return { ...school, default: false, defaulted: false };
      }
    }),
    /**********************/
    newUser,
  };
};
// Set Validated to true for the selected school
export const updateValidatedSchools = (state, action) => {
  const { labelsToUpdate, value } = action.payload;
  state.schools = state.schools.map((school) => {
    if (school.value === value && labelsToUpdate.includes(school.label)) {
      return { ...school, validated: true };
    } else {
      return school;
    }
  });
};
// export const updateValidatedSchools = (state, action) => {
//   const { labelsToUpdate } = action.payload;
//   state.schools = state.schools.map((college) => {
//     if (labelsToUpdate.includes(college.label)) {
//       return { ...college, validated: true };
//     } else {
//       return college;
//     }
//   });
// };

export const setCollegeEmails = (state, action) => {
  const { collegeEmails } = action.payload;
  // console.log("College Emails:", collegeEmails);
  state.schools = state.schools.map((college) => ({
    ...college,
    email: collegeEmails[college.label] || college.email,
  }));
};

export const updateSchoolProperties = (state, action) => {
  const { newData } = action.payload;
  console.log("Payload:", action.payload);
  let newDefaultUser = {};
  let newUser = {};

  // Call setDefaultCollege if newData.default is true
  if (newData.default === true) {
    newData.defaulted = newData.default;

    // Get results from SetDefaultCollege
    newUser = setDefaultCollege(state, {
      payload: { collegeValue: newData.value, college: newData },
    }).newUser;
    console.log("Update  after setDefaultCollege:", newUser);

    // Create a new school record
    newDefaultUser = {
      value: newUser.value,
      schoolName: newUser.schoolName,
      userType: newUser.userType,
      email: newUser.email,
      schoolImage: newUser.schoolImage,
      // default: newUser.default,
      // validated: false,
      // Add other properties as needed
    };

    // Add newUser to newData
    newData.defaultSchool = newDefaultUser;

    console.log("NewDefaultData after setDefaultCollege:", newData);
  } // if

  // Set default to false for all schools if newData.default is true
  state.schools = state.schools.map((school) => ({
    ...school,
    default: newData.default === true ? false : false,
    defaulted: newData.default === true ? false : false,
    defaultSchool: {},
  }));

  // Iterate through the schools array
  state.schools = state.schools.map((school) => {
    // Check if the value of the school matches the provided value
    if (school.value === newData.value) {
      // If matched, update the properties of the school with newData
      return {
        ...school,
        ...newData,
      };
    } else {
      // If not matched, return the school without any changes
      return school;
    }
  });
  // Create a new school record
  //  const newSchool = {
  //   value: newUser.value,
  //   schoolName: newUser.schoolName,
  //   userType: newUser.userType,
  //   email: newUser.email,
  //   schoolImage: newUser.schoolImage,
  //   default: newData.default,
  //   // validated: false,
  //   // Add other properties as needed
  // };

  // state.newUser = newUser;
  return state;
};

export const createSchoolProperties = (state, action) => {
  let { newData } = action.payload;
  let newDefaultUser = {};

  console.log("Payload:", action.payload);
  let newUser = {};

  // Call setDefaultCollege if newData.default is true
  if (newData.default === true) {
    newData.defaulted = newData.default;

    // Get results from SetDefaultCollege
    newUser = setDefaultCollege(state, {
      payload: { collegeValue: newData.value, college: newData },
    }).newUser;

    // Create a new school record
    newDefaultUser = {
      value: newUser.value,
      schoolName: newUser.schoolName,
      userType: newUser.userType,
      email: newUser.email,
      schoolImage: newUser.schoolImage,
      // Add other properties as needed
    };

    // Add newUser to newData
    newData.defaultSchool = newDefaultUser;

    console.log("NewDefaultData after setDefaultCollege:", newData);
  } // if

  /**
   * State.schools.map will only set schools prior to new school:
   * If incoming newData.default is true then set school.default
   * and .defaulted to false, else if index and newData.value not equal
   * */
  state.schools = state.schools.map((school, index) => {
    // console.log("SCHOOL:", school, "NEWDATA:", newData);
    // console.log("Index;", index, "Value:", newData.value);
    if (index !== newData.value && newData.default === true) {
      // console.log("Index1:", index)
      // If newData.default is true, set school.default and school.defaulted to the opposite of newData.default
      return {
        ...school,
        default: !newData.default,
        defaulted: !newData.default,
        defaultSchool: {},
      };
    } //if
    else {
      // console.log("Index3:", index)
      return {
        ...school,
      };
    }
  });

  // Create a new school record
  const newSchool = {
    value: newData.value,
    label: newData.label,
    role: newData.role,
    email: newData.email,
    imageName: ImageMap[newData.schoolName],
    default: newData.default,
    defaulted: newData.default,
    validated: false,
    // Add other properties as needed
  };

  // Add the new school record to the schools array
  state.schools.push(newSchool);
  // state.schools.newUser = newUserSchool;

  return state;
};
