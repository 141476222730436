import { forwardRef } from 'react';

const ConnectingToLMS = forwardRef((props, ref) => {
    return(
        <div className="modal setup">
            <h2>Connecting to LMS</h2>
            {props.courseSelected ?
            <ul ref={ref}>
                <li>Creating Pearson course template...</li>
                <li>Copying available assignments...</li>
                <li>Checking customization settings...</li>
            </ul>
            : 
            /* Only one of this is shown at a time - see css animation */
            <ul ref={ref}>
                <li className='hideaway'>Asking for Course List</li>
                <li>Receiving Course List</li>
            </ul>
            }
        </div>
    )
})

export default ConnectingToLMS