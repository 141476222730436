
const AdjunctSetupSteps = ({flow, step}) => {
    return(
        <ul className='step-indicator'>
            <li className={`${step === 0 ? 'selected' : ''}`}>
                <div>Step 1</div>
                <div>Add Course Information</div>
            </li>
            <li className={`${step === 1 ? 'selected' : ''}`}>
                <div>Step 2</div>
                <div>Create Course</div>
            </li>
        </ul>
    )
}

export default AdjunctSetupSteps;