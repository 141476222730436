const getFormattedDate = (dateStr)  => {
    var date = new Date(dateStr);
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = (1 + date.getDate()).toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
}

export default getFormattedDate;