//images
import gearIcon from "../../../assets/images/layouts/dashboard/settings-24.svg";
import image from "../../../assets/images/layouts/dashboard/image-placeholder.svg";
import video from "../../../assets/images/layouts/dashboard/video-placeholder.svg";
import add from "../../../assets/images/layouts/dashboard/add.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import universityBook from "../../../assets/images/biology-flower.png";
import nashvilleBook from "../../../assets/images/biological-science.png";
import ivyLeagueBook from "../../../assets/images/bridging-english.png";

const CourseCard = ({ course, type, flow, ...props }) => {
  const [lmsReady, setLmsReady] = useState(false);
  const navigate = useNavigate();

  const getCTAText = () => {
    switch (type) {
      case "search":
        return "Use this title";
        break;
      case "current":
        return "Launch";
        break;
      default:
        return "Launch";
    }
  };

  const handleConnectToLMS = () => {
    navigate(process.env.PUBLIC_URL + "/lmsConnect");
  }

  useEffect(() => {
    console.log("CourseName:", props.courseName);
  }, [course]);

  return (
    <div className={`dashboard-course-card card-type-${type}`}>
      <div className="course-card-header">
        <h3>
          {/* {type === 'current' ? "New! " : ""} */}
          {/* { console.log("CourseName2:", props.courseName)} */}
          {flow && flow === "lms"
            ? course.lmsTemplateCourse
            : props.courseName
            ? props.courseName
            : course.courseName}
          {/* {props.courseName ? props.courseName : course.courseName} */}
        </h3>
        <button className="gear disabled" disabled>
          <img src={gearIcon} />
        </button>
      </div>
      <div className="course-card-content">
        <div className="course-card-cover">
          {console.log("Course:", course)}
          {type === "current" ? (
            <img
              alt="Course Book"
              src={
                course?.schoolName === "University College"
                  ? universityBook
                  : course.schoolName === "Nashville Community"
                  ? nashvilleBook
                  : ivyLeagueBook
              }
            />
          ) : (
            <img alt="Course Book" src={course.imagePath} />
          )}
        </div>

        <div className="course-card-info">
            {console.log("Type:", type)}
          {/* <p className='course-card-book-title'>{flow === "lms" ? course.lmsTemplateName : course.bookName}</p> */}
          {flow === "lms" ? (
            <p className="course-card-book-title">{course.lmsTemplateName}</p>
          ) : (type === "current" ? (
            <p className="course-card-book-title">
              {course?.schoolName === "University College"
                ? "Campbell Biology"
                : course.schoolName === "Nashville Community"
                ? "Biological Science"
                : "Bridging English"}
            </p>) : <p className="course-card-book-title">{course.bookName}</p>)
          }

          <p className="course-card-dates">
            {flow === "lms"
              ? "Created by " + course.lmsTemplateCreator
              : course.dates}
          </p>
          <button className={flow==="lms"? "lms right adopt-btn disabled" : "primary disabled"} disabled>
            {flow === "lms" ? "Learn more" : getCTAText()}
          </button>
          {type != "search" ? (
            <>
              <span>Additional Resources</span>
              <div className="course-card-additional">
                <button>
                  <div>
                    <img src={image} />
                  </div>
                  <span>ETEXT</span>
                </button>
                <button>
                  <div>
                    <img src={video} />
                  </div>
                  <span>CHANNELS</span>
                </button>
                <button>
                  <div>
                    <img src={add} />
                  </div>
                  <span></span>
                </button>
              </div>
            </>
          ) : null}
        </div>
        {type === "current" ? (
          <div className="course-card-progress">
            <p>Next Steps</p>
            <ul>
              <li>
                <span className={`radio ${props.isAdjunct ? '' : 'radio-checked'}`}></span>
                <p>Adjust course settings</p>
              </li>
              <li>
                <span className={`radio ${props.isAdjunct ? '' : 'radio-checked'}`}></span>
                <p>Create assignments</p>
              </li>
              <li>
                <span className={`radio ${props.isAdjunct ? '' : 'radio-checked'}`}></span>
                <p>Set up Gradebook</p>
              </li>
              {props.isAdjunct === true ? 
                <li>
                  <span className="radio"></span>
                  <p>
                    <button onClick={props.handleOpenInviteStudents}>
                      Invite students
                    </button>
                  </p>
                </li>
              : props.lmsConnect ?
                <li>
                  <span className="radio radio-checked"></span>
                    <p role="button" onClick={handleConnectToLMS} style={{'cursor': 'pointer'}}>
                        Connect to LMS
                      </p>
                </li>
              : 
                <li>
                  <span className="radio"></span>
                    <p>
                      {lmsReady ? 
                      <button onClick={handleConnectToLMS}>
                        Connect to LMS - Ready
                      </button>
                      : 
                      <em style={{ 'color': '#8CA2C0', "fontSize": '18px'}} onClick={() => setLmsReady(true)}>Connect to LMS - Not ready</em>
                      }
                    </p>
                </li>}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CourseCard;
