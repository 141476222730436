import React, {useState, useEffect}  from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const LmsCourseSetup = ({ children, setIsLmsCourseHome }) => {
  const location = useLocation();
  console.log("location.pathname", location.pathname,"isSet:",location.pathname.includes("lms/course/home"));
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const userSchool = useSelector((state) => state.user).schoolName;
  // console.log("UserSchool:", userSchool);

  const navigationData = useSelector((state) => state.navigation);
  const helpImage = navigationData.helpButton;
  // Inline styles for the helpImage
  const helpImageStyles = {
    position: "fixed",
    top: "45%",
    left: "0",
    zIndex: "100",
  };

  useEffect(() => {
    // Check if the path includes "lms/course/home"
    if (location.pathname.includes("lms/course/home") === false && setIsLmsCourseHome) {
      // Toggle the rerender flag to force a rerender
      setRerenderFlag(prevFlag => !prevFlag);
      console.log("location.pathname", location.pathname, "isSet:",location.pathname.includes("lms/course/home"));
      setIsLmsCourseHome(false);
    }
  }, [location.pathname]);

  return (
    <section
      className="course-setup"
    >
      {children}
      {helpImage && (
        <img src={helpImage} alt="Help button" style={helpImageStyles} />
      )}
    </section>
  );
};

export default LmsCourseSetup;
