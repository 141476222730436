import React from "react";
import { useForm } from "react-hook-form";
import Button from "../../global/input/Button";
import { useDispatch, useSelector } from "react-redux";

const ValidateContent = () => {
  const userData = useSelector((state) => state.user);
  // console.log("USERData:", userData);

  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      // lastName: {
      //   value: "new",
      //   label: "New",
      // },
    },
  });

  return (
    <main className="validate-content">
      <div className="header">
        <h3 className="title">Welcome, {userData.firstName}</h3>
        <div className="row">
          <section>
            <p>
              {`Would you like us to validate your instructor status at
                ${userData.schoolName}, home of the Golden Lions? `}
            </p>
            <div>
              <h3>Why Validate?</h3>
              <p>
                On approval you will be able to access your institution’s custom
                features, create courses, and access subject resources.
              </p>
            </div>

            <p>
              Without Approval, you can still browse our catalog and view sample
              courses. Some institution features may not be available without
              approval.
            </p>
          </section>
        </div>
      </div>
      <footer>
        <div className="row">
          <div className="col">
            <Button
              type="button"
              displayType="segment"
              label="Skip for now"
              variant="attention"
              size="medium"
              className="adopt-btn"
              newContent="ProductContent1"
              newPage="Product"
              newPath="/setup/product"
              // newContent="ValidateEducatorContent"
              // newPage="Validate"
              // newPath="/validate/educator"
            />
          </div>
        </div>

        <Button
          label="Begin validation"
          variant="attention"
          size="medium"
          className="adopt-btn"
          newContent="ValidateEducatorContent"
          newPage="Validate"
          newPath="/validate/educator"
        />
      </footer>
    </main>
  );
};

export default ValidateContent;
