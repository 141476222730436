import React from 'react';
import Start from "./Start";

const AdjunctLmsLinking = ({children}) => {
    return (
        <>
            <main>
                {children}
            </main>
        </>
    );
};

export default AdjunctLmsLinking;