import React from "react";

const ActiveTag = () => {
  return (
    <div className="active-tag">
      <span className="active-tag-text">Active</span>
    </div>
  );
};

export default ActiveTag;
