import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import displaySvgIcon from "../../../utilities/displaySvgIcon";

/**
 * Description: Multi Value selector
 * @param {*} name: element name
 * @param {*} label: element label
 * @param {*} isMulti: does input take multiple inputs
 * @param {*} options: dropdown options
 * @param {Optional} handler: method to execute OnChange
 * @param {Optional} value: default selected value
 *
 * @returns
 */
const StaticSelect = ({
  isMulti,
  name,
  label,
  labelClass,
  hiddenClass,
  options,
  control,
  DropdownIndicator,
  IconOption,
  card,
  required,
  placeholder,
  selectHandler,
}) => {
  // console.log("StaticSelect:", props);
  // console.log("control", control);

  // Define custom styles for the Select component
  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "24px" /* 150% */,
      letterSpacing: " 0.5px",
      borderRadius: "10px", // Set border-radius to 10px
      border: "1px solid var(--Neutral-10, #486284)", // Set border style
      flex: "1 0 0",
      padding: "5px 12px",
    }),
  };

  // Construct components object conditionally
  const components = {
    DropdownIndicator: DropdownIndicator ? DropdownIndicator : "",
    IndicatorSeparator: () => null,
  };

  // Add IconOption as Option if provided
  if (IconOption) {
    components.Option = IconOption;
  }

  return (
    <div className="form-group">
      <div
        className={`col-auto ${labelClass} ${hiddenClass ? hiddenClass : ""} `}
      >
        {label && label !== undefined ? (
          <label>
            {label}
            <span className="label-icon">
              {displaySvgIcon("question-mark")}
            </span>
          </label>
        ) : (
          <> </>
        )}
      </div>

      <div className="search-select">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              // isClearable // enable isClearable to demonstrate extra error handling
              isSearchable={true}
              isMulti={isMulti}
              name={name}
              placeholder={placeholder}
              options={options}
              className={`basic-multi-select ${card ? "card-select" : null}`}
              classNamePrefix="select"
              required={required}
              // onChange={props.retrieveData ? (data) => props.retrieveData(data) : null}
              styles={customStyles}
              components={components}
              {...(selectHandler && {
                onChange: (data) => selectHandler(data),
              })}
            />
          )}
        />
      </div>
    </div>
  );
};

export default StaticSelect;
