import { useForm } from "react-hook-form";
import React from "react";
import StaticSelect from "../../global/input/StaticSelect";
import { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setNavigation } from "../../../redux/slices/navigationSlice";
import {setUser } from "../../../redux/slices/userSlice";
import displaySvgIcon from "../../../utilities/displaySvgIcon";

const TitleSearchContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pearsonData = useSelector((state) => state.pearson);
  //console.log("Pearson Data:", pearsonData.titles);

  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm({
    // defaultValues: {
    //   status: {
    //     value: "new",
    //     label: "New",
    //   },
    // },
  });

  const titleOptions = pearsonData.titles;

  const { Option } = components;

  /**Launches when a title selection is made */
  const selectHandler = async (data) => {
    // console.log("Selection Data:", data);
    const selectedBook = pearsonData.titles.filter((title) => title.label === data.label);
    // console.log("Selected Book:", selectedBook);
    await dispatch(setUser({ bookName: data.label, bookImagePath: selectedBook[0].imagePath, bookSelected: true }));

    const path = "/title_details";
    const response = await dispatch(setNavigation({ path }));
    console.log("Response:", response);
    navigate(`${process.env.PUBLIC_URL}${response.payload.path}`);
  };

  /**
   * Set the portion of the option label to bold, if it matches the userinput
   * @param {*} label
   * @param {*} userInput
   * @returns
   */
  const getFormattedMatch = (label, userInput) => {
    const escapedInput = userInput.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escapedInput, "gi");
    const replacedLabel = label.replaceAll(
      regex,
      (match) => `<span style="font-weight: 800">${match}</span>`
    );
    return <div dangerouslySetInnerHTML={{ __html: replacedLabel }} />;
  };

  /**
   * Set a new option format for react-select component
   * @param {*} options
   * @returns
   */
  const IconOption = (options) => {
    const userInput = options?.selectProps?.inputValue || "";
    const { data } = options;
    console.log("data", data);
    console.log("userInput", userInput);
    return (
      <Option {...options}>
        <div className="option-container">
          <img
            src={data.imagePath}
            style={{ width: "45px", height: "70px" }}
            alt={data.label}
          />
          <div className="option-details">
            <p>
              {userInput?.length
                ? data.label?.split("").length
                  ? getFormattedMatch(data.label, userInput)
                  : data.label
                : data.label}
            </p>
            <p>{data.authors}</p>
            <p>{data.isbn}</p>
          </div>
        </div>
      </Option>
    );
  };

  const DropdownIndicator = (svg) => svg;

  return (
    <div className="title-search-content">
      <div className="title">Pearson Courseware Adoption</div>
      <div className="builder-group">
        {/* <div className="title">Let’s Build together</div> */}
        <div className="main">
          To begin, tell us what title you want to adopt.
        </div>
        <StaticSelect
          isMulti={false}
          placeholder="Search by title"
          name="titles"
          options={titleOptions}
          control={control}
          DropdownIndicator={() => DropdownIndicator(displaySvgIcon("magnifying-glass"))}
          IconOption={IconOption}
          card
          selectHandler={selectHandler}
        />
      </div>
    </div>
  );
};

export default TitleSearchContent;
