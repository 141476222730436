import React from "react";
import { useDispatch, useSelector } from 'react-redux';

const Start= ({ children }) => {
  const navigationData = useSelector((state) => state.navigation);
  const helpImage = navigationData.helpButton;
//  console.log("HelpImage:", helpImage);

  return (
    <div className="welcome-page">
      {children}
      {helpImage && <img src={helpImage} alt="Help button"  className='getHelp' />}

    </div>
  );
};

export default Start;
