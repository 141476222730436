const Packages = (props) => {
    return(
        <div className='packages'>
            <div className='header'>
                <h3>Mastering/eText Package </h3>
                <span>from $89.99</span>
            </div>
            <div className='header'>
                <h3>eTextbook </h3>
                <span>$43.97 for 4 mo access</span>
            </div>
            <div className='col'>
                <ul>
                    <li className='included'>
                        Anytime, anywhere etext with the Pearson+ app
                    </li>
                    <li className='included'>Search, take notes and access them anywhere</li>
                    <li className='included'>Simpler studying with flashcards</li>
                    <li className='included'>Award winning winning study prep videos</li>
                    <li className='included'>Active learning with lessons and homework</li>
                    <li className='included'>Tailor the content to fit your needs</li>
                    <li className='included'>Access to quiz-banks, instructor resources and slidedecks.</li>
                </ul>

                <button className='primary' data-price="Student Price: $89.00 18wk access" name="eText bundle" onClick={props.chooseCourseType}>Mastering/eText Package</button>
            </div>
            <div className='col'>
                <ul>
                    <li className='included'>
                        Anytime, anywhere etext with the Pearson+ app
                    </li>
                    <li className='included'>Search, take notes and access them anywhere</li>
                    <li className='included'>Simpler studying with flashcards</li>
                    <li>Award winning winning study prep videos</li>
                    <li>Active learning with lessons and homework</li>
                    <li>Tailor the content to fit your needs</li>
                    <li>Access to quiz-banks, instructor resources and slidedecks.</li>
                </ul>
                <button className='primary' data-price="Student Price: $43.97 4mo access" name='eTextbook' onClick={props.chooseCourseType}>eTextbook</button>
            </div>
        </div>
    )
}

export default Packages