import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setUser } from "../../../redux/slices/userSlice";

import Button from "../../global/input/Button";

const ValidateCompleteContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  console.log("USERData:", userData);

  return (
    <form
      className="validate-complete-content"
      //   onSubmit={handleSubmit(handleSubmitForm)}
    >
      <div className="header">
        <h3 className="title">Educator validation</h3>
        {/* HEADER */}
      </div>
      <div className="image-container">
        {userData?.schoolImage ? (
          <img width="358px" src={userData.schoolImage} alt={"school"} />
        ) : (
          <></>
        )}
      </div>
      <div className="row">
        <section>
          <p>Validation complete!</p>
          <p>
            {`Great, you have been verified as an instructor at ${userData?.schoolName}. You can now review and set up Pearson titles and access
            ${userData?.schoolName} specific features.`}
          </p>
          <p>
            If you have any questions, help is just one click away using the Get
            Help tab!
          </p>
        </section>
      </div>
      {/* <section> */}

      {/* </section> */}
      {/* INPUT GROUP */}

      <footer>
        {/* <div className="row">
          <div className="col"> */}
        {/* <Button
              type="button"
              displayType="segment"
              label="Skip for now"
              variant="attention"
              size="medium"
              className="adopt-btn"
              // newContent="ValidateEducatorContent"
              // newPage="Validate"
              // newPath="/validate/educator"
            />
          </div>
        </div> */}

        <Button
          label="Continue"
          variant="attention"
          size="medium"
          className="adopt-btn"
        //   clickHandler={handleSubmitForm}
          newContent="ProductContent1"
          newPage="CourseSetup"
          newPath="/setup/product"
        />
      </footer>
    </form>
  );
};

export default ValidateCompleteContent;
