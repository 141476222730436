import React, { useRef, useState, useEffect } from "react";
import linkingImg from "../../../../assets/images/linking-accounts.png";
import { useNavigate } from "react-router";
import Button from "../../../global/input/Button";
import { useDispatch, useSelector } from "react-redux";
import { setNavigation } from "../../../../redux/slices/navigationSlice";
const LinkingSuccess = (props) => {
  const dispatch = useDispatch();
  // const navData = useSelector((state) => state.navigation);
  const navigate = useNavigate();

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newPath = props.userType === 'adjunct' ? '/adjunct/lms/steps2' : "/lms/setup/product";

    const path = newPath;
    const response = await dispatch(setNavigation({ path }));
    console.log("Response:", response.payload.path);
    navigate(`${process.env.PUBLIC_URL}${response.payload.path}`);
  };

  return (
    <form
      className="linking-success"
      //   onSubmit={handleSubmit(handleSubmitForm)}
    >
      <div className="header">
        <h3 className="title">Account link successful!</h3>
      </div>{" "}
      {/* HEADER */}
      <div className="row image-container" />
      <h3 className="title">
        <span> Now that you have linked your Canvas account to Pearson</span>
        <span>you can add your Pearson title to the course.</span>
      </h3>
      <footer>
        <div className="row">
          <div className="col">
            {/* <Button
              type="button"
              displayType="segment"
              label="Begin title setup"
              variant="attention"
              size="medium"
              className="adopt-btn left"
              newContent="TitleSelectConfirm"
              newPage="Start"
              newPath="/lms/title/confirm"
            /> */}
            
            <Button
              className="adopt-btn left"
              label={props.userType === 'adjunct' ? "Next" : "Begin title setup"}
              variant={props.userType === 'adjunct' ? "primary" : "secondary"}
              size="medium"
              clickHandler={(e) => handleSubmitForm(e)}
            />
            
          </div>
        </div>
      </footer>
    </form>
  );
};

export default LinkingSuccess;
