import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Dashboard = ({children}) => {const dispatch = useDispatch();
    const navigationData = useSelector((state) => state.navigation);
    const helpImage = navigationData.helpButton;
    const userSchool = useSelector((state) => state.user).schoolName;

    return (
        <section className={`dashboard ${userSchool.indexOf("Nashville") > -1 ? 'nashville' : (userSchool.indexOf("Ivy-League") > -1) ? 'ivyleague' : ''}`}>
            {/* {console.log(`IMAGE: ${ userSchool}`)}
            {console.log(`IMAGE: ${ userSchool.indexOf("Ivy-League") > -1 ? "ivyleague" : ""}`)} */}
            {children}
            {helpImage && <img src={helpImage} alt="Help button"  className='getHelp' />}

        </section>
    );
};

export default Dashboard;