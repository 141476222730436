import React from "react";

export const setNavigationReducer = (state, action) => {
  // console.log("State1:", JSON.stringify(state), "Navigation:", action.payload);
  const { page, content } = action.payload;
  if (page !== undefined && content !== undefined) {
    state.page = page;
    state.content = content;
  } else if (page !== undefined) {
    state.page = page;
  } else if (content !== undefined) {
    state.content = content;
  }
};
