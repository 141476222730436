import { createSlice } from "@reduxjs/toolkit";
import {
  getPearsonReducer,
} from "../reducers/pearsonReducer";

// import navbarData from "../data/navbarData";
import pearsonData from "../data/pearsonData";

/********************************
 * Configure Slice with reducers
 * and Export actions and reducer
 *******************************/
const pearsonSlice = createSlice({
  name: "pearson",
  initialState: pearsonData,
  reducers: {
    getPearson: getPearsonReducer,
  },
});

// Note: pearsonSlice.actions automatically creates actions based on our reducer names
export const { 
  getPearson, 
} = pearsonSlice.actions;

// export  reducer, so it can be added to the store
export default pearsonSlice.reducer;