import React from "react";
import displaySvgIcon from "../../../utilities/displaySvgIcon";

const Text = ({
  register,
  className,
  id,
  testId,
  name,
  label,
  value,
  changeHandler,
  ref,
  hiddenClass,
  labelClass,
  displayType,
  type,
  required,
  placeholder,
}) => {
  // Trim whitespace from the beginning and end of the value
  const trimmedValue = value ? value.trim() : "";

  return (
    <>
      {/* <br /> */}
      <div className={displayType}>
        <div
          className={`col-auto ${labelClass} ${
            hiddenClass ? hiddenClass : ""
          } `}
        >
          <label htmlFor={id}>
            {label}
            <span className="label-icon">
              {displaySvgIcon("question-mark")}
            </span>
          </label>
        </div>
        <div className="col">
          <input
            type={type ? type : "text"}
            ref={ref}
            aria-labelledby={id}
            {...register(name)}
            className={`${className} ${hiddenClass ? hiddenClass : ""}`}
            id={id}
            data-testid={testId}
            placeholder={placeholder ? placeholder : undefined}
            name={name}
            defaultValue={trimmedValue}
            required={required ? required : false}
          />
        </div>
      </div>
    </>
  );
};

export default Text;
