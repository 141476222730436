import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../global/input/Button";
import { useDispatch, useSelector } from "react-redux";
import { setNavigation } from "../../../redux/slices/navigationSlice";
import { setUser } from "../../../redux/slices/userSlice";
import { updateSchool } from "../../../redux/slices/collegeSlice";
import { useNavigate } from "react-router";
import displaySvgIcon from "../../../utilities/displaySvgIcon";
import StaticSelect from "../../global/input/StaticSelect";
import universityCollege from "../../../assets/images/college-university.png";
import nashvilleCollege from "../../../assets/images/college-nashville.png";
import ivyCollege from "../../../assets/images/college-ivy.png";
import toTitleCase from "../../../utilities/toTitleCase";

const ImageMap = {
  "University College": universityCollege,
  "Nashville Community": nashvilleCollege,
  "Ivy-League University": ivyCollege,
};

const DescribeUserCollegeContent = () => {
  let defaultCollege = {};
  const school = {
    schoolName: "University College",
    imagePath: universityCollege,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const [userTypeSelected, setUserTypeSelected] = useState(false);
  const [userTypeSubmitted, setUserTypeSubmitted] = useState(false);
  const [userType, setUserType] = useState(null);

  //   const [collegeImage, setCollegeImage] = useState(null);
  //   const [collegeName, setCollegeName] = useState(null);
  const [college, setCollege] = useState(null);

  const userData = useSelector((state) => state.user);
  const collegeData = useSelector((state) => state.college.schools);

  const defaultCollegeOptions = collegeData;

  // make sure college Options are unique
  const collegeOptions = collegeData.filter(
    (school, index, self) =>
      self.findIndex((s) => s.label === school.label) === index
  );
  const userOptions = [
    {
      value: "1",
      label: "I am an instuctor",
      type: "instructor",
    },
    {
      value: "2",
      label: "I am a student",
      type: "student",
    },
  ];

  const getCollege = () => {
    console.log("GETCOLLEGE:", userData);
    // const label = userData?.schoolName;
    // const currentCollege = collegeData.filter(
    //   (college) => college.label === label
    // );
    const userEmail = userData.email;
    let currentCollege = [];

    // Check if userEmail contains a key in ImageMap
    Object.keys(ImageMap).forEach((key) => {
      const domainMatch = `@${key.slice(0, 3).toLowerCase()}`;
      console.log("Domain Match:", domainMatch);
      console.log("User Email:", userEmail);
      if (userEmail.includes(domainMatch)) {
        currentCollege = collegeData.filter((college) => college.label === key);
        console.log("CollegeData:", collegeData, "CurrentCollege1:", currentCollege);
      }
    });

    // If no match found in ImageMap, use userData?.schoolName to filter
    if (currentCollege.length === 0) {
      const label = userData?.schoolName;
      currentCollege = collegeData.filter((college) => college.label === label);
    }
console.log("currentCollege:", currentCollege);
    // Set College:
    defaultCollege = currentCollege[0];
    console.log("Default College:", defaultCollege);
    return defaultCollege;
  };
  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      schoolName: getCollege(),
      // schoolName: getCollege()?.label,
      // schoolImage: getCollege()?.imageName,
    },
  });

  /**
   * React-Select userType handler
   * @param {*} data
   */
  const userTypeSelectHandler = (data) => {
    console.log("UserType election Data:", data);
    setUserType(data.type);
  };

  /**
   * React-Select college selector handler
   * @param {*} data
   * @param {*} event
   */
  const collegeSelectHandler = async (data, event) => {
    console.log("CollegeSelected:", data);
    setCollege(data);
    setValue("schoolName", data);
    const path = "/title_details";
    const page = "Start";
    await dispatch(setNavigation({ path, page }));
  };

  /**
   * UserType Submit Button
   * @param {*} data
   * @param {*} event
   */
  // const handleSubmitUserType = async (data, event) => {
  //   const handleSubmitUserType = async () => {

  //   // console.log("HandleSubmit:", data)
  //   // event.preventDefault();
  //   // event.stopPropagation();
  //   if (userType) {
  //     setUserTypeSubmitted(true);
  //   }

  //   // console.log("Descibe UserType Data:", userType);
  //   await dispatch(
  //     setUser({
  //       userType,
  //     })
  //   );
  // };

  /**
   * College Name Submit Button
   * @param {*} data
   * @param {*} event
   */
  const handleSubmitCollege = async (data, event) => {
    const page = "Validate";
    const path = "/validate";

    // Prevent the default form submission behavior
    event.preventDefault();
    // Stop the event propagation to prevent it from bubbling up
    event.stopPropagation();

    console.log(
      "Describe College  Data:",
      data,
      event,
      "UserData:",
      userData,
      "collegeData:",
      collegeData
    );

    /**
     * Step1 of 6: Choose Default School:
     * There are two University College entries,
     * so we need to find the one that matches the school name.
     */
    let collegeMatch = collegeData.filter(
      (college) => college.label === data?.schoolName.label
    );

    /**
     * Step 2 of 6: Choose Default School: Filter on role, if there is a match
     * Role (initially Instructor, or Admin, but could later be Student)
     **/
    let secondaryCollegeMatch = [];
    if (collegeMatch.length > 1) {
      secondaryCollegeMatch = collegeMatch.filter((college) => {
        console.log(
          "college.role.toLowerCase():",
          college.role.toLowerCase(),
          "userData?.userType.toLowerCase():",
          userData?.userType.toLowerCase(),
          college.role.toLowerCase() === userData?.userType.toLowerCase()
        );

        return college.role.toLowerCase() === userData?.userType.toLowerCase();
      });
    } //if

    // Step 3 of 6: If secondary results found by filtering collegeMatch, store in collegeMatch
    if (
      secondaryCollegeMatch.length > 1 ||
      secondaryCollegeMatch.length === 1
    ) {
      collegeMatch = secondaryCollegeMatch;
    }

    // Step 4 of 6: If there are still matches select the first one as the default
    if (collegeMatch.length > 1 || collegeMatch.length === 1) {
      collegeMatch = collegeMatch[0];
    }
    console.log("College Match:", collegeMatch);
    // switch (data?.schoolName) {

    // Step 5 of 6: Default Selected: Update user record with Default School
    await dispatch(
      setUser({
        schoolName: data?.schoolName.label,
        schoolImage: data?.schoolName.imageName,
      })
    );

    /**
     * Step 6 of 6: Default Selected: Update school slice with  default true
     * (Make sure email and role, match what user entered in user record),
     * validated on next page
     **/
    if (collegeMatch.value) {
      await dispatch(
        updateSchool({
          newData: {
            value: collegeMatch.value,
            email: userData.email,
            imageName: collegeMatch.imageName,
            label: collegeMatch.label,
            role: toTitleCase(userData.userType),
            default: true,
            defaultSchool: {
              email: userData.email,
              schoolImage: collegeMatch.imageName,
              schoolName:  collegeMatch.label,
              userType: toTitleCase(userData.userType),
              value: collegeMatch.value,
            }
          },
        })
      );
    }
    // await dispatch(
    //   updateSchool({newData: {...collegeMatch, default: true, validated: true}}));
    //   case "University College":
    //     // find college by name and type

    //     // update user slice schoolName, value, userType
    //     await dispatch(
    //           setUser({
    //             schoolName: data?.schoolName[0].label,
    //             schoolImage: data?.schoolName[0].imageName,
    //           }));

    //   break;
    //   case "Nashville Community":

    //   break;
    //   case "Ivy-League University":

    //   break;
    //   default:
    // }

    // if (data?.schoolName?.length > 0) {
    //   await dispatch(
    //     setUser({
    //       schoolName: data?.schoolName[0].label,
    //       schoolImage: data?.schoolName[0].imageName,
    //     })
    //   );
    // } else {
    //   await dispatch(
    //     setUser({
    //       schoolName: data?.schoolName.label,
    //       schoolImage: data?.schoolName.imageName,
    //     })
    // );
    // }

    const navResponse = await dispatch(setNavigation({ page, path }));
    navigate(`${process.env.PUBLIC_URL}${navResponse.payload.path}`);
  };

  /**
   * New react-select dropdown icon function
   * @param {*} svg
   * @returns
   */
  const DropdownIndicator = (svg) => svg;

  useEffect(() => {
    console.log("UserTypeSubmitted:", userTypeSubmitted);
    // console.log("CollageSubmitted:", college);
  }, [userTypeSubmitted]);

  useEffect(() => {
    const handleSubmitUserType = async () => {
      if (userType) {
        setUserTypeSubmitted(true);


      // console.log("Descibe UserType Data:", userType);
        await dispatch(
          setUser({
            userType: toTitleCase(userType),
          })
        );
      }

      console.log("UserTypeSubmitted:", userTypeSubmitted);
      // console.log("CollageSubmitted:", college);
    };

    handleSubmitUserType();
  }, [userType]);
  return (
    <div className="signin-form-content">
      <form
        // onSubmit={handleSubmit(handleSubmitUserType)}
        className="describe-user-college-content"
      >
        <div className="signin-header">
          <h3 className="title">
            Welcome
            {userData?.firstName && userData?.firstName !== ""
              ? `,  ${userData["firstName"]}`
              : ""}
          </h3>

          <div className="row create-account">
            Let’s get to know you a bit better.{" "}
          </div>
        </div>

        <div className="login-group">
          <div className="form-group">
            <StaticSelect
              labelClass="label-element"
              isMulti={false}
              placeholder="select one"
              name="userType"
              label="What best describes you"
              options={userOptions}
              control={control}
              DropdownIndicator={() =>
                DropdownIndicator(displaySvgIcon("dropdown"))
              }
              card
              selectHandler={userTypeSelectHandler}
              required={true}
            />
            {userTypeSubmitted ? (
            <div className="school-auto-fill">
              {`Based on your email address (${userData.email}), We’ve auto-filled in
              your school. Verify or edit to continue.`}
            </div>) : <></>}
          </div>
        </div>
      </form>
      <>
        {userTypeSubmitted ? (
          <form onSubmit={handleSubmit(handleSubmitCollege)}>
            <div className="login-group">
              <div className="form-group college-val">
                <StaticSelect
                  labelClass="label-element"
                  isMulti={false}
                  placeholder="select one"
                  name="schoolName"
                  label="What school do you teach at"
                  options={collegeOptions}
                  control={control}
                  DropdownIndicator={() =>
                    DropdownIndicator(displaySvgIcon("dropdown"))
                  }
                  card
                  selectHandler={collegeSelectHandler}
                  required={true}
                />
                <div className="option-container">
                  {console.log("College2:", college)}
                  {console.log("Default College2:", defaultCollege)}
                  {college?.imageName ? (
                    // <>{console.log("College3:", college.imageName, college.label)}
                    <img src={college.imageName} height="112px" alt={"school"} />
                    // </>
                  ) : (
                    // <>{console.log("College3:", college.imageName, college.label)}
                    <img src={defaultCollege.imageName} height="112px" alt={"school"} />
                    // </>
                  )}
                </div>

                <Button
                  name="school-name"
                  type="submit"
                  label="Next"
                  variant="attention"
                  size="medium"
                  className="adopt-btn"
                />
              </div>
            </div>
          </form>
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default DescribeUserCollegeContent;
