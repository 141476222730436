import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";

const LoadingSpinner = ({ width, height }) => {
  return (
    <div>
      <div className="fas fa-spin center">
        <FontAwesomeIcon
          icon={faRotateRight}
          // size='5x'
          color="#3B5998"
          style={{
            width: `${width}`,
            height: `${height}`,
          }}
        />
      </div>
    </div>
  );
};

export default LoadingSpinner;
