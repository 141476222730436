import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../../global/input/Button";
import SchoolEditCard from "./SchoolEditCard";
import { setDefault } from "../../../../../redux/slices/collegeSlice";
import { setUser } from "../../../../../redux/slices/userSlice";
import EditButton from "../../../../global/input/EditButton";
import { setNavigation } from "../../../../../redux/slices/navigationSlice";
// ../../../redux/slices/navigationSlice";

const SchoolEditList = ({ displayToggle, setDisplayToggle }) => {
  const collegeOptions = useSelector((state) => state.college.schools);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = async (event, college) => {
    // console.log("CLICKED BUTTON!", college)
    // setDisplayToggle(!displayToggle);
    const path = "/school/edit";
    const page = "School";
    const navResponse = await dispatch(setNavigation({ path, page }));
    // console.log("Response:", navResponse);
    navigate(
      `${process.env.PUBLIC_URL}${navResponse.payload.path}\\${college.value}`
    );
  };

  const handleDivClick = async (event, college) => {
    event.preventDefault();
    event.stopPropagation();
    // const dataId = event.currentTarget.dataset.id;
    const newUserData = {};
    const collegeValue = college.value;
    newUserData.schoolName = college.label;
    newUserData.schoolImage = college.imageName;

    // console.log("Clicked div with data-id:", collegeValue);
    // console.log("College value:", college);

    // Dispatch the setDefaultCollege action
    if (collegeValue) {
      const defaultCollegeData = await dispatch(setDefault({ collegeValue }));
      console.log("DefaultCollege:", defaultCollegeData);
      await dispatch(setUser(newUserData));
    }
  };

  useEffect(() => {
    // console.log("displayToggle:", displayToggle);
  }, [displayToggle]);

  // console.log("CollegeOptions:", collegeOptions, "default:", activeCollege);
  return (
    <>
      {collegeOptions.map((college) => (
        <div className="school-info" key={college.value}>
          <div
            data-school={college.label}
            data-id={college.value}
            className="school inactive"
            // onClick={(event) => handleDivClick(event, college)}
          >
            <SchoolEditCard school={college} active={college.default} />
          </div>
          <div className="edit-buttons">
            <EditButton
              label="Edit"
              college={college}
              handler={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleClick(event, college);
              }}
            />
            <EditButton
              label={college?.default === true ? "default" : "set default"}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default SchoolEditList;
