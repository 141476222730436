import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SetupSteps from "./SetupSteps";
import BookInfo from "./BookInfo";
import Packages from "./Packages";
import CourseTypeContent1 from "./CourseTypeContent1";
import StudentAccessContent1 from "./StudentAccessContent1";
import CreateCourseContent2 from "./CreateCourseContent2";
import CourseInfoContent2 from "./CourseInfoContent2";
import CreatingCourse from "./CreatingCourse";
import ConnectBookstoreContent3 from "./ConnectBookstoreContent3";
import ConnectLmsContent4 from "./ConnectLmsContent4";
import ConnectingToBookstore from "./ConnectingToBookstore";
import ConnectingToLMS from "./ConnectingToLMS";
import { setUser } from "../../../redux/slices/userSlice";
import LmsConnectingToBookstore from "./LmsConnectingToBookstore";
import LmsCreatingCourse from "./LmsCreatingCourse";

const ProductContent1 = ({ step, subStep, flow }) => {
  // const [parentFlow, setParentFlow] = useState(flow);
  const [parentMode, setParentMode] = useState("");

  const dispatch = useDispatch();

  const [pageChange, setPageChange] = useState(false);

  const [currentStep, setCurrentStep] = useState(step || 0); //Main steps shown in the breadcrumbs
  const [currentSubStep, setCurrentSubStep] = useState(subStep || 0); // Sub steps for each main step

  /* States to show connections to book store, LMS and course creation - status change happens on a timer */
  const [creatingCourse, setCreatingCourse] = useState(false);
  const [connectingBookstore, setConnectingBookstore] = useState(false);

  const [connectingLMS, setConnectingLMS] = useState(false);

  /* List of steps in the creation modals */
  const createCourseSteps = useRef(null);

  const lmsCreateCourseSteps = useRef(null);
  const connectBookstoreSteps = useRef(null);

  const navigate = useNavigate();

  const subStepTitle = [
    "Choose Product",
    "Choose Course Type",
    "Choose Student Access",
    "Create Course",
    "Verify Information",
    "Send to Bookstore",
    "Connect to LMS",
  ];

  const lmsSubStepTitle = [
    "Choose Product",
    "Choose Course Type",
    "Choose Student Access",
    "Create Course",
    "Choose price",
    "Connect to Bookstore",
    "Connect to LMS",
  ];
  if (flow === "lms/setup/product" && currentStep === 1) {
    lmsSubStepTitle[4] = "Set up";
  }

  const SubsectionMap = {
    productSection: 0,
    userSection: 1,
    bundleSection: 2,
    courseInputSection: 3,
    courseEditSection: 4,
  };
  // Choose type of package
  const chooseCourseType = async (event) => {
    const packageType = event.target.name;
    const packagePrice = event.target.getAttribute("data-price");
    console.log("PackageType:", packageType);
    console.log("PackagePrice:", packagePrice);
    switch (packageType) {
      case "eText bundle":
      case "eTextbook":
        await dispatch(
          setUser({
            packageType,
            packagePrice,
          })
        );
        break;
      default:
        break;
    }
    setCurrentSubStep(1);

    /**
     * Skip to BundleSection
     */
    const bundleSection = SubsectionMap["bundleSection"];
    console.log(
      "bundleSectionNum:",
      bundleSection,
      "Flow:",
      flow,
      "subsection:",
      currentSubStep
    );

    // Skip over Access Type button
    skipToSubsection(bundleSection, 0); // remove for original

    /**
     * Redirecting to CourseEdit Subsection while editing, set subsection to 0 if not editing:
     */
    console.log("ChooseCourseType: Flow:", flow, "ParentMode:", parentMode);
    if (
      parentMode === "editingProductInfo" &&
      // || parentMode===""
      flow === undefined
    ) {
      console.log("Redirecting3: Back to Course Edit on Inclusive click");
      const courseEditSection = SubsectionMap["courseEditSection"];
      console.log("bundleSectionNum:", courseEditSection);
      const step = 2;
      skipToSubsection(courseEditSection, step);

      // reset parentMode
      setParentMode("");
    } else if (
      parentMode === "editingProductInfo" &&
      flow === "lms/setup/product"
    ) {
      const courseEditSection = SubsectionMap["courseEditSection"];
      console.log("bundleSectionNum:", courseEditSection);
      const step = 2;
      skipToSubsection(courseEditSection, step);

      // reset parentMode
      setParentMode("");
    }
  };

  const chooseAccessType = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("CourseType:", event.target.name);
    const courseType = event.target.name;
    // const packagePrice = event.target.getAttribute("data-price");

    switch (courseType) {
      case "student":
      case "instructor":
        await dispatch(
          setUser({
            courseType,
          })
        );
        break;
      default:
        break;
    }
    setCurrentSubStep(2);
  };
  /**
   * Method to skip to a subsection in the  courseSetup
   */
  const skipToSubsection = async (subSection, step) => {
    const courseType = "student";
    await dispatch(
      setUser({
        courseType,
      })
    );
    setCurrentSubStep(subSection);
    console.log("SkipToSubsection:", "Subsection:", subSection, "Step:", step);
    // if (step && flow === "lms/setup/pricing") {
    setCurrentStep(step);
    // }
  };

  const createCourse = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const courseAccess = event.target.name;
    console.log("courseAccess:", courseAccess);
    switch (courseAccess) {
      case "inclusive":
        await dispatch(setUser({ courseAccess }));
        break;
      case "bookstore":
        await dispatch(
          setUser({
            courseAccess,
            packagePrice: "Student Price: $119.00 18wk access",
          })
        );
        break;
      default:
        break;
    }

    /**
     * Redirect to CourseEdit Section if editing
     */
    console.log("CreateCourse: Flow:", flow, "ParentMode:", parentMode);
    if (flow === undefined && parentMode == "") {
      /******* Original Code Start *****/
      setCurrentStep(1);
      setCurrentSubStep(3);
      /******* Original Code End*****/
    } else if (
      parentMode === "editingAccessInfo" &&
      // || parentMode==="" || parentMode==="editinngCourseInfo"
      flow === undefined
    ) {
      console.log("Redirecting2: Back to Course Edit on Inclusive click");
      const courseEditSection = SubsectionMap["courseEditSection"];
      console.log("bundleSectionNum:", courseEditSection);
      const step = 2;
      skipToSubsection(courseEditSection, step);

      // reset parentMode
      setParentMode("");
    } else if (
      parentMode === "editingAccessInfo" &&
      flow === "lms/setup/product"
    ) {
      const courseEditSection = SubsectionMap["courseEditSection"];
      console.log("bundleSectionNum:", courseEditSection);
      const step = 0;
      skipToSubsection(courseEditSection, step);

      // reset parentMode
      setParentMode("");
    }
  };

  const confirmCreateCrouse = () => {
    console.log("ConfirmCreateCourse: Flow:", flow, "ParentMode:", parentMode);

    if (flow === "lms/setup/product" && parentMode === "editingCourseInfo") {
      setCurrentStep(1);
      setCurrentSubStep(4);
    } else if (flow === "lms/setup/product") {
      setCurrentStep(0);
      setCurrentSubStep(4);
    } else if (flow === undefined) {
      setCurrentStep(2);
      setCurrentSubStep(4);
    }
  };

  const creatingCourseAnimation = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setCurrentStep(2);
    setCreatingCourse(true); //Creating course...

    setTimeout(() => {
      console.log("createCourseSteps:", createCourseSteps, "lmsCreateCourseSteps:", lmsCreateCourseSteps);
        createCourseSteps.current.childNodes.forEach((element) => {
        element.classList.add("fade-in");
      });
    }, 500);

    /* Set page to Connecting to Bookstore */
    setTimeout(() => {
      setCreatingCourse(false);
      setCurrentStep(3);
      setCurrentSubStep(5);
    }, 5000); // updated 3000 to 5000
  };

  const lmsCreatingCourseAnimation = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setCurrentStep(1);
    setCreatingCourse(true); //Creating course...

    /** Set Page to Connecting to Course */
    setTimeout(() => {
      console.log("LMScreateCourseSteps:", createCourseSteps, "lmsCreateCourseSteps:", lmsCreateCourseSteps.current.childNodes);
    
    /*LAUNCH CONNECTING COURSE ANIMATION FOR LMS PROTOTYPE*/ 

      lmsCreateCourseSteps.current.childNodes.forEach((element, index) => {
        if (index === lmsCreateCourseSteps.current.childNodes.length-1) {
          setTimeout(() => {
            element.classList.add("fade-in");
          }, 2000); // Delay the last element by 1500ms
        } else {
          element.classList.add("fade-in");
        }
      });
    }, 500); // updated 500 to 5000

/* Set page to Connecting to Bookstore */
    setTimeout(() => {
      setCreatingCourse(false);
      setCurrentStep(2);
      setCurrentSubStep(5);
    }, 5000); // updated 3000 to 5000
  };

  /* Set page to Connecting to LMS */
  const connectToBookStore = () => {
    setConnectingBookstore(true);
    setTimeout(() => {
      connectBookstoreSteps.current.childNodes.forEach((element) => {
        element.classList.add("fade-in");
      });
    }, 500); // updated 500 to 5000

    /* Set page to Connecting to LMS */
    setTimeout(() => {
      setConnectingBookstore(false);
      setCurrentStep(3);
      setCurrentSubStep(6);
      navigate(process.env.PUBLIC_URL + "/setup/complete");
    }, 5000); // updated 2500 to 5000
  };
  const lmsConnectToBookStore = () => {
    setConnectingBookstore(true);
    // const isConnectedToBookstore = false;
    setTimeout(() => {
      connectBookstoreSteps.current.childNodes.forEach((element) => {
        element.classList.add("fade-in");
      });
    }, 500); // updated 500 to 5000

   setTimeout(() => {
      setConnectingLMS(false);
      navigate(process.env.PUBLIC_URL + "/lms/setup/complete");
    }, 5000); // updated 2500 to 5000


  //   /* Set page to Connecting to LMS, not For LMS Prototype */
  //   setTimeout(() => {
  //     setConnectingBookstore(false);
  //     setCurrentStep(3);
  //     setCurrentSubStep(6);
  //   }, 5000); // updated 2500 to 5000
  };

  useEffect(() => {
    console.log(
      "ProductContent1:",
      "currentStep:",
      currentStep,
      "currentSubStep:",
      currentSubStep
    );
    if (flow === "lms/setup/product" && step === 0 && subStep === 4) {
      dispatch(
        setUser({
          lmsPackageType: "eText bundle",
          lmsPackagePrice: "Student Price: $89.00 18wk access",
        })
      );
    }
  }, [currentStep, currentSubStep]);

  useEffect(() => {
    console.log("parentMode:", parentMode);
  }, [parentMode]);

  return (
    <div className="page-layout">
      <h1>Set up the title</h1>

      <SetupSteps flow={flow} step={currentStep} />

      <h2>
        {flow === "lms/setup/product"
          ? lmsSubStepTitle[currentSubStep]
          : subStepTitle[currentSubStep]}
      </h2>

      {currentSubStep < 4 && (
        /* Book content should come from redux */
        <BookInfo />
      )}

      {currentSubStep === 0 ? (
        /* Sub Step 1 - Choose packages */
        <Packages chooseCourseType={chooseCourseType} />
      ) : // {
      currentSubStep === 1 ? (
        /* Sub Step 2 - Choose Course Type */
        /*********choose eTextbook / bundle********/
        <CourseTypeContent1 chooseAccessType={chooseAccessType} />
      ) : // *****Purchase from bookstore / Add to Student Fees********
      currentSubStep === 2 ? (
        /* Sub Step 2 - Choose Student Access */
        <StudentAccessContent1 createCourse={createCourse} />
      ) : currentSubStep === 3 ? (
        /* Step 2: Sub step 3 - Create Course */
        <CreateCourseContent2
          confirmCreateCrouse={confirmCreateCrouse}
          // setMode={setParentMode}
        />
      ) : currentSubStep === 4 ? (
        /* Step 2 - Sub stem 4 - Create Course */
        <CourseInfoContent2
          setCurrentStep={setCurrentStep}
          setCurrentSubstep={setCurrentSubStep}
          creatingCourseAnimation={creatingCourseAnimation}
          lmsCreatingCourseAnimation={lmsCreatingCourseAnimation}
          setMode={setParentMode}
          flow={flow}
          currentStep={currentStep}
          currentSubStep={currentSubStep}
        />
      ) : currentSubStep === 5 ? (
        /* Step 3 - sub step 5 - Connect to bookstore */
        <ConnectBookstoreContent3
          connectToBookStore={connectToBookStore}
          lmsConnectToBookStore={lmsConnectToBookStore}
          flow={flow}
          currentStep={currentStep}
          currentSubStep={currentSubStep}
        />
      ) : (
        <></>
      )}

      {/* Creation/Conenction modals */}
      {console.log("FLOWBefore:", flow, "CreatingCourse:", creatingCourse, "Step:", currentStep, "SubStep:", currentSubStep)}
      {flow === undefined
        ? creatingCourse && <CreatingCourse ref={createCourseSteps} />
        : flow === "lms/setup/product"
        ? creatingCourse && <LmsCreatingCourse ref={lmsCreateCourseSteps} />
        : null}
      {console.log(
        "FLOWAfter:",
        flow,
        "connectingBookstore:",
        connectingBookstore, "Step:", currentStep, "SubStep:", currentSubStep
      )}

      {flow === undefined
        ? connectingBookstore && (
            <ConnectingToBookstore ref={connectBookstoreSteps} />
          )
        : flow === "lms/setup/product"
        ? connectingBookstore && (
            <LmsConnectingToBookstore ref={connectBookstoreSteps} />
          )
        : null}

      
    </div>
  );
};

export default ProductContent1;
