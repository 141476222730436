import React from 'react';
import Button from "../../input/Button";
import { setUser } from '../../../../redux/slices/userSlice';
import { useSelector, useDispatch } from 'react-redux';

const Footer = ({label, setShowAccount, setDisplayToggle}) => {
  const dispatch = useDispatch();

  const handleClick = (event) => {
    // event.preventDefault();
    // event.stopPropagation();
    dispatch(setUser({signedIn: false}));
    setShowAccount(false);
    setDisplayToggle(false);  
    console.log("Before:", localStorage.getItem('persist:root')); 
    localStorage.removeItem('persist:root');
    console.log("After:", localStorage.getItem('persist:root')); 
  }
    return (
        <footer className="modal-footer">
        <div className="row">
          <div className="col">
            <Button
              type="button"
              displayType="segment"
              label={label}
              variant="attention"
              size="medium"
              className="modal-toggle-btn"
              setShowModal={handleClick}
            />
          </div>
        </div>
      </footer>
    );
};

export default Footer;