import React from "react";

const UserIcon = ({ userData, size = 46, color = "#9CB0C9" }) => {
  // Function to extract initials from first name and last name
  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0) : "";
    const lastInitial = lastName ? lastName.charAt(0) : "";
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const initials = getInitials(userData.firstName, userData.lastName);

  return (
    <svg width={size} height={size} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="23" r="23" fill={color} />
      <text x="50%" y="50%" textAnchor="middle" dy="0.35em" fontSize="20" fill="white">
        {initials}
      </text>
    </svg>
  );
};

export default UserIcon;
