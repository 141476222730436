import React, { useState, useEffect } from "react";
import setupCompleteImg from "../../../assets/images/setup_complete.png";
import Button from "../../global/input/Button";
import ConfettiExplosion from "react-confetti-explosion";
import CourseModal from "./CourseModal";

const CompleteContent = (props, { flow }) => {
  const [isExploding, setIsExploding] = useState(false);
  const [openCourseModal, setOpenCourseModal] = useState(false);
  console.log("CompleteContent: Flow:", flow);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 80);
    setIsExploding(true);
  }, []);


  return (
    <div className="page-layout">
      {isExploding && (
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <ConfettiExplosion
            force={0.8}
            duration={3000}
            particleCount={250}
            width={1600}
          />
        </div>
      )}
      <h1>All Set!</h1>

      <img src={setupCompleteImg} alt="" />

      <div className="setup-complete-message">
        <p>
          If at any time you need to contact help regarding this title set up.
          Your order number is #2304982304982
        </p>

        <p>
          An email with your receipt and order number has been sent to your
          primary email address, you can also{" "}
          <a href="javascript:void(0);">Download a PDF copy of the receipt and order number</a>
        </p>

        {flow && flow === "/lms/setup/product" ? (
          <Button
            label="Go to Course Dashboard"
            variant="primary"
            size="medium"
            newContent="CompleteContent"
            newPage="CourseSetup"
            newPath="/lms/course/home"
          />
        ) : (props.userType === 'adjunct' ? 
          <Button
            label="Open Pearson Course in New Tab"
            variant="primary"
            size="medium"
            onClick={() => setOpenCourseModal(true)}
          /> 
          :
          <Button
            label="Go to Dashboard"
            variant="primary"
            size="medium"
            newContent="Dashboard"
            newPage="Dashboard"
            newPath="/dashboard"
          />
        )}
      </div>

      {openCourseModal && <CourseModal />}
    </div>
  );
};

export default CompleteContent;
