import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Text from "../../global/input/Text";
import Button from "../../global/input/Button";
import { useDispatch, useSelector } from "react-redux";
import { setNavigation } from "../../../redux/slices/navigationSlice";
import { setUser } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router";
import Checkbox from "../../global/input/Checkbox";
import { setEmails } from "../../../redux/slices/collegeSlice";

const LmsCreateAccountContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm({
    // defaultValues: {
    //   lastName: "",
    // },
  });

  const handleSubmitForm = async (data, event) => {
    // const newContent = "TitleSearchContent";
    const page = "Start";
    const path = "/lms/link";

    // Prevent the default form submission behavior
    event.preventDefault();
    // Stop the event propagation to prevent it from bubbling up
    event.stopPropagation();
    console.log("Signin Data:", data);
    const emailName = data.firstName.toLowerCase() +"."+ data.lastName.toLowerCase();
    data.signedIn = true;
    const collegeEmails = {
      // "University College": data.email,
      "University College": emailName + "@univ.edu",
      "Nashville Community": emailName +"@nash.cc.edu",
      "Ivy-League University" : emailName +"@ivey.lg.edu",
      // Add more emails for other college labels if needed
    };
    // dispatch emails:
    console.log("College Emails:", collegeEmails);
    await dispatch(setEmails({collegeEmails}))
    await dispatch(setUser(data));

    // console.log("NewPath:", newPath);
    // const path = newPath;
    const navResponse = await dispatch(setNavigation({ page, path }));
    console.log("Response:", navResponse);

    navigate(`${process.env.PUBLIC_URL}${navResponse.payload.path}`);
  };

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className="create-account create-account-form-content"
    >
      <div className="header">
        <h3 className="title">Create account</h3>
      </div>

      <div className="login-group">
        <div className="form-group">
          <div className="name-group">
            <Text
              displayType="segment"
              register={register}
              className="form-input"
              id="formGroupFirstName"
              testId="first-name"
              label="First name"
              labelClass="label-element"
              name="firstName"
              required={true}
            />

            <Text
              displayType="segment"
              register={register}
              className="form-input"
              id="formGroupLastName"
              testId="last-name"
              label="Last name"
              labelClass="label-element"
              name="lastName"
              required={true}
            />
          </div>
          <Text
            displayType="row"
            register={register}
            className="form-input"
            id="formGroupEmail"
            testId="email"
            label="School email address"
            labelClass="label-element"
            name="email"
            required={true}
            placeholder="ex. johnsmith@college.edu"
          />
          <Text
            displayType="row"
            type="password"
            register={register}
            className="form-input"
            id="formGroupPassword"
            testId="password"
            label="Password"
            labelClass="label-element"
            name="password"
            required={true}
          />
          <Checkbox
            displayType="row"
            register={register}
            className={"form-input"}
            id="formGrouptermAgreement"
            testId="term-agreement"
            label="I agree and acknowledge to the Terms of use."
            labelClass="label-element"
            name="termAgreement"
            required={true}
            displayQuestion={true}
          />
          <Button
            label="Create account"
            variant="attention"
            size="medium"
            className="create-btn adopt-btn"
            // newContent="TitleSearchContent"
            // newPage="Start"
            // newPath="/title"
          />
        </div>
      </div>
    </form>
  );
};

export default LmsCreateAccountContent;
