import React, { useState, useEffect } from "react";
import AvatarDisplayCard from "./partials/AvatarDisplayCard";
import Backdrop from "../Backdrop";
import Button from "../input/Button";
import SignOutFooter from "./partials/SignOutFooter";
import SchoolDisplayList from "./partials/SchoolDisplayList";

const Account = ({ setShowAccount, flow }) => {
  const [displayToggle, setDisplayToggle] = useState(false);

  useEffect(() => {
    // console.log('DISPLAYTOGGLE:", displayToggle')
  }, [displayToggle]);

  return (
    <>
      <div className="access-modal">
        <div className="avatar-display select-container">
          {flow !== "lms" ? (
            <>
              <AvatarDisplayCard setShowAccount={setShowAccount} />
              <SchoolDisplayList
                displayToggle={displayToggle}
                setDisplayToggle={setDisplayToggle}
                modalClass="account-modal"
              />
            </>
          ) : (
            <></>
          )}
          <div className={flow==="lms" ? "lms-sign-out-footer" : "sign-out-footer"}>
            <SignOutFooter
              label="Sign out"
              setShowAccount={setShowAccount}
              setDisplayToggle={setDisplayToggle}
            />
          </div>
        </div>
      </div>
      {/* <Backdrop /> */}
    </>
  );
};

export default Account;
