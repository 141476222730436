import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Text from "../../global/input/Text";
import Button from "../../global/input/Button";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/slices/userSlice";
import adjunctUser from "../../../redux/data/adjunctUserData";
import userData from "../../../redux/data/userData";
import { setNavigation } from "../../../redux/slices/navigationSlice";
import { useNavigate } from "react-router";

const SigninFormContent = ({ flow }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();

  console.log("Flow:", flow);

  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      // lastName: {
      //   value: "new",
      //   label: "New",
      // },
    },
  });

  const handleSubmitForm = async (data, event) => {
     // Prevent the default form submission behavior
     event.preventDefault();
     // Stop the event propagation to prevent it from bubbling up
     event.stopPropagation();
     
    let newUserData = { ...data, ...userData, signedIn: true, isAdjunct: false };
    let page = "";
    let path = "";


    if (flow === "lms") {
      page = "LinkAccount";
      path = "/lms/link"; 
    } else if (flow === "adjunct/lms") {
      page = "AdjunctLinkAccount";
      path = "/adjunct/lms/link"; 
    } else if (userData.bookSelected) {
      page = "CourseSetup";
      path = "/setup/product";
    }
    // book not selected
    else if(!userData.bookSelected) {
      page = "TitleSearchContent";
      path = "/title";
    }

    if (data.email.indexOf('adjunct') > -1) {/* Check if Adjunct user - Any username that includes the word 'adjunct' */
      page = 'Dashboard';
      path = '/adjunct/dashboard';
      newUserData = { ...adjunctUser, signedIn: true, isAdjunct: true };
    }

    await dispatch(setUser(newUserData));

    const response = await dispatch(setNavigation({ page, path }));
    navigate(`${process.env.PUBLIC_URL}${response.payload.path}`);
  };

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className="signin-form-content"
    >
      <div className="signin-header">
        <h3 className="title">Sign in</h3>
        <div className="row create-account">
          New to Pearson?{" "}
          <Link
            className="create-account-link"
            to={flow==="lms" ? `${process.env.PUBLIC_URL}/lms/account/create` : `${process.env.PUBLIC_URL}/account/create`}
          >
            Create an account
          </Link>
        </div>
      </div>
      {userData?.lastName ? (
        <>
          <div className="login-group">
            <div className="form-group">
              <Text
                displayType="row"
                register={register}
                className="form-input"
                id="formGroupEmail"
                testId="login-email"
                label="Email address"
                labelClass="label-element"
                name="email"
                required={true}
              />
              <Text
                displayType="row"
                type="password"
                register={register}
                className="form-input"
                id="formGroupPassword"
                testId="login-password"
                label="Password"
                labelClass="label-element"
                name="password"
                required={true}
              />
              <p className="trouble-element">Trouble signing in?</p>
              <Button
                label="Sign in"
                variant="attention"
                size="medium"
                className="adopt-btn"
              />
              <div className="row signin-terms">
                By signing in, you agree to our Terms of use and acknowledge our
                Privacy notice.
              </div>
            </div>
          </div>{" "}
        </>
      ) : (
        <></>
      )}
    </form>
  );
};

export default SigninFormContent;
