import helpButton from "../../assets/images/getHelpButton.png";


const navigationData = {
    page: "Start",
    content: "WelcomeContent",
    path: "/",
    helpButton
}

export default navigationData;