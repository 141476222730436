import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {setUser } from "../../../redux/slices/userSlice";

import AdjunctSetupSteps from "./AdjunctSetupSteps";
import AddCourseInformation from './AddCourseInformation';
import AdjunctCreateCourseInfo from './AdjunctCreateCourseInfo';
import CreatingCourse from './CreatingCourse';

const AdjunctCreateCourseContent = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [currentStep, setCurrentStep] = useState(0); //Main steps shown in the breadcrumbs
    /* States to show connections to book store, LMS and course creation - status change happens on a timer */
    const [creatingCourse, setCreatingCourse] = useState(false);
    const userData = useSelector((state) => state.user);

    const [courseName, setCourseName] = useState(userData.courseName);
    const [courseTimezone, setCourseTimezone] = useState(userData.courseTimezone);
    const [courseStart, setCourseStart] = useState(userData.courseStart);
    const [courseEnd, setCourseEnd] = useState(userData.courseEnd);
    const [enrollStart, setEnrollStart] = useState(userData.enrollStart);
    const [enrollEnd, setEnrollEnd] = useState(userData.enrollEnd);

    const updateCourseName = (a) => { setCourseName(a) }
    const updateCourseTimezone = (a) => { setCourseTimezone(a) }
    const updateCourseStart = (a) => { setCourseStart(a) }
    const updateCourseEnd = (a) => { setCourseEnd(a) }
    const updateEnrollStart = (a) => { setEnrollStart(a) }
    const updateEnrollEnd = (a) => { setEnrollEnd(a) }

    /* List of steps in the creation modals */
    const createCourseSteps = useRef(null);

    const formSubmitHandler = async (event) => {
        await dispatch(setUser({ 
            courseName: courseName, 
            courseTimezone: courseTimezone, 
            courseStart: courseStart, 
            courseEnd: courseEnd, 
            enrollStart: enrollStart, 
            enrollEnd: enrollEnd }));
        
        setCurrentStep(1);
    }

    const creatingCourseAnimation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        setCurrentStep(2);
        setCreatingCourse(true); //Creating course...
    
        setTimeout(() => {
            createCourseSteps.current.childNodes.forEach((element) => {
            element.classList.add("fade-in");
          });
        }, 500);
    
        /* Set page to Connecting to Bookstore */
        setTimeout(() => {
          setCreatingCourse(false);
          if (props.userType === 'adjunct') {
            navigate(
              `${process.env.PUBLIC_URL}/adjunct/setup/complete`
            );
          } else {
            navigate(
              `${process.env.PUBLIC_URL}/setup/complete`
            );
          }

        }, 5000); // updated 3000 to 5000
      };

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 80);
      }, []);

    return (
        <>
        <div className="page-layout">
            <h1>Set up the title</h1>

            <AdjunctSetupSteps step={currentStep} />
            {currentStep == 0 ? 
                <AddCourseInformation 
                    courseName={courseName} 
                    courseTimezone={courseTimezone} 
                    courseStart={courseStart} 
                    courseEnd={courseEnd} 
                    enrollStart={enrollStart} 
                    enrollEnd={enrollEnd} 
                    formSubmitHandler={formSubmitHandler} 
                    updateCourseName={updateCourseName} 
                    updateCourseTimezone={updateCourseTimezone} 
                    updateCourseStart={updateCourseStart}
                    updateCourseEnd={updateCourseEnd}
                    updateEnrollStart={updateEnrollStart}
                    updateEnrollEnd={updateEnrollEnd}
                />
            : 
                <AdjunctCreateCourseInfo setCurrentStep={setCurrentStep} creatingCourseAnimation={creatingCourseAnimation} />
            }
        </div>
        {creatingCourse && <CreatingCourse ref={createCourseSteps} />}
        </>
    );
};

export default AdjunctCreateCourseContent;