const Tabs = ({
    items,
    activeTab,
    handleClick,
    ...rest
  }) => {

    return (
        <div role="tablist" className="tabs">
            {
                items.map((item, index)=>{
                    return(
                        <button 
                            key={index}
                            id={`${item.id}-tab`}
                            aria-controls={`${item.id}-tab-panel`}
                            aria-selected={activeTab === item.id ? 'true' : 'false'}
                            role="tab"
                            className={activeTab === item.id ? 'active' : ''}
                            onClick={()=>{
                                if(handleClick){
                                    handleClick(item.id)
                                }
                            }}
                        >
                            <span>{item.label}</span>
                        </button>
                    )
                })
            }
        </div>
    )
  }

  export default Tabs