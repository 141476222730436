

import { useSelector } from "react-redux";

const BookInfo = (props) => {


    const userData = useSelector((state) => state.user);
    // console.log("userData:", userData)
    const selectedBook = userData.bookName;
    // console.log("SelectedBook:", selectedBook)
    const bookData = useSelector((state) => state.pearson).titles.filter(a => a.label === selectedBook)[0];

    return(
        <div className={`selected-product ${props.type}`}>
            <img src={bookData?.imagePath} alt="Selected Book" />
            <div>
                <h3>{bookData?.label}, {bookData?.edition} edition</h3>
                <p>Published by Pearson (January 16, 2020) &copy; 2021</p>
                <p>{bookData?.authors.replaceAll(",", " | ")}</p>
            </div>
        </div>
    )
}

export default BookInfo