import React from "react";
import ActiveTag from "../../output/ActiveTag";
import Validated from "../../output/Validated";

const SchoolDisplayCard = ({ school, active}) => {
//   console.log("SCHOOL:", school);
  return (
    <div className="col">
      <div className="school-data">
        <span className={active ? "active": "inactive"}>{school?.label}</span>
       <div className="val-role">
       {school?.validated ? <Validated /> : <></>} <span className={active ? "active": "inactive"}>{school?.role}</span>
        </div>
        <span className={active ? "active": "inactive"}>{school?.email}</span>
      </div>
       {active ? <ActiveTag /> : <></>}
    </div>
  );
};

export default SchoolDisplayCard;
