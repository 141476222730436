import React, { useEffect, useState } from "react";
import Button from "../../global/input/Button";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/slices/userSlice";

const WelcomeContent = () => {
  
   /*******************
    * Dispatch Test
    *******************/
   
  //  const dispatch = useDispatch();
  // // const firstName = "Tony2";
  // useEffect(() => {
  //   // dispatch(setUser({ firstName }));
  // }, []);

  return (
    <div className="welcome-content">
      <div className="title">Welcome to Pearson</div>
      <div className="pearson-courseware">
        Ready to add Pearson courseware to your course? We’ll walk you through
        getting things set up with ease.
      </div>
      <div className="builder-group">
        <div className="title">Let’s Build together</div>
        <div className="main">
          We know how valuable your time is. Use our specialized tools to find
          the right material and connect it to your bookstore and learning
          management system so that you can focus on your class.
        </div>
        <div className="adopt-btn-group">
          <Button
            label="Adopt your Pearson title now"
            variant="attention"
            size="medium"
            className="adopt-btn"
            newContent="TitleSearchContent"
            newPage="Start"
            newPath="/title"
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomeContent;
