import iconInfo from '../../../assets/images/icons/icon_ info.png';

const AddCourseInformation = (props) => {
    const {courseName, courseTimezone, courseStart, courseEnd, enrollStart, enrollEnd } = props;

    return(
        <section className="create-course-form">
            <p>
                We've auto-filled your settings from your school catalog. Verify this information and make adjustments to match your course. This information will be seen by your students.
            </p>

            <form 
            // onSubmit={formSubmitHandler}
            >
                <div className="form-control">
                    <label htmlFor="course-name">Course name <img src={iconInfo} alt='' /></label>
                    <input type="text" id="course-name" defaultValue={courseName} onChange={(e) => props.updateCourseName(e.target.value)} />
                </div>
                <div className="form-control">
                    <label htmlFor="course-timezone">Course timezone <img src={iconInfo} alt='' /></label>
                    <select id="course-timezone" defaultValue={courseTimezone} onChange={(e) => props.updateCourseTimezone(e.target.value)}>
                        <option value="Pacific/Honolulu">Pacific/Honolulu GMT-10:00</option>
                        <option value="America/Anchorage">America/Anchorage GMT-9:00</option>
                        <option value="America/Los_Angeles">America/Los_Angeles GMT-8:00</option>
                        <option value="America/Boise">America/Boise GMT-7:00</option>
                        <option value="America/Denver">America/Denver GMT-7:00</option>
                        <option value="America/Phoenix">America/Phoenix GMT-7:00</option>
                        <option value="America/Chicago">America/Chicago GMT-6:00</option>
                        <option value="America/Detroit">America/Detroit GMT-5:00</option>
                        <option value="America/New_York">America/New_York GMT-5:00</option>
                    </select>
                </div>

                <div className="dates">
                    <div className="form-control">
                        <label htmlFor="start-date">Course Start Date <img src={iconInfo} alt='' /></label>
                        <input type="date" id="start-date" defaultValue={courseStart} onChange={(e) => props.updateCourseStart(e.target.value)} />
                    </div>
                    <div className="form-control">
                        <label htmlFor="end-date">Course End Date <img src={iconInfo} alt='' /></label>
                        <input type="date" id="edn-date" defaultValue={courseEnd} onChange={(e) => props.updateCourseEnd(e.target.value)} />
                    </div>
                    <div className="form-control">
                        <label htmlFor="enroll-start-date">Enroll Start Date <img src={iconInfo} alt='' /></label>
                        <input type="date" id="enroll-start-date" defaultValue={enrollStart} onChange={(e) => props.updateEnrollStart(e.target.value)} />
                    </div>
                    <div className="form-control">
                        <label htmlFor="enroll-end-date">Course start date <img src={iconInfo} alt='' /></label>
                        <input type="date" id="enroll-end-date" defaultValue={enrollEnd} onChange={(e) => props.updateEnrollEnd(e.target.value)} />
                    </div>
                </div>

                <div className="form-action">
                    <button 
                        type='submit'
                        className="primary"
                        onClick={props.formSubmitHandler}
                    >
                            Next
                        </button>
                </div>
            </form>
        </section>
    )
};

export default AddCourseInformation;