import React from "react";
import displaySvgIcon  from "../../utilities/displaySvgIcon";

const LmsCourseNavbar = () => {
  return (
    <nav>
      <div className="pearson-icon">{displaySvgIcon("pearson-nav")}</div>

      <ol>
        <li><span>{displaySvgIcon("dashboard-nav")}</span><p>Dashboard</p></li>
        <li><span>{displaySvgIcon("calendar-nav")}</span><p className="regular-text">Assignments</p></li>
        <li><span>{displaySvgIcon("grades-nav")}</span><p className="regular-text">Grades</p></li>
        <li><span>{displaySvgIcon("resources-nav")}</span><p className="regular-text">Resources</p></li>
        <li><span>{displaySvgIcon("gear-nav")}</span><p className="regular-text">Settings</p></li>
        <li><span>{displaySvgIcon("magnifier-nav")}</span><p className="regular-text">Search</p></li>
      </ol>
      <div className="mode-bottom">
        <span>{displaySvgIcon("mode-nav")}</span>
        <p className="regular-text">Mode</p></div>

    </nav>
  );
};

export default LmsCourseNavbar;
