
//images
import close from "../../../assets/images/icons/close-24.svg"
import qrCode from "../../../assets/images/layouts/dashboard/qrCode.png"

const InviteStudents = (props) => {
    return(
        <div className="modal invite-students-modal">
                <button 
                    className="close-modal"
                    onClick={props.handleClose}
                >
                    <img src={close}/>
                </button>
                <h1>Invite Students</h1>
                <div className="modal-body">
                        <img src={qrCode}/>
                        <p>QR Code</p>
                        <p>Students can take a picture of the QR code and access their pearson material directly from their mobile devices or create a bookmark for later.</p>
                        <button 
                            className="primary"
                            onClick={props.handleClose}
                        >
                            Download QR code sheet
                        </button>
                </div>
        </div>
    )
}

export default InviteStudents