/** @format */
import React, { useState } from 'react';

// import logo from './logo.svg';
import './App.css';
import './assets/css/style.css';
import { useSelector } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useNavigate,
} from 'react-router-dom';

import {
	faArrowsLeftRight,
	faBars,
	faBookmark,
	faChevronDown,
	faChevronUp,
	faPencil,
	faPlus,
	faServer,
	faTrash,
	faThumbTack,
	faUser,
	faVolumeHigh,
	faVolumeXmark,
	faXmark,
} from '@fortawesome/free-solid-svg-icons';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// Nav
import Navbar from './components/global/Navbar';
import NavbarLaunch from './components/global/NavbarLaunch';

// Pages
import Start from './pages/Start';
import TitleDetails from './pages/TitleDetails';
import Signin from './pages/Signin';
import Account from './pages/Account';
import CourseSetup from './pages/CourseSetup';
import LmsCourseSetup from './pages/LmsCourseSetup';
import Dashboard from './pages/Dashboard';
import LmsDashboard from './pages/LmsDashboard';
import Validate from './pages/Validate';

//** LAYOUTS */
// Start
import TitleSearchContent from './components/layouts/start/TitleSearchContent';
import WelcomeContent from './components/layouts/start/WelcomeContent';
import TitleDetailsContent from './components/layouts/titleDetails/TitleDetailsContent';
import SigninFormContent from './components/layouts/start/SigninFormContent';
import CreateAccountContent from './components/layouts/start/CreateAccountContent';
import LmsCreateAccountContent from './components/layouts/start/LmsCreateAccountContent';
import DescribeUserCollegeContent from './components/layouts/start/DescribeUserCollegeContent';
import ValidateContent from './components/layouts/start/ValidateContent';
import ValidateEducatorContent from './components/layouts/start/ValidateEducatorContent';
import ValidateEmailContent from './components/layouts/start/ValidateEmailContent';
import ValidateCompleteContent from './components/layouts/start/ValidateCompleteContent';

// CourseSetup
import ProductContent1 from './components/layouts/courseSetup/ProductContent1';
import CompleteContent from './components/layouts/courseSetup/CompleteContent';
import AdjunctCreateCourseContent from './components/layouts/courseSetup/AdjunctCreateCourseContent';

// dashboard
import GreetContent from './components/layouts/dashboard/GreetContent';
import AdjunctGreetContent from './components/layouts/dashboard/AdjunctGreetContent';
import FindTitleContent from './components/layouts/dashboard/FindTitleContent';
import ScrollToTop from './components/global/ScrollToTop';
import AccountSettingsContent from './components/layouts/start/AccountSettingsContent';
import School from './pages/School';
import SchoolEditContent from './components/layouts/start/SchoolEditContent';
import SchoolNewContent from './components/layouts/start/SchoolNewContent';
import CourseLaunch from './components/layouts/lms/content/CourseLaunch';
import TitleSelect from './components/layouts/lms/content/TitleSelect';
import LinkAccount from './components/layouts/lms/content/LinkAccount';
import LinkingAccount from './components/layouts/lms/content/LinkingAccount';
import LinkingSuccess from './components/layouts/lms/content/LinkingSuccess';
import TitleSelectConfirm from './components/layouts/lms/content/TitleSelectConfirm';
import CourseDisplay from './pages/CourseDisplay';
import CourseHome from './components/layouts/lms/content/CourseHome';
import CourseTypeContent1 from './components/layouts/courseSetup/CourseTypeContent1';
import LMSConnect from './components/layouts/dashboard/LMSConnect';
import LMSConnectionSuccess from './components/layouts/dashboard/LMSConnectionSuccess';

/* Adjunct Pickup from LMS */
import AdjunctLmsLinking from './pages/AdjunctLmsLinking';
import AdjunctLmsSteps from './components/layouts/adjunct/AdjunctLmsSteps';
/**********/

library.add(
	faArrowsLeftRight,
	faSpinner,
	faBars,
	faBookmark,
	faChevronDown,
	faChevronUp,
	faPencil,
	faPlus,
	faServer,
	faTrash,
	faSpinner,
	faThumbTack,
	faUser,
	faVolumeHigh,
	faVolumeXmark,
	faXmark
);

function App() {
	// const pearsonData = useSelector((state) => state.pearson);
	// console.log("Pearson Data:", pearsonData);

	const userData = useSelector((state) => state.user);
	// console.log('User Data:', userData);

	const isLaunchRoute = window.location.pathname.includes('/lms/launch');
	const isLmsCourseRoute =
		window.location.pathname.includes('/lms/course/home');
	const isGenericLmsRoute = window.location.pathname.includes('/lms');

	const isAdjuctLmsRoute = window.location.pathname.includes('/adjunct/lms');

	const [isLmsCourseHome, setIsLmsCourseHome] = useState(false);

	console.log(
		'isLaunchRoute:',
		isLaunchRoute,
		'lmsCourseRoute:',
		isLmsCourseRoute,
		'isGenericLmsRoute:',
		isGenericLmsRoute
	);
	return (
		<div className='App'>
			<Router>
				{isLaunchRoute || isAdjuctLmsRoute ? (
					<NavbarLaunch />
				) : isLmsCourseRoute || isLmsCourseHome ? (
					<></>
				) : isGenericLmsRoute ? (
					<Navbar flow='lms' />
				) : (
					<Navbar />
				)}
				<ScrollToTop />
				<Routes>
					<Route
						path={process.env.PUBLIC_URL + '/'}
						element={
							<Start>
								<WelcomeContent />
							</Start>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/title'}
						element={
							<Start>
								<TitleSearchContent />
							</Start>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/title_details'}
						element={
							<TitleDetails>
								<TitleDetailsContent />
							</TitleDetails>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/account/signin'}
						element={
							<Signin>
								<SigninFormContent />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/account/create'}
						element={
							<Signin>
								<CreateAccountContent />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lms/account/create'}
						element={
							<Signin>
								<LmsCreateAccountContent />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/account/info'}
						element={
							<Signin>
								<DescribeUserCollegeContent />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/account/settings'}
						element={
							<Account>
								<AccountSettingsContent />
							</Account>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/school/edit/:value'}
						element={
							<School>
								<SchoolEditContent />
							</School>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/school/new/:value'}
						element={
							<School>
								<SchoolNewContent />
							</School>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/validate'}
						element={
							<Validate>
								<ValidateContent />
							</Validate>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/validate/educator'}
						element={
							<Validate>
								<ValidateEducatorContent />
							</Validate>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/validate/email_notification'}
						element={
							<Validate>
								<ValidateEmailContent />
							</Validate>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/validate/complete'}
						element={
							<Validate>
								<ValidateCompleteContent />
							</Validate>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/setup/adoption_type'}
						element={
							<CourseSetup>
								<CourseTypeContent1 />
							</CourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/setup/product'}
						element={
							<CourseSetup>
								<ProductContent1 />
							</CourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/setup/course_type'}
						element={
							<CourseSetup>
								<ProductContent1
									step={0}
									subStep={1}
								/>
							</CourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/setup/access'}
						element={
							<CourseSetup>
								<ProductContent1
									step={0}
									subStep={2}
								/>
							</CourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/setup/course'}
						element={
							<CourseSetup>
								<ProductContent1
									step={1}
									subStep={3}
								/>
							</CourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/setup/course_info'}
						element={
							<CourseSetup>
								<ProductContent1
									step={1}
									subStep={4}
								/>
							</CourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/setup/bookstore'}
						element={
							<CourseSetup>
								<ProductContent1
									step={2}
									subStep={5}
								/>
							</CourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/setup/lms'}
						element={
							<CourseSetup>
								<ProductContent1
									step={3}
									subStep={6}
								/>
							</CourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lmsConnect'}
						element={
							<Dashboard>
								<LMSConnect />
							</Dashboard>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lmsconnect/complete'}
						element={
							<CourseSetup>
								<LMSConnectionSuccess />
							</CourseSetup>
						}
					/>

					<Route
						path={process.env.PUBLIC_URL + '/setup/complete'}
						element={
							<CourseSetup>
								<CompleteContent />
							</CourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lms/setup/complete'}
						element={
							<LmsCourseSetup setIsLmsCourseHome={setIsLmsCourseHome}>
								<CompleteContent flow='/lms/setup/product' />
							</LmsCourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/setup/adjunct/add_course'}
						element={
							<CourseSetup>
								<AdjunctCreateCourseContent />
							</CourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/dashboard'}
						element={
							<Dashboard>
								<GreetContent />
							</Dashboard>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/adjunct/dashboard'}
						element={
							<Dashboard>
								<AdjunctGreetContent />
							</Dashboard>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/catalog'}
						element={
							<Dashboard>
								<FindTitleContent />
							</Dashboard>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lms/launch'}
						element={<CourseLaunch />}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lms/title/select'}
						element={
							<LmsDashboard>
								<TitleSelect />
							</LmsDashboard>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lms/signin'}
						element={
							<Signin>
								<SigninFormContent flow='lms' />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lms/link'}
						element={
							<Signin>
								<LinkAccount />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lms/linking'}
						element={
							<Signin>
								<LinkingAccount />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lms/linking/success'}
						element={
							<Signin>
								<LinkingSuccess />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lms/title/confirm'}
						element={
							<LmsDashboard>
								<TitleSelectConfirm />
							</LmsDashboard>
						}
					/>

					<Route
						path={process.env.PUBLIC_URL + '/lms/setup/product'}
						element={
							<LmsCourseSetup>
								<ProductContent1
									step={1}
									subStep={4}
									flow='lms/setup/product'
								/>
							</LmsCourseSetup>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/lms/course/home'}
						element={
							<CourseDisplay setIsLmsCourseHome={setIsLmsCourseHome}>
								<CourseHome
									step={0}
									subStep={4}
									flow='lms/setup/product'
								/>
							</CourseDisplay>
						}
					/>
					{/* Adjunct pickup through LMS*/}
					<Route
						path={process.env.PUBLIC_URL + '/adjunct/lms/steps'}
						element={
							<AdjunctLmsLinking>
								<AdjunctLmsSteps accountLinked={false} />
							</AdjunctLmsLinking>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/adjunct/lms/steps2'}
						element={
							<AdjunctLmsLinking>
								<AdjunctLmsSteps accountLinked={true} />
							</AdjunctLmsLinking>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/adjunct/lms/signin'}
						element={
							<Signin>
								<SigninFormContent flow='adjunct/lms' />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/adjunct/lms/link'}
						element={
							<Signin>
								<LinkAccount userType='adjunct' />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/adjunct/lms/linking'}
						element={
							<Signin>
								<LinkingAccount userType='adjunct' />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/adjunct/lms/linking/success'}
						element={
							<Signin>
								<LinkingSuccess userType='adjunct' />
							</Signin>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/adjunct/setup/add_course'}
						element={
							<AdjunctLmsLinking>
								<AdjunctCreateCourseContent userType='adjunct' />
							</AdjunctLmsLinking>
						}
					/>
					<Route
						path={process.env.PUBLIC_URL + '/adjunct/setup/complete'}
						element={
							<AdjunctLmsLinking>
								<CompleteContent userType='adjunct' />
							</AdjunctLmsLinking>
						}
					/>
				</Routes>
			</Router>
		</div>
	);
}

export default App;
