import React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
// import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

function CustomChip({ label, onDelete }) {
  const styles = {
    chipContainer: {
      backgroundColor: "#e0e0e0",
      display: "inline-flex",
      flexWrap: "wrap",
      flexDirection: "row-reverse",
      alignItems: "center",
      boxSizing: "border-box",
      borderRadius: "10px",
      minHeight: "24px",
    },
    label: {
      padding: "6px 10px 6px 10px",
      margin: 0,
      flex: "1 1 auto", // Allow the Typography to grow within the container
      overflow: "hidden", // Hide overflow text
    },
    deleteButton: {
      cursor: "pointer",
      margin: "0", // Remove all margins
      color: "rgba(0, 0, 0, 0.26)",
      padding: "6px 0 6px 10px", // Add padding to match IconButton's default size
      paddingRight: "0",
      backgroundColor: "transparent",
    },
  };

  return (
    <div className="custom-chip-container" style={styles.chipContainer}>
      <Typography variant="body2" gutterBottom style={styles.label}>
        {label || ""}
      </Typography>

      {onDelete && (
        <IconButton style={styles.deleteButton} onClick={() => onDelete(label)}>
          {/* <CancelRoundedIcon /> */}
          <CloseIcon style={{ width: "13px" }} />
        </IconButton>
      )}
    </div>
  );
}

CustomChip.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.func,
};

export default CustomChip;
