import React, { useEffect, useState } from "react";
import BookInfo from "./BookInfo";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/slices/userSlice";
import Button from "../../global/input/Button";

const CourseInfoContent2 = ({
  creatingCourseAnimation,
  lmsCreatingCourseAnimation,
  currentStep,
  currentSubStep,
  setCurrentStep,
  setCurrentSubstep,
  flow,
  setMode,
}) => {
  const dispatch = useDispatch();
  const nonLmsAndLmsConfirmSetupText =
    "Almost done, let's check and make sure everything looks good.";
  const lmsEditSetupText =
    "Looks like this course already has Type & Price taken care of.  Check it over and click next to continue.";
  const userData = useSelector((state) => state.user);
  let displayMessage = "";
  let priceMessage = "";

  if (flow === "lms/setup/product") {
    displayMessage = userData.lmsPriceMessage;
    priceMessage = userData.lmsPackagePrice;
  } else {
    switch (userData.courseType) {
      case "student":
        if (
          userData.packageType == "eText bundle" &&
          userData.courseAccess === "inclusive"
        ) {
          displayMessage = "Add to Student Fees (Inclusive Access)";
          priceMessage = "Student Price: $89.00 18wk access";
        } else if (
          userData.packageType == "eText bundle" &&
          userData.courseAccess === "bookstore"
        ) {
          displayMessage = "Purchase from bookstore ";
          priceMessage = "Student Price: $119.00 18wk access";
        } else if (
          userData.packageType == "eTextbook" &&
          userData.courseAccess === "inclusive"
        ) {
          displayMessage = "Add to Student Fees (Inclusive Access)";
          priceMessage = "Student Price: $33.97 4mo access";
        } else if (
          userData.packageType == "eTextbook" &&
          userData.courseAccess === "bookstore"
        ) {
          displayMessage = "Purchase from bookstore";
          priceMessage = "Student Price: $43.97 4mo access";
        }
        break;
      case "instructor":
        displayMessage = "Instructor use only";
        priceMessage = "";
      //
      default:
    }
  }
  //Fotmat date to mm/dd/yyyy
  const getFormattedDate = (dateStr) => {
    var date = new Date(dateStr);
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = (1 + date.getDate()).toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
  };

  const handleCreateAnimations = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const creatingLmsCourseAnimations = (flow === "lms/setup/product" && currentStep === 1 && currentSubStep === 4);
    console.log("CourseInfoContent: currentStep:", currentStep);
    console.log("creatingLmsCourseAnimations:", creatingLmsCourseAnimations )
    if (flow === "lms/setup/product" && currentStep === 0) {
      setCurrentStep(1);
    }
    /*LAUNCH CREATING COURSE FOR LMS PROTOTYPE*/ 
    else if (creatingLmsCourseAnimations) {
      lmsCreatingCourseAnimation(event);
    } 
    // else if (connectingLmsBookstoreAnimations) {
    //   // Do nothing
    // }
    else {
      creatingCourseAnimation(event);
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 80);

    if (userData.userType == "instructor") {
      dispatch(setUser({ packagePrice: "" }));
    }
  }, []);

  return (
    <section className="confirm-create-course">
      {/****HEADER TEXT ****/}
      {flow === "lms/setup/product" && currentStep === 0 ? (
        <div className="container">
          <p className="lms-step-priompt">{lmsEditSetupText}</p>
        </div>
      ) : (
        <p className="step-priompt">{nonLmsAndLmsConfirmSetupText}</p>
      )}

      {/*****COURSE INFO *****/}
      {flow === "lms/setup/product" && currentStep === 0 ? (
        <></>
      ) : (
        <>
          <h3>Course Information</h3>
          <div className="summary">
            <h4>{userData.courseName}</h4>

            <ul>
              <li>Start date: {getFormattedDate(userData.courseStart)}</li>
              <li>End date: {getFormattedDate(userData.courseEnd)}</li>
              <li>
                Students can enroll after:{" "}
                {getFormattedDate(userData.enrollStart)}
              </li>
              <li>Course timezone: {userData.courseTimezone}</li>
            </ul>

            {/* <button className="secondary">Edit</button> */}

            <Button
              label="Edit"
              variant="secondary"
              size="medium"
              newContent="CreateCourseContent2"
              newPage="CourseSetup"
              newPath="/setup/course"
              refreshPage={true}
              setCurrentStep={
                flow === "lms/setup/product" && currentStep === 1
                  ? () => setCurrentStep(0)
                  : () => setCurrentStep(1)
              }
              setCurrentSubstep={() => setCurrentSubstep(3)}
              {...(flow === "lms/setup/product" && {
                setMode: () => setMode("editingCourseInfo"),
              })}
            />
          </div>
          {/********TITLE INFO*********/}
          <div className="summary">
            <h3>Title information</h3>
            <BookInfo type={"large"} />
            {flow === "lms/setup/product" ? (
              <Button
                label="Edit"
                variant="secondary"
                size="medium"
                newContent="ProductContent1"
                newPage="CourseSetup"
                newPath="/setup/product"
                refreshPage={true}
                setCurrentStep={() => setCurrentStep(0)}
                setCurrentSubstep={() => setCurrentSubstep(0)}
                disabled={
                  flow === "lms/setup/product" && currentStep === 1
                    ? true
                    : false
                }
              />
            ) : (
              <></>
            )}
          </div>
        </>
      )}
      <div className="summary">
        <h4>
          {flow === "lms/setup/product"
            ? "Mastering / " + userData.lmsPackageType
            : userData.packageType === "eText bundle"
            ? "Mastering / " + userData.packageType
            : userData.packageType}
        </h4>
        {
          // flow === "lms/setup/product" &&
          currentStep === 2 ? (
            <Button
              label="Edit"
              variant="secondary"
              size="medium"
              newContent="ProductContent1"
              newPage="CourseSetup"
              newPath="/setup/product"
              refreshPage={true}
              setCurrentStep={() => setCurrentStep(0)}
              setCurrentSubstep={() => setCurrentSubstep(0)}
              setMode={() => setMode("editingProductInfo")}
              disabled={
                flow === "lms/setup/product" && currentStep === 1 ? true : false
              }
            />
          ) : (
            <></>
          )
        }
      </div>

      {/* Add to Student Fees (Inclusive Access) */}
      <div className="summary">
        <h4>{displayMessage}</h4>
        {
          // flow === "lms/setup/product" &&
          currentStep === 2 ? (
            <Button
              label="Edit"
              variant="secondary"
              size="medium"
              newContent="CourseTypeContent1"
              newPage="CourseSetup"
              newPath="/setup/access"
              refreshPage={true}
              setCurrentStep={() => setCurrentStep(0)}
              setCurrentSubstep={() => setCurrentSubstep(2)}
              setMode={() => setMode("editingAccessInfo")}
              disabled={
                flow === "lms/setup/product" && currentStep === 1 ? true : false
              }
            />
          ) : (
            <></>
          )
        }

        <p>{priceMessage}</p>
      </div>

      <div className="step-action">
        <button
          type="button"
          className="primary"
          onClick={handleCreateAnimations}
        >
          {flow === "lms/setup/product" && currentStep === 0
            ? "Next"
            : "Create"}
        </button>
      </div>
    </section>
  );
};

export default CourseInfoContent2;
