import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Text from "../../global/input/Text";
import Button from "../../global/input/Button";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/slices/userSlice";
import { setNavigation } from "../../../redux/slices/navigationSlice";
import { useNavigate } from "react-router";
import displaySvgIcon from "../../../utilities/displaySvgIcon";
import StaticSelect from "../../global/input/StaticSelect";
import InputChips from "../../global/input/InputChips";
import { setUserDisciplines } from "../../../redux/slices/userSlice";
// import universityCollege from "../../../assets/images/college-university.png";
// import nashvilleCollege from "../../../assets/images/college-nashville.png";
// import ivyCollege from "../../../assets/images/college-ivy.png";

import {
  updateValidated,
  updateSchool,
} from "../../../redux/slices/collegeSlice";
import toTitleCase from "../../../utilities/toTitleCase";

const ValidateEducatorContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  console.log("USERData:", userData);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [department, setDepartment] = useState(null);

  // console.log("Nashville College:", nashvilleCollege);
  // console.log("University College:", universityCollege);
  const collegeOptions = useSelector((state) => state.college);
  const departmentOptions = collegeOptions.departments;
  const collegeData = collegeOptions.schools;
  console.log("CollegeOptions:", collegeOptions);

  const [disciplineNames, setDisciplineNames] = useState("");

  const getCollege = () => {
    const label = userData?.schoolName;
    const currentCollege = collegeData.filter(
      (college) => college.label === label
    )[0];
    // Creating a new object with the previous data
    const updatedObject = {
      ...currentCollege, // Copying all properties from the original object
      label: currentCollege.label + "_____", // Updating the label
    };
    console.log("CCC:", updatedObject);
    // Set College:
    return updatedObject;
  };

  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      schoolName: getCollege(),
    },
  });

  const handleSubmitForm = async (data, event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("SUBMIT DATA2:", data);

    // Deteermine current college name, if it has been selected or not
    let currentSchoolName = null;
    if (
      data &&
      typeof data.schoolName === "object" &&
      selectedCollege === null
    ) {
      currentSchoolName = data?.schoolName.label.replace(/_+$/, "");
    }
    if (selectedCollege?.label && currentSchoolName === null) {
      currentSchoolName = selectedCollege?.label;
    }
    console.log(
      "SCHOOLNAME:",
      currentSchoolName,
      "SELECTEDCOLLEGE:",
      selectedCollege
    );

    if (department) {
      data.department = department;
    }
    const newUserData = {};

    /**
     * Step 1: Add SchoolName,  Image, email, department,
     * and discipling to UserData Object and update user slice
     **/
    if (data?.schoolName?.label) {
      // Remove underscores from the data label
      data.schoolName.label = data.schoolName.label.replace(/_/g, "");

      newUserData.schoolName = data?.schoolName?.label;
      newUserData.schoolImage = data?.schoolName?.imageName;
      console.log("NEW USER DATA", newUserData);
    }
    if (data?.email) {
      newUserData.email = data?.email;
    }
    if (data?.department) {
      console.log("DEPARTMENT", data?.department);
      newUserData.schoolDepartment = data?.department;
    } else {
      console.log("NO DEPARTMENT");
      newUserData.schoolDepartment = "";
      console.log("NEW USER DATA", newUserData);
      await dispatch(setUser({ schoolDepartment: "" }));
    }
    const disciplinesLength = disciplineNames.length;
    if (disciplinesLength > 0) {
      await dispatch(setUserDisciplines({ disciplines: disciplineNames }));
    } else {
      await dispatch(setUserDisciplines({ disciplines: [] }));
    }
    await dispatch(setUser(newUserData));

    /**
     * Step 2: Validate Selected School
     */
    if (currentSchoolName && data?.schoolName?.value) {
      await dispatch(
        updateValidated({
          labelsToUpdate: [currentSchoolName],
          value: data.schoolName.value,
        })
      );
    }

    const page = "Validate";
    const path = "/validate/email_notification";
    const navResponse = await dispatch(setNavigation({ path, page }));
    console.log("Response:", navResponse);
    navigate(`${process.env.PUBLIC_URL}${navResponse.payload.path}`);
  };
  
  const departmentSelectHandler = async (data, event) => {
    console.log("College1:", data);
    setDepartment(data.label);
    if (data?.label) {
      dispatch(setUser({ schoolDepartment: data.label }));
    }
  };

  /**
   * React-Select college selector handler
   * @param {*} data
   * @param {*} event
   */
  const collegeSelectHandler = async (data, event) => {
    console.log("College1:", data);

    // Remove underscores from the data label
    // const cleanedData = { ...data, label: data.label.replace(/_/g, "") };

    // setSelectedCollege(cleanedData);
    setSelectedCollege(data);
    // Add "_____" to the end of the data label
    const updatedLabel = data.label + "_____";
    // Set the updated label as the value for "schoolName"
    setValue("schoolName", { ...data, label: updatedLabel });

    // setValue("schoolName", data );
  };

  /**
   * New react-select dropdown icon function
   * @param {*} svg
   * @returns
   */
  const DropdownIndicator = (svg) => svg;

  useEffect(() => {
    console.log("ChipLabels:", disciplineNames);
  }, [disciplineNames.length]);

  return (
    <form
      className="validate-educator-content"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <div className="header">
        <h3 className="title">Educator validation</h3>
        <div className="input-group">
          <StaticSelect
            labelClass="label-element"
            isMulti={false}
            placeholder="select one"
            name="schoolName"
            label="Verify your school"
            options={collegeOptions.schools}
            control={control}
            DropdownIndicator={() =>
              DropdownIndicator(displaySvgIcon("dropdown"))
            }
            card
            selectHandler={collegeSelectHandler}
            required={true}
          />
          {console.log("SelectedCollege3:", selectedCollege)}
          {console.log("UserDataCollege3:", userData)}
          <div className="image-container">
            {selectedCollege?.imageName ? (
              <img
                width="358px"
                src={selectedCollege.imageName}
                alt={"school"}
              />
            ) : (
              <img width="358px" src={userData.schoolImage} alt={"school"} />
            )}
          </div>
          {/* <Text
            displayType="row"
            register={register}
            className="form-input"
            id="formGroupDepartment"
            testId="department"
            label="What department do you teach in (optional)"
            labelClass="label-element"
            name="department"
            placeholder="Start typing the department"
            // required={true}
          /> */}
          <StaticSelect
            labelClass="label-element"
            isMulti={false}
            placeholder="Start typing the department"
            name="department"
            label="What department do you teach in (optional)"
            options={departmentOptions}
            control={control}
            DropdownIndicator={() =>
              DropdownIndicator(displaySvgIcon("dropdown"))
            }
            card
            selectHandler={departmentSelectHandler}
            required={false}
          />
          <InputChips
            placeholder="Type the discipline, then press Enter"
            displayType="row"
            register={register}
            className="form-input"
            id="formGroupDisciplines"
            testId="disciplines"
            label="What disciplines do you teach (optional)"
            labelClass="label-element"
            name="disciplines"
            // required={true}
            setChipLabels={setDisciplineNames}
          />
          <Text
            displayType="row"
            register={register}
            className="form-input"
            id="formGroupEmail"
            testId="email"
            label="School email address"
            labelClass="label-element"
            name="email"
            required={true}
            value={userData?.email}
          />

          {/* INPUT GROUP */}
        </div>
        {/* HEADER */}
      </div>
      <footer>
        <div className="row">
          <div className="col">
            <Button
              type="button"
              displayType="segment"
              label="Skip for now"
              variant="attention"
              size="medium"
              className="adopt-btn"
              newContent="ProductContent1"
              newPage="Product"
              newPath="/setup/product"
              // newContent="ValidateEducatorContent"
              // newPage="Validate"
              // newPath="/validate/educator"
            />
          </div>
        </div>

        <Button
          label="Validate my educator status"
          variant="attention"
          size="medium"
          className="adopt-btn"
          // clickHandler={handleSubmitForm}
          // newContent="ValidateEmailContent"
          // newPage="Validate"
          // newPath="/validate/email_notification"
        />
      </footer>
    </form>
  );
};

export default ValidateEducatorContent;
