import React, { useState, useEffect } from 'react';
import LmsCourseHeader from "../../../global/LmsCourseHeader";
import displaySvgIcon from "../../../../utilities/displaySvgIcon";
import Assignment from "./partial/Assignment";
import Profile from "./partial/Profile";

// import { useLocation } from "react-router-dom";

const CourseHome = ({
  step,
  subStep,
  flow,
}) => {
 
  const Assignments = [
    {
      month: "OCT",
      day: "3",
      title: "3.1 Quadratic Functions and Models",
    },
    {
      month: "OCT",
      day: "5",
      title: "3.1 Quadratic Functions",
    },
    {
      month: "OCT",
      day: "3",
      title: "3.1 Exercises",
    },
    {
      month: "OCT",
      day: "3",
      title: "3.1 Exercises (23-72)",
    },
    {
      month: "OCT",
      day: "12",
      title: "3.1 Exercises (73-96)",
    },
    {
      month: "OCT",
      day: "14",
      title: "3.3 Zeros of Polynomial Functions",
    },
    {
      month: "OCT",
      day: "24",
      title: "Chapter 3 Quiz",
    },
  ];

  return (
    <div className="course-content">
      <LmsCourseHeader />
      <div className="course-home-container">
        <h3 className="course-home-header">Welcome!</h3>
        <main>
          <div className="left column">
            <div className="col-container">
              <div className="content">
                <h3>Assignment creation</h3>
                <div className="top row">
                  <div className="assigntment-background">
                    <h2>Continue creating your assignment</h2>
                    <span className="resume-btn">
                      <h2>Resume</h2>
                    </span>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div className="content">
                <div className="bottom row">
                  <div className="subtitle-container">
                    <h3>Assignments</h3>
                    <h3>All</h3>
                  </div>
                  <div className="assignments-background">
                    {Assignments.map((assignment, index) => (
                      <Assignment
                        key={index}
                        month={assignment.month}
                        day={assignment.day}
                        title={assignment.title}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="right column">
            <div className="col-container">
              <div className="content">
                <div className="first  row">
                  <div className="subtitle-container">
                    <h3>Performance</h3>
                    <span className="header-handles">
                      {displaySvgIcon("white-handles")}
                    </span>
                  </div>
                  <div className="product-background">
                    {displaySvgIcon("product-icon")}
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="second row">
                  <div className="subtitle-container">
                    <h3>Profile</h3>
                    <span className="header-handles">
                      {displaySvgIcon("white-handles")}
                    </span>
                  </div>
                  <div className="profile-background">
                    <Profile name="Mary Ericson" title="Instructor" />
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="third row">
                  <div className="subtitle-container">
                    <h3>Announcements</h3>
                    <span className="header-handles">
                      {displaySvgIcon("white-handles")}
                    </span>
                  </div>
                  <div className="announce-background">
                    <div className="announce-group">
                      <p className="announce-text">
                        Lorem ipsum dolor sit amet consectetur. Pharetra
                        faucibus dolor posuere pulvinar vulputate. Plac eget
                        egestas sed blandit. Proin tempor habitant.
                      </p>
                      <p className="announce-text">
                        Lorem ipsum dolor sit amet consectetur. Pharetra
                        faucibus dolor posuere vulputate. Placerat viverra.
                      </p>

                      <div className="post-group">
                        <textarea
                          placeholder="Search"
                          placeHolder="Write an announcement"
                        />
                        <div className="post-btn">Post</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CourseHome;
