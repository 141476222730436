const TitleCard = ({title, flow}) => {
    return (
        <div className="dashboard-title-card">
              <div className="title-card-cover">
                    <img src={title.imagePath}/>
              </div>
              <div className="title-card-info">
                <p className="title">{flow === "lms" ? `${title.label} + w/ etext bundle`: title.label}</p>
                <div>
                    <p>{title.edition} edition</p>
                    <p>{title.authors}</p>
                </div>
                {flow === "lms" ? <p>{title.lmsTemplateName}</p> : <button className="secondary">Take a look</button>}
              </div>
        </div>
    )
}

export default TitleCard