import { createSlice } from "@reduxjs/toolkit";
import {
  getUserReducer,
  setUserReducer,
  updateUserDisciplinesReducer,
} from "../reducers/userReducer";

// import navbarData from "../data/navbarData";
import userData from "../data/userData";

/********************************
 * Configure Slice with reducers
 * and Export actions and reducer
 *******************************/
const userSlice = createSlice({
  name: "user",
  initialState: userData,
  reducers: {
    getUser: getUserReducer,
    setUser: setUserReducer,
    setUserDisciplines: updateUserDisciplinesReducer,
  },
});

// Note: userSlice.actions automatically creates actions based on our reducer names
export const { getUser, setUser, setUserDisciplines } = userSlice.actions;

// export  reducer, so it can be added to the store
export default userSlice.reducer;
