import React from 'react';
import linkingImg from "../../../../assets/images/linking-accounts.png";
import { useNavigate } from "react-router";
import LoadingSpinner from '../../../global/output/LoadingSpinner';

const LinkingAccount = (props) => {
    const navigate = useNavigate();
    const linkSuccess = props.userType === 'adjunct' ? "/adjunct/lms/linking/success" : "/lms/linking/success";

    setTimeout(() => {
        navigate(process.env.PUBLIC_URL + linkSuccess);
    }, 5000);

  return (
    <form
      className="linking-account"
      //   onSubmit={handleSubmit(handleSubmitForm)}
    >
      <div className="header">
        <h3 className="title">Linking accounts...</h3>
        <div className="spinner-container" style={{marginTop: "10px", marginBottom: "10px"}}>
        <LoadingSpinner width="70px" height="70px" />
      </div>
      </div>{/* HEADER */}
      <div className="row image-container"/>
      <h3 className="title">Once your accounts are linked you will not need to do this again. Simply click the link in your LMS course and we’ll take you right to Pearson</h3>
    </form>
  );
};

export default LinkingAccount;