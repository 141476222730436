import nashvilleCollege from "../../assets/images/college-nashville.png";
import universityCollege from "../../assets/images/college-university.png";
import ivyCollege from "../../assets/images/college-ivy.png";

const collegeData = {
  schools: [
    {
      value: "0",
      label: "University College",
      imageName: universityCollege,
      role: "Instructor",
      email:"mary.ericson@univ.edu",
      validated: false,
      default: true,
      defaulted: true,
    },
    {
      value: "1",
      label: "University College",
      imageName: universityCollege,
      role: "Admin",
      email:"mary.ericson@univ.cc.edu",
      validated: false,
      default: false,
      defaulted: false,
    },
    {
      value: "2",
      label: "Nashville Community",
      imageName: nashvilleCollege,
      role: "Instructor",
      email:"mary.ericson@nash.cc.edu",
      validated: false,
      default: false,
      defaulted: false,
    },
    {
      value: "3",
      label: "Ivy-League University",
      imageName: ivyCollege,
      role: "Instructor",
      email:"mary.ericson@ivy.lg.edu",
      validated: false,
      default: false,
      defaulted: false,
    },
  ],
  departments: [
    {
      value: "1",
      label: "Art",
    },
    {
      value: "2",
      label: "Astronomy",
    },
    {
      value: "3",
      label: "Biology",
    },
    {
      value: "4",
      label: "Business",
    },
    {
      value: "5",
      label: "Business / Economics",
    },
    {
      value: "6",
      label: "Business Info Systems",
    },
    {
      value: "7",
      label: "Chemistry",
    },
    {
      value: "8",
      label: "Computer Science",
    },
    {
      value: "9",
      label: "Developmental Studies",
    },
  ],
};

export default collegeData;
