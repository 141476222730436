/** @format */
// import '../../assets/styles/Sass/styles.scss';
import { useDispatch, useSelector } from "react-redux";
import { setNavigation } from "../../../redux/slices/navigationSlice";
import { useNavigate } from "react-router";

const Button = ({
  label,
  variant,
  size,
  disabled,
  newPage,
  newContent,
  newPath,
  clickHandler,
  type,
  refreshPage,
  setCurrentStep,
  setCurrentSubstep,
  setMode,
  setShowModal,
  validated,
  ...rest
}) => {
  const dispatch = useDispatch();
  // const navData = useSelector((state) => state.navigation);
  const navigate = useNavigate();

  const handleNavigation = async (e) => {
    e.preventDefault();
    e.stopPropagation();
   
    // Manual transition setup course pages
    if (refreshPage) {
      setCurrentStep();
      setCurrentSubstep();
      // Redirect to the Corresponding Section BundleType or ProductType
      if (setMode ) {setMode()}
      console.log("Redirecting1: to From CourseEdit to StudentAccess")
    }
    // Auto transiton other sections
    else {
      // console.log("RefreshPage:", refreshPage);
      // console.log("NewPath:", newPath);
      const path = newPath;
      const response = await dispatch(setNavigation({ path }));
      console.log("Response:", response.payload.path);
      navigate(`${process.env.PUBLIC_URL}${response.payload.path}`);
    }
  };

  // console.log("NavData:", navData);
  return (
    <button
      // {...(!newPath && !newPage && !newContent && {type: "submit"})}
      type={type ? type : "submit"}
      className={`pso-btn ${variant ? variant : ""} ${size ? size : ""}`}
      {...rest}
      {...disabled && (disabled={disabled})}
      {...(newPath &&
        newPage &&
        newContent && { onClick: (e) => handleNavigation(e) })}
      {...(clickHandler && { onClick: (e) => clickHandler(e) })}
      {...(setShowModal && { onClick: (e) => {
        e.preventDefault();
         e.stopPropagation(); 
         setShowModal();
}})} 
    >
      {label}
    </button>
  );
};

export default Button;
