import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/slices/userSlice";
import { setNavigation } from "../../../redux/slices/navigationSlice";
import { useNavigate } from "react-router";
import LoadingSpinner from "../../global/output/LoadingSpinner";

const ValidateEmailContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  console.log("USERData:", userData);

  setTimeout(async () => {
    const path = "/validate/complete";
    const page = "ValidateCompleteContent";
    const navResponse = await dispatch(setNavigation({ path, page }));
    console.log("Response:", navResponse);
    navigate(`${process.env.PUBLIC_URL}${navResponse.payload.path}`);
  }, 3000);

  return (
    <form
      className="validate-educator-content"
      //   onSubmit={handleSubmit(handleSubmitForm)}
    >
      <div className="header">
        <h3 className="title">Educator validation</h3>
        {/* HEADER */}
      </div>
      <div className="image-container">
        {userData?.schoolImage ? (
          <img width="358px" src={userData.schoolImage} alt={"school"} />
        ) : (
          <></>
        )}
      </div>
      {/* <section> */}
      <p className="status-message">
        Validation Status, this may take a minute...
      </p>
      <div className="spinner-container">
        <LoadingSpinner width="150px" height="150px" />
      </div>
      <p className="email-message">
        {`An email has been sent to ${userData.email}. Please click on the link to
        complete the validation process.`}
      </p>
      {/* </section> */}
      {/* INPUT GROUP */}
    </form>
  );
};

export default ValidateEmailContent;
