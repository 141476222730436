import React, { useState, useEffect } from "react";
import connectionSuccessImg from "../../../assets/images/high_five.png";
import Button from "../../global/input/Button";

const CompleteContent = ({ flow }) => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 80);
  }, []);

  return (
    <div className="page-layout">
      
      <h1>Connection Success!</h1>

      <img src={connectionSuccessImg} alt="" style={{'display': 'block', 'margin': '0 auto'}} />

      <div className="setup-complete-message">
        <p>
        If at any time you need to contact help regarding this title set up. Your reference number is #2304982304982
        </p>
        <p>
          An email with your receipt and order number has been sent to your
          primary email address, you can also{" "}
          <a href="javascript:void(0);">Download a PDF copy of the receipt and order number</a>
        </p>

       
          <Button
            label="Go to Dashboard"
            variant="primary"
            size="medium"
            newContent="Dashboard"
            newPage="Dashboard"
            newPath="/dashboard"
          />
      </div>
    </div>
  );
};

export default CompleteContent;
