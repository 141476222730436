import React from "react";
import avatar from "../../../../../assets/images/instructor.png";
const Profile = ({ name, title }) => {
  return (
    <div className="profile-group">
      <div className="instructor-avatar">
        <img src={avatar} alt="" />
      </div>
      <div className="labels">
        <h2>{name}</h2>
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export default Profile;
