import { forwardRef } from "react";

const LmsConnectingToBookstore = forwardRef((props, ref) => {
  return (
    <div className="modal setup">
      <h2>Connecting to Bookstore</h2>

      <ul ref={ref}>
        <li>Sending title information to bookstore...</li>
        <li>Receiving validation data received....</li>
      </ul>
    </div>
  );
});

export default LmsConnectingToBookstore;
