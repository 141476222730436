import {useState} from 'react';
import Tabs from '../../global/Tabs';
import { useDispatch, useSelector } from "react-redux";
import Button from "../../global/input/Button"
import AdjunctCourseCard from './AdjunctCourseCard';
import InviteStudents from './InviteStudents';
import getFormattedDate from "../../../utilities/getFormattedDate";
//images
import image from "../../../assets/images/layouts/dashboard/image-placeholder.svg";

const tabItems = [
    {
        label: "Current",
        id: "current",
    },
    {
        label: "Past",
        id: "past",
    }
]

const GreetContent = () => {

    const userData = useSelector((state) => state.user);
    const pearsonData = useSelector((state) => state.pearson);
    const selectedBookName= useSelector((state) => state.user).bookName;
    const selectedBook = pearsonData.courses.filter(a => a.label === selectedBookName)[0];
    const selectedBookWithDates = {
        ...selectedBook,
        dates: `${getFormattedDate(userData.courseStart)} - ${getFormattedDate(userData.courseEnd)}`,
        bookName: userData.bookName,
        schoolName: userData.schoolName,
      };
    // console.log("SelectedBookData1:", selectedBookWithDates)

    const [activeTab, setActiveTab] = useState("current")
    const [showInviteStudents, setShowInviteStudents] = useState(false)

    const handleOpenInviteStudents = () => {
        setShowInviteStudents(true)
    }

    const handleCloseInviteStudents = () => {
        setShowInviteStudents(false)
    }

    return (
        <>
        {
            showInviteStudents ? 
            <InviteStudents
                handleClose={handleCloseInviteStudents}
            />
            : null
        }
        <div className='page-layout'>
            <h1>{`Welcome ${userData.firstName}!`}</h1>
            <div className='dashboard-header'>
                <h2>My courses</h2>
                <Button
                    label="Find a title"
                    variant="secondary"
                    size="medium"
                    newContent="Catalog"
                    newPage="Setup"
                    newPath="/catalog"
                />
            </div>
            
            <Tabs
                items={tabItems}
                activeTab={activeTab}
            />
            {
                tabItems.map((item,index)=>{
                    return(
                        <div
                            key={index}
                            role="tabpanel"
                            aria-labelledby={`${item.id}-tab`}
                            id={`${item.id}-tab-panel`}
                            tabindex={`${item.defaultActive ? "0" : "-1"}`}
                            className={`tabpanel ${activeTab != item.id ? "is-hidden" : ""}`}
                        >
                            {
                                item.id === "current" ? 
                                <>
                                {/* {console.log("Course Name:", userData.courseName, "School Name:", userData.schoolName, userData.schoolName === "Nashville Community" )} */}
                                    <AdjunctCourseCard 
                                        course={selectedBookWithDates}
                                        // courseName={userData.courseName}
                                        courseName={userData.schoolName === "University College" ? "New! Biology 101 Section 8" : (
                                            userData.schoolName === "Nashville Community" ? "BIO 181 General Biology" : "New! Biology 101 Section 9"
                                        )}
                                        type='current'
                                        lmsConnect = {userData.lmsConnect}
                                        handleOpenInviteStudents={handleOpenInviteStudents}
                                    />
                                </>
                                : 
                                <>
                                    Past content
                                </>
                            }
                            <div className='dashboard-2-column'>
                                <div>
                                     <h4>Tackle teaching challenges with Revel</h4>
                                     <p>Think about your first teaching experience in higher education. What was it like? Did you feel prepared to address your students’ learning needs? “In higher education, we aren’t necessarily taught how to be teachers,” says Anakira Gabriela, a...</p>
                                     <button className='secondary'>Read this article</button>
                                </div>
                                <div className='large-image-placeholder'>
                                    <img src={image}/>
                                </div>
                            </div>

                            <div className='dashboard-3-column'>
                                <div>
                                     <h5>Inclusive Access in Higher Education: Unlocking Student Success</h5>
                                     <p>Inclusive Access (IA) provides students with a more cost-efficient, success-driven option for obtaining...</p>
                                     <div className='large-image-placeholder'>
                                        <img src={image}/>
                                    </div>
                                </div>
                                <div>
                                    <h5>Meet Emily Taylor, author on Biological Science</h5>
                                    <p>1. What course(s) do/did you teach and where? I teach at the California Polytechnic State University...</p>
                                    <div className='large-image-placeholder'>
                                        <img src={image}/>
                                    </div>
                                </div>
                                <div>
                                    <h5>Active learning and engagement in the classroom</h5>
                                    <p>Students don’t know how to study.  As a former instructor of undergraduate students, I would hear students...</p>
                                    <div className='large-image-placeholder'>
                                        <img src={image}/>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    )
                })
            }
        </div>
        </>
    );
};

export default GreetContent;