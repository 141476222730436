import React , {useState, useEffect} from "react";
import LmsCourseNavbar from "./../components/global/LmsCourseNavbar";
import { useLocation } from "react-router-dom";

const CourseDisplay = ({ children, setIsLmsCourseHome }) => {
  const [rerenderFlag, setRerenderFlag] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Check if the path includes "lms/course/home"
    if (location.pathname.includes("lms/course/home")) {
      // Toggle the rerender flag to force a rerender
      setRerenderFlag(prevFlag => !prevFlag);
      console.log("location.pathname", location.pathname, "isSet:",location.pathname.includes("lms/course/home"));
      setIsLmsCourseHome(true);
    }
  }, [location.pathname]);
  
  return (
    <div className="course-display-container">
      <LmsCourseNavbar/>
      {children}
    </div>
  );
};

export default CourseDisplay;
