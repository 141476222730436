import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../redux/slices/userSlice";
import { useNavigate } from "react-router";
import Button from "../../../global/input/Button";
import TitleCard from "../../dashboard/TitleCard";
import CourseCard from "../../dashboard/CourseCard";

const TitleSelectConfirm = () => {
  const pearsonData = useSelector((state) => state.pearson);
  const titleData = pearsonData.titles;

  const courseData = pearsonData.courses;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  console.log("USERData:", userData);

  return (
    <div className="lms-title-confirm page-layout">
      <form
        className="title-select-content"
        //   onSubmit={handleSubmit(handleSubmitForm)}
      >
        <div className="header">
          <h3 className="title">Select your Title</h3>
          {/* HEADER */}
        </div>

        <div className="row">
          <section className="text">
            <p className="section1">
              This course is set to use the following title and assignment
              template
            </p>
          </section>
        </div>
        <div className="row">
          <section className="card-group">
            <div className="search-results">
              {titleData.map((title, index) => {
                if (index === 0) {
                  return <TitleCard title={title} flow="lms" />;
                }
              })}
            </div>
            <div className="dashboard-previous-courses">
              {courseData.map((course, index) => {
                // console.log("CourseDisplay:", course)
                if (index === 0) {
                  return (
                    <CourseCard course={course} type="search" flow="lms" />
                  );
                }
              })}
            </div>
          </section>
        </div>

        <footer>
          <Button
            label="Verify title for course"
            variant="attention"
            size="medium"
            className="adopt-btn"
            //   clickHandler={handleSubmitForm}
            newContent="CourseSetup"
            newPage="Setup"
            newPath="/lms/setup/product"
          />
           <p>This is not the right title</p>
        </footer>
      </form>
    </div>
  );
};

export default TitleSelectConfirm;
