import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../global/input/Button";

const AdjunctLmsSteps = (props) => {

    return(
        <section class="page-in-lms">
            <p>Thank you for choosing Pearson.</p>
            <p>Let's link your Pearson title to your course.</p>

            <div className="steps">
                <div className="step">
                    <h2 className={props.accountLinked ? 'complete' : ''}>Step 1</h2>
                    <h3>Link accounts</h3>
                    <p>First, you'll need to sign in and link your Pearson account with yout Canvas account.</p>
                    <Button
                        label={'Link account'}
                        variant="primary"
                        size="medium"
                        newContent="SigninFormContent"
                        newPage="Start"
                        newPath="/adjunct/lms/signin"
                        disabled={props.accountLinked ? true : false}
                    />
                </div>
                <div className="step">
                    <h2>Step 2</h2>
                    <h3>Link Course</h3>
                    <p>Next, you'll need to sign in and link your Pearson account with yout Canvas account.</p>
                    <Button
                        label={'Link course'}
                        variant="primary"
                        size="medium"
                        newContent="AdjunctCreateCourseContent"
                        newPage="CourseSetup"
                        newPath="/adjunct/setup/add_course"
                        disabled={props.accountLinked ? false : true}
                    />
                </div>
            </div>
        </section>
    )
}

export default AdjunctLmsSteps;