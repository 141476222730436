import React from "react";
import Button from "../../../global/input/Button";
import Avatar from "./Avatar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setNavigation } from "../../../../redux/slices/navigationSlice";

const AvatarDisplayCard = ({ setShowAccount }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();

  // console.log("USERData:", userData);
  const handleClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const path = "/account/settings";

    // console.log("RefreshPage:", refreshPage);
    // console.log("NewPath:", newPath);

    const response = await dispatch(setNavigation({ path }));
    console.log("Response:", response.payload.path);
    navigate(`${process.env.PUBLIC_URL}${response.payload.path}`);

    // Close Modal
    setShowAccount(false);
  };
  return (
    <div className="modal-content modal-content-demo shadow">
      <form>
        <div className="modal-body">
          <div className="modal-body-content">
            <main>
              <Avatar />
              <div>{`${userData.firstName} ${userData.lastName}`}</div>
              <div className="row">
                <div className="col">
                  <button
                    type="button"
                    // displayType="segment"
                    // label="Account Settings"
                    // variant="attention"
                    // size="medium"
                    className="show-account-btn"
                    // setShowModal={()=>setShowAccount(false)}
                    // newContent="AccountSettingsContent"
                    // newPage="Account"
                    // newPath="/account/settings"
                    onClick={handleClick}
                  >
                    Account Settings
                  </button>
                </div>
              </div>
            </main>
            {/* <div className="form-container"></div> */}
            {/* </div> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AvatarDisplayCard;
