import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../redux/slices/userSlice";
import { useNavigate } from "react-router";
import Button from "../../../global/input/Button";
import TitleCard from "../../dashboard/TitleCard";
import CourseCard from "../../dashboard/CourseCard";

const TitleSelectContent = () => {
  const pearsonData = useSelector((state) => state.pearson);
  const titleData = pearsonData.titles;

  const courseData = pearsonData.courses;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  console.log("USERData:", userData);

  return (
    <div className="lms-title-select page-layout">
      <form
        className="title-select-content"
        //   onSubmit={handleSubmit(handleSubmitForm)}
      >
        <div className="header">
          <h3 className="title">Select your Title</h3>
          {/* HEADER */}
        </div>

        <div className="row">
          <section className="text">
            <p className="section1">
              This course is set to use the following title and assignment
              template
            </p>
          </section>
        </div>
        <div className="row">
          <section className="card-group">
            <div className="search-results">
              {titleData.map((title, index) => {
                if (index === 0) {
                  return <TitleCard title={title} flow="lms" />;
                }
              })}
            </div>
            <div className="dashboard-previous-courses">
              {courseData.map((course, index) => {
                // console.log("CourseDisplay:", course)
                if (index === 0) {
                  return (
                    <CourseCard course={course} type="search" flow="lms" />
                  );
                }
              })}
            </div>
          </section>
        </div>

        {/* <div className="image-container">
          {userData?.schoolImage ? (
            <img width="358px" src={userData.schoolImage} alt={"school"} />
          ) : (
            <></>
          )}
        </div> */}
        <div className="row">
          <section className="text">
            <p className="section2">
              To get your title ready we’ll need to pair your Canvas account
              with a Pearson account so that you can have seemless access to the
              course materials now and in the future.
            </p>
          </section>
        </div>
        {/* <div className="row">
          <section>
            <p>Validation complete!</p>
            <p>
              {`Great, you have been verified as an instructor at ${userData?.schoolName}. You can now review and set up Pearson titles and access
            ${userData?.schoolName} specific features.`}
            </p>
            <p>
              If you have any questions, help is just one click away using the
              Get Help tab!
            </p>
          </section>
        </div> */}

        {/* INPUT GROUP */}

        <footer>
          <Button
            label="Next"
            variant="attention"
            size="medium"
            className="adopt-btn"
            //   clickHandler={handleSubmitForm}
            newContent="SigninForm"
            newPage="Start"
            newPath="/lms/signin"
          />
        </footer>
      </form>
    </div>
  );
};

export default TitleSelectContent;
