import React, { useEffect, useState } from "react";
import BookInfo from "./BookInfo";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/slices/userSlice";
import Button from "../../global/input/Button";

const AdjunctCreateCourseInfo = ({
  creatingCourseAnimation,
  setCurrentStep,
}) => {
  const dispatch = useDispatch();
  const nonLmsAndLmsConfirmSetupText =
    "Almost done, let's check and make sure everything looks good.";
  const userData = useSelector((state) => state.user);
  let displayMessage = "Instructor use only";
  let priceMessage = "";

  
  //Fotmat date to mm/dd/yyyy
  const getFormattedDate = (dateStr) => {
    var date = new Date(dateStr);
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = (1 + date.getDate()).toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
  };

  const handleCreateAnimations = (event) => {
    event.preventDefault();
    event.stopPropagation();

    creatingCourseAnimation(event);
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 80);

    if (userData.userType == "instructor") {
      dispatch(setUser({ packagePrice: "" }));
    }
  }, []);

  return (
    <section className="confirm-create-course">
      
        <p className="step-priompt">{nonLmsAndLmsConfirmSetupText}</p>

        <h3>Course Information</h3>
        <div className="summary">
            <h4>{userData.courseName}</h4>

            <ul>
                <li>Start date: {getFormattedDate(userData.courseStart)}</li>
                <li>End date: {getFormattedDate(userData.courseEnd)}</li>
                <li>
                    Students can enroll after:{" "}
                    {getFormattedDate(userData.enrollStart)}
                </li>
                <li>Course timezone: {userData.courseTimezone}</li>
            </ul>

            <Button
              label="Edit"
              variant="secondary"
              size="medium"
              newContent="CreateCourseContent2"
              newPage="CourseSetup"
              newPath="/setup/course"
              refreshPage={true}
              setCurrentStep={setCurrentStep(1)}
            />
        </div>
          
        {/********TITLE INFO*********/}
        <div className="summary">
            <h3>Title information</h3>
            <BookInfo type={"large"} />
        </div>
        <div className="summary">
            <h4>
                {userData.packageType === "eText bundle" ? "Mastering / " + userData.packageType : userData.packageType}
            </h4>
        </div>

        {/* Add to Student Fees (Inclusive Access) */}
        <div className="summary">
            <h4>{displayMessage}</h4>
            <p>{priceMessage}</p>
        </div>

        <div className="step-action">
        <button
          type="button"
          className="primary"
          onClick={handleCreateAnimations}
        >Create</button>
      </div>
    </section>
  );
};

export default AdjunctCreateCourseInfo;
