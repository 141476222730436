import { createSlice } from "@reduxjs/toolkit";
import {
  // getNavigationReducer,
  setNavigationReducer,
} from "../reducers/navigationReducer";

// import navbarData from "../data/navbarData";
import navigationData from "../data/navigationData";

/********************************
 * Configure Slice with reducers
 * and Export actions and reducer
 *******************************/
const navigationSlice = createSlice({
  name: "navigation",
  initialState: navigationData,
  reducers: {
    // getNavigation: getNavigationReducer,
    setNavigation: setNavigationReducer,
  },
});

// Note: navigationSlice.actions automatically creates actions based on our reducer names
export const { 
  // getNavigation, 
  setNavigation, 
} = navigationSlice.actions;

// export  reducer, so it can be added to the store
export default navigationSlice.reducer;