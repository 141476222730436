import React from 'react';
import displaySvgIcon from '../../../../../utilities/displaySvgIcon';

const Assignment = ({month, day, title}) => {
    return (
        <div className="assignment">
            <div className="calendar">
                <h3>{month}</h3>
                <h3>{day}</h3>
            </div>
            <div className="assignment-group">
                <h3>{title}</h3>
                <ol>
                    <li>Homework</li>
                    <li>45 min</li>
                    <li>10 pts</li>
                </ol>
            </div>
            <div>{displaySvgIcon("handles")}</div>
        </div>
    );
};

export default Assignment;