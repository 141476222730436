import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../input/Button";
import SchoolDisplayCard from "./SchoolDisplayCard";
import { setDefault } from "../../../../redux/slices/collegeSlice";
import { setUser } from "../../../../redux/slices/userSlice";
import { setNavigation } from "../../../../redux/slices/navigationSlice";
import { useNavigate } from "react-router";

const SchoolDisplayList = ({ displayToggle, setDisplayToggle, modalClass}) => {
  const navigate = useNavigate();

  const collegeOptions = useSelector((state) => state.college.schools);
  const dispatch = useDispatch();

  const activeCollege = collegeOptions.filter(
    (college) => college.default === true
  );

  const inactiveColleges = collegeOptions.filter(
    (college) => college.default === false
  );

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDisplayToggle(!displayToggle);
  };

  const handleDivClick = async (event, college) => {
    event.preventDefault();
    event.stopPropagation();

     // const newContent = "AccountSettingsContent";
     const page = "Dashboard";
     const path = "/dashboard";

    // const dataId = event.currentTarget.dataset.id;
    const newUserData = {};
    const collegeValue = college.value;
    newUserData.schoolName = college.label;
    newUserData.schoolImage = college.imageName;

    console.log("Clicked div with data-id:", collegeValue);
    console.log("College value:", college);

    // Dispatch the setDefaultCollege action
    if (collegeValue) {
      // const defaultCollegeData = await dispatch(setDefault({ collegeValue: collegeValue, college: college }));
      const defaultCollegeData = await dispatch(setDefault({ collegeValue: collegeValue}));
       console.log("DefaultCollege:", defaultCollegeData)
      // Dispatch the updateDefaultCollege action with the extracted default college data
    // await dispatch(setUser(defaultCollegeData));
      await dispatch(setUser(newUserData));
    }

    const navResponse = await dispatch(setNavigation({ page, path }));
    navigate(`${process.env.PUBLIC_URL}${navResponse.payload.path}`);

  };

  useEffect(() => {
    // console.log("displayToggle:", displayToggle);
  }, [displayToggle]);

  // console.log("CollegeOptions:", collegeOptions, "default:", activeCollege);
  return (
    <>
      <div className="expand-list-ctr">
        <div className="school-num">{`My School (${collegeOptions.length})`}</div>
        <button type="button" className="expand-btn" onClick={handleClick}>
          {displayToggle ? "collapse list" : "expand list"}
        </button>
      </div>

      {activeCollege.map((college) => (
        <div
          data-school={college.label}
          data-id={college.value}
          className="school active"
          onClick={(event) => handleDivClick(event, college)}
        >
          <SchoolDisplayCard school={college} active={true} />
        </div>
      ))}

      {inactiveColleges.map((college) => (
        <div
          data-school={college.label}
          data-id={college.value}
          className={`school inactive ${displayToggle ? "expand" : "collapse"} ${modalClass}`}
          onClick={(event) => handleDivClick(event, college)}
        >
          <SchoolDisplayCard school={college} active={false} />
        </div>
      ))}
    </>
  );
};

export default SchoolDisplayList;
