import React from 'react';
import EditButton from '../../../../global/input/EditButton';

const UserInfoCard = ({label, content, id}) => {
    return (
        <div className="user-info" key={id}>
            <span className="info-label">{label}</span>
            <span className="info-content">{content}</span>
            <EditButton label="Edit"/>
        </div>
    );
};

export default UserInfoCard;