const toTitleCase = (str) => {
  if (str) {
    return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  } else {
    return "";
  }
};
export default toTitleCase;
