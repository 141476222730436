import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ScrollToTopOnMount() {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [pathname, hash, navigate]);

  return null;
}

export default ScrollToTopOnMount;
