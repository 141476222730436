/** @format */

// import nashvilleCollege from "../../assets/images/college-nashville.png";
import universityCollege from '../../assets/images/college-university.png';
import lmsUniversityCollege from '../../assets/images/lms-college-link-account-logo.png';
// import ivyCollege from "../../assets/images/college-ivy.png";
/** @format */

const userData = {
	value: '0',
	firstName: 'Mary',
	lmsFirstName: 'Mary',
	lmsLastName: 'Ericson',
	lmsUserType: 'Instructor',
	lmsSchoolName: 'University College',
	lmsSchoolImage: lmsUniversityCollege,
	lmsCourseName: 'Biology and Life Sciences',
	lastName: 'Ericson',
	email: 'mary.ericson@univ.edu',
	password: 'password',
	signedIn: false,
	schoolName: 'University College',
	schoolImage: universityCollege,
	schoolDepartment: 'Biology',
	schoolDisciplines: [],
	product: '',
	courseName: 'Biology 101 Section 8',
	courseAccess: 'inclusive',
	courseType: 'instructor',
	courseTimezone: 'America/Denver',
	courseStart: '2024-08-15',
	courseEnd: '2024-12-14',
	enrollStart: '2024-08-04',
	enrollEnd: '2024-08-22',
	termAgreement: false,
	userType: 'Instructor',
	bookName: 'Campbell Biology',
	bookImagePath: universityCollege,
	bookSelected: false,
	packageType: 'eText bundle',
	packagePrice: 'Student Price: $89.00 18wk access',
	lmsPackageType: 'eText bundle',
	lmsPackagePrice: 'Student Price: $89.00 18wk access',
	lmsPriceMessage: 'Add to Student Fees',
	phone: '303-555-5555',
	lmsConnect: false,
	isAdjunct: false,
};

export default userData;
