//images
import gearIcon from "../../../assets/images/layouts/dashboard/settings-24.svg";
import image from "../../../assets/images/layouts/dashboard/image-placeholder.svg";
import video from "../../../assets/images/layouts/dashboard/video-placeholder.svg";
import add from "../../../assets/images/layouts/dashboard/add.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import universityBook from "../../../assets/images/biology-flower.png";
import nashvilleBook from "../../../assets/images/biological-science.png";
import ivyLeagueBook from "../../../assets/images/bridging-english.png";
import placeholderBook from '../../../assets/images/placeholder_img.png';
import userData from "../../../redux/data/userData";
import Button from "../../global/input/Button";

const AdjunctCourseCard = ({ course, type, flow, ...props }) => {
  const [lmsReady, setLmsReady] = useState(false);
  const navigate = useNavigate();

  const getCTAText = () => {
    switch (type) {
      case "search":
        return "Use this title";
        break;
      case "current":
        return "Launch";
        break;
      default:
        return "Launch";
    }
  };

  const clickHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    navigate(`${process.env.PUBLIC_URL}/setup/adjunct/add_course`);
  };

  useEffect(() => {
    console.log("CourseName:", course);
  }, [course]);

  useEffect(() => {
    setTimeout(() => {
      setLmsReady(true)
    }, 3000);
  }, [])
  return (
    <div className={`dashboard-course-card card-type-${type}`}>
        <div className="course-card-header">
            <h3>NEW! Biology 101 Section 9</h3>
            <button className="gear disabled" disabled>
                <img src={gearIcon} />
            </button>
        </div>

        <h4>This course is set to use:</h4>
        <div style={{'display': 'flex', "gap": "20px"}}>
            <div className="course-info">
                <div className="course-card-cover small">
                    <img alt="Course Book" src={placeholderBook} />
                </div>

                <div className="course-details">
                    <h5>Campbell Biology w/ etext bundle</h5>
                    <p>12th Edition</p>
                    <p>Lisa A. Urry, Michael L. Cain...</p>
                    <p>Mastery Biology</p>

                    <h5>Template</h5>
                    <p>Biology 101 Fall 2024 Template</p>
                    <p>Created by John Smith</p>
                </div>
            </div>

            <div className="course-card-actions">
                <Button
                    label="Add This Title to My Course"
                    variant="primary"
                    size="medium"
                    onClick={clickHandler}
                />
                <a href="javascript:void(0)">This is not the right title</a>
            </div>
        </div>
    </div>
  );
};

export default AdjunctCourseCard;
