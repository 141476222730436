import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConnectingToLMS from '../courseSetup/ConnectingToLMS';
import logoCanvas from '../../../assets/images/logo_canvas.png';
import {setUser } from "../../../redux/slices/userSlice";


const LMSConnect = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const [connectingLMS, setConnectingLMS] = useState(false);
  const [courseMatchFound, setCourseMatchFound] = useState(false);
  const [courseSelected, setCourseSelected] = useState(false);
  const connectLMSSteps = useRef(null);

  const collegeOptions = useSelector((state) => state.college);

  const navigate = useNavigate();

  /* Connect to LMS For Instructor Prototype, not LMS anf retrieve matching courses*/
  const getLmsCourseMatch = () => {
    setConnectingLMS(true);
    setTimeout(() => {
      connectLMSSteps.current.childNodes.forEach((element) => {
        element.classList.add("fade-in");
      });
    }, 500); // updated 500 to 5000

    setTimeout(() => {
      setConnectingLMS(false);
      setCourseMatchFound(true);
      //navigate(process.env.PUBLIC_URL + "/setup/complete");
    }, 5000); // updated 2500 to 5000
  };

  const selectCourse = () => {
    setCourseSelected(true);
  }

  /* Connect to LMS For Instructor Prototype, not LMS anf retrieve matching courses*/
  const connectToLms = () => {
    setConnectingLMS(true);
    setTimeout(() => {
      connectLMSSteps.current.childNodes.forEach((element) => {
        element.classList.add("fade-in");
      });
    }, 500); // updated 500 to 5000

    setTimeout(() => {
      setConnectingLMS(false);
      setCourseMatchFound(false);
      updateUserData()
    }, 5000); // updated 2500 to 5000
  };
  const updateUserData = async (event) => {
    await dispatch(setUser({ 
      lmsConnect: true,  }));
      navigate(process.env.PUBLIC_URL + "/lmsconnect/complete");
}

  return (
    <div className="page-layout">
        <h1>Connect to LMS</h1>
        <h2>Select LMS Course</h2>

        <p className="page-prompt">Our records show {userData.schoolName} has a LMS partnership with Canvas by Instructure. To begin, click next and we'll get your courses.</p>   

        <div className="lms-logo">
            <img src={logoCanvas} alt="" />   
        </div>

        {courseMatchFound ?
        <div className="matched-courses">
            <p>We found a coursematch:</p>
            <div className={`pill ${courseSelected ? 'selected' : ''}`} role="button" onClick={selectCourse}>
                <p className="small">Bio Section 08</p>
                <h4>BIO101 - General Biology</h4>
            </div>

            <p>Other available courses</p>
            <ul>
                <li>
                    <div className="pill" role="button">
                        <p className="small">Bio Section 06</p>
                        <h4>bIO101 - General Biology</h4>
                    </div>
                </li>
                <li>
                    <div className="pill" role="button">
                        <p className="small">Bio Section 07</p>
                        <h4>BIO101 - General Biology</h4>
                    </div>
                </li>
                <li>
                    <div className="pill" role="button">
                        <p className="small">Apbio Section 01</p>
                        <h4>BIO204 - Advanced Biology</h4>
                    </div>
                </li>
                <li>
                    <div className="pill" role="button">
                        <p className="small">APBio Section 02</p>
                        <h4>BIO204 - Advanced Biology</h4>
                    </div>
                </li>
                <li>
                    <div className="pill" role="button">
                        <p className="small">APBio Section 03</p>
                        <h4>BIO204 - Advanced Biology</h4>
                    </div>
                </li>
                <li>
                    <div className="pill" role="button">
                        <p className="small">APBio Section 04</p>
                        <h4>BIO204 - Advanced Biology</h4>
                    </div>
                </li>
            </ul>
        </div>
    : <></> }
    

        <div className="step-actions">
            <button className=" secondary" onClick={() => navigate(-1)}>Cancel</button>
            {courseMatchFound ? <button className="primary" disabled={courseSelected === true ? false : true} onClick={connectToLms}>Connect to LMS</button>
            : 
            <button className="primary" onClick={getLmsCourseMatch}>Next</button> }
        </div>
        {connectingLMS && <ConnectingToLMS ref={connectLMSSteps} courseSelected={courseSelected} />}
    </div>
  );
};

export default LMSConnect;
