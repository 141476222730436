import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import EditButton from "../../global/input/EditButton";
import UserInfoCard from "./AccountSettings/partials/UserInfoCard";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../global/input/Button";
import moment, { Moment } from "moment";
import toTitleCase from "../../../utilities/toTitleCase";
import SchoolEditList from "./AccountSettings/partials/SchoolEditList";
import AddSchoolProfile from "./AccountSettings/partials/AddSchoolProfile";
// import SignOutFooter from "../../global/modals/partials/SignOutFooter";

const AccountSettingsContent = () => {
  const userData = useSelector((state) => state.user);
  // console.log("USER DATA:", userData);

  const [displayToggle, setDisplayToggle] = useState(false);

  const collegeData = useSelector((state) => state.college.schools);
  // console.log("COLLEGE DATA:", collegeData);
  //   const newData =
  //     {
  //       name: userData.firstName + " " + userData.lastName,
  //     //   role: userData.role,
  //       phone: userData.phone,
  //     }

  //   const mergedData = collegeData.map((school) => ({
  //     ...school,
  //     ...newData,
  //   }));
  //   console.log("MERGED DATA:", mergedData);

  const formattedUserData = [
    {
      label: "Name",
      content: userData.firstName + " " + userData.lastName,
      Button: EditButton,
    },
    {
      label: "Username",
      content: userData.email,
      Button: EditButton,
    },
    {
      label: "Password",
      content: "Last changed " + moment().format("ll"),
      Button: EditButton,
    },
    {
      label: "Phone / Text",
      content: userData.phone,
      Button: EditButton,
    },
    {
      label: "Main Role",
      content: toTitleCase(userData.userType),
      Button: EditButton,
    },
  ];

  const formattedComData = [
    {
      label: "Notifications",
      content: "off",
      Button: EditButton,
    },
    {
      label: "Emails",
      content: "yes alerts, no sales",
      Button: EditButton,
    },
    {
      label: "Digest",
      content: "1 per day",
      Button: EditButton,
    },
  ]

  // console.log("FORMATTED DATA:", formattedUserData);
  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
    setValue,
  } = useForm({
    // defaultValues: {
    //   schoolName: () => getCollege(),
    // },
  });

  const handleSubmitForm = async (data, event) => {
    event.preventDefault();
    event.stopPropagation();
    // console.log("SUBMIT DATA2:", data);
  };

  return (
    <form
      className="create-account-content account-settings-content"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <div className="header">
        <h3 className="title">Account settings</h3>
      </div>

      <div className="avatar">
        <EditButton label="Edit" />
      </div>

      <div className="subheader">
        {" "}
        <div>My Information</div>
      </div>

      <div className="info-container">
        {formattedUserData.map((data) => (
          <UserInfoCard
            label={data.label}
            content={data.content}
            id={data.value}
          />
        ))}
      </div>

      <div className="subheader">
        {" "}
        <div>{`My Schools  (${collegeData.length})`}</div>
      </div>

      <div className="school-container">
        <SchoolEditList
          displayToggle={displayToggle}
          setDisplayToggle={setDisplayToggle}
        />

        <AddSchoolProfile
          label="+ Add New School Profile"
          setShowAccount={() => {
            // console.log("TEXT");
          }}
          setDisplayToggle={setDisplayToggle}
        />
      </div>

      <div className="subheader">
        {" "}
        <div>Communication</div>
      </div>

      <div className="info-container">
        {formattedComData.map((data) => (
          <UserInfoCard
            label={data.label}
            content={data.content}
            id={data.value}
          />
        ))}
      </div>
      <div className="subheader">
        {" "}
        <div>Subscriptions</div>
      </div>
      <Button
            label="Back to dashboard"
            className="adopt-btn"
            newContent="GreetContent"
            newPage="Dashboard"
            newPath="/dashboard"
          />
      {/* <div className="school-container">

      </div> */}
    </form>
  );
};

export default AccountSettingsContent;
