import { forwardRef } from "react";

const LmsCreatingCourse = forwardRef((props, ref) => {
  return (
    <div className="modal setup">
      <h2>Creating Course</h2>

      <ul ref={ref}>
        <li>Creating Pearson course template...</li>
        <li>Copying available assignments...</li>
        <li>Checking customization settings...</li>
      </ul>
    </div>
  );
});

export default LmsCreatingCourse;
