import { forwardRef } from 'react';

const CourseModal = (props) => {
    return(
        <div className="modal ">
            <h2>Welcome to your course</h2>

            <p style={{'marginBottom': '16px', 'lineHeight': '1.6'}}>Since you are using a template created by your school, all that's left is to set due dates.</p>
            <p style={{'color': '#8CA2C0', 'lineHeight': '1.6', 'marginBottom': '90px'}}>The Mastering assignment wizard will guide you through setting your due dates</p>

            <div style={{'display': 'flex', 'gap': '20px'}}>
                <button className='secondary'>I'll do it later</button>
                <button className='primary'>Go to Assignment Wizard</button>
            </div>
        </div>
    )
}

export default CourseModal