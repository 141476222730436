import React from "react";
import displaySvgIcon from "../../../utilities/displaySvgIcon";

const Checkbox = ({
  register,
  className,
  id,
  testId,
  name,
  label,
  value,
  changeHandler,
  ref,
  hiddenClass,
  labelClass,
  type,
  required,
  displayQuestion,
  formLabel
}) => {
  // console.log("NAME:", name, "Register:", register);
  return (
    <>
      {/* <br /> */}
      <div className={`${type}`}>
        <div className={`col-auto checkbox-group checkbox ${labelClass} ${hiddenClass ? hiddenClass : ""} `}>
          <input
            ref={ref}
            aria-labelledby={id}
            type="checkbox" // Changed to checkbox type
            {...register(name)}
            className={`${className} ${hiddenClass ? hiddenClass : ""}`}
            id={id}
            data-testid={testId}
            // placeholder={placeholder}
            name={name}
            defaultChecked={value} // Using defaultChecked to set the initial checked state
            required={required ? required : false}
          />
          <label htmlFor={id} className={formLabel}>{label}{displayQuestion ? <span className="label-icon">{displaySvgIcon("question-mark")}</span> : <></>}</label>
        </div>
      </div>
    </>
  );
};

export default Checkbox;
