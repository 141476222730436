import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/slices/userSlice";
import Tabs from "../../global/Tabs";
import TitleCard from "./TitleCard";
import CourseCard from "./CourseCard";
import Checkbox from "../../global/input/Checkbox";
import { useForm } from "react-hook-form";
import StaticSelect from "../../global/input/StaticSelect";
import dropdownIndicator from "../../../utilities/dropdownIndicator";

//images
import add from "../../../assets/images/layouts/dashboard/add-24.svg";
import arrow from "../../../assets/images/layouts/dashboard/arrow.svg";
import close from "../../../assets/images/icons/close-16.svg";
import displaySvgIcon from "../../../utilities/displaySvgIcon";

const tabItems = [
  {
    label: "Department Picks",
    id: "department",
  },
  {
    label: "Previous Courses",
    id: "previous",
  },
  {
    label: "Search the Catalog",
    id: "search",
  },
];

const FindTitleContent = () => {
  const dispatch = useDispatch();
  const pearsonData = useSelector((state) => state.pearson);
  const titleData = pearsonData.titles;
  const courseData = pearsonData.courses;
  // console.log("CourseData:", courseData)
  const userData = useSelector((state) => state.user);
  // console.log("UserData:", userData)

  const [activeTab, setActiveTab] = useState("department");
  const [department, setDepartment] = useState(null);
  const collegeOptions = useSelector((state) => state.college);
  const departmentOptions = collegeOptions.departments;
  const handleSetTab = (tab) => {
    setActiveTab(tab);
  };

  const getDepartmentDefault = () => {
    const label = userData.schoolDepartment;
    const selectedDepartmentOption = departmentOptions.filter((department) => {
      if (label) {
        return department.label === label;
      } else return {

      };
    });
    return selectedDepartmentOption;
  };

  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
    setValue
  } = useForm({
    defaultValues: {
      department: () => getDepartmentDefault(),
    },
  });

  const departmentSelectHandler = async (data, event) => {
    console.log("College1:", data);
    setDepartment(data.label);
    if (data?.label) {
      await dispatch(setUser({ schoolDepartment: data.label }));
      
    }
  };

  useEffect(()=> {
    const defaultValues = getDepartmentDefault();
      setValue("department", defaultValues)
  }, [userData.schoolDepartment]);

  return (
    <div className="page-layout">
      <h1>Find your Pearson title</h1>
      <div className="dashboard-find-presets">
        <div>
          <div className="label-group">
            <label>Department</label>
            <span className="label-icon">
              {displaySvgIcon("question-mark")}
            </span>
          </div>
          {/* <div className="select-container"> */}
          <StaticSelect
            labelClass="label-element"
            isMulti={false}
            //   placeholder="Start typing the department"
            name="department"
            label=""
            options={departmentOptions}
            control={control}
            DropdownIndicator={() =>
              dropdownIndicator(displaySvgIcon("dropdown"))
            }
            card
            selectHandler={departmentSelectHandler}
            required={false}
            value={{ label: "Biology", value: 3 }}
          />
          {/* <img src={arrow} /> */}
          {/* </div> */}
        </div>
        <div>
          <div className="label-group">
            <label>
              Discipline
              <span className="label-icon">
                {displaySvgIcon("question-mark")}
              </span>
            </label>
          </div>
          <div className="tags">
            <button className="add">
              <img src={add} />
            </button>
            {userData.schoolDisciplines.length > 0 ? (
              <div className="title-chip-container">
                {userData.schoolDisciplines.slice(0, 4).map((disc, index) => {
                  return (
                    <div className="tag">
                      <button>
                        <img src={close} />
                      </button>
                      <span>{disc}</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="tag">
                <button>
                  <img src={close} />
                </button>
                <span>Ecology</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <Tabs items={tabItems} activeTab={activeTab} handleClick={handleSetTab} />
      {tabItems.map((item, index) => {
        return (
          <div
            key={index}
            role="tabpanel"
            aria-labelledby={`${item.id}-tab`}
            id={`${item.id}-tab-panel`}
            tabIndex={`${item.defaultActive ? "0" : "-1"}`}
            className={`tabpanel ${activeTab != item.id ? "is-hidden" : ""}`}
          >
            {item.id === "department" ? (
              <div className="dashboard-dept-picks">
                {titleData.map((title, index) => {
                  return <TitleCard title={title} />;
                })}
              </div>
            ) : null}

            {item.id === "previous" ? (
              <div className="dashboard-previous-courses">
                {courseData.map((course, index) => {
                  // console.log("CourseDisplay:", course)
                  return <CourseCard course={course} type="search" />;
                })}
              </div>
            ) : null}

            {item.id === "search" ? (
              <div className="dashboard-search-courses">
                <input type="search" placeholder="Search" />
                <div className="search-body">
                  <div className="search-filter">
                    <h2>Filter</h2>
                    {/* update map to real filter data */}
                    <div>
                      {[1, 2, 3, 4].map((filter, index) => {
                        return (
                          <div className="filter-container">
                            <Checkbox
                              displayType="row"
                              register={register}
                              className={"form-input"}
                              id="formGrouptermAgreement"
                              testId="term-agreement"
                              label="Lorem ipsum dolor"
                              labelClass="label-element"
                              name="filter"
                              required={true}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="search-results">
                    {titleData.map((title, index) => {
                      return <TitleCard title={title} />;
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default FindTitleContent;
