import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../global/input/Button";
import { useNavigate } from "react-router";
import { setNavigation } from "../../../redux/slices/navigationSlice";

const TitleDetailsContent = () => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user);
  const selectedBook = useSelector((state) => state.user).bookName;
  const bookData = useSelector((state) => state.pearson).titles.filter(
    (a) => a.label === selectedBook
  )[0];
  const navigate = useNavigate();

  const clickHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("SignedIn:", userData.signedIn);
    // const newContent="SigninFormContent"
    // const newPage="Start"
    let newPath = "";
    // if signed it go to course setup after selecting title
    if (userData.signedIn) {
      newPath = "/setup/adoption_type";
    } else {
      newPath = "/account/signin";
    }
    const path = newPath;
    const response = await dispatch(setNavigation({ path }));
    console.log("Response:", response.payload.path);
    navigate(`${process.env.PUBLIC_URL}${response.payload.path}`);
  };
  return (
    <div>
      <div className="title-details-hero">
        <div className="sc-5a5a7f17-0 kOWOjM dots-lrDown-textureDark sc-d5f43c04-2 dPkxvt"></div>
        <div className="title-details-cover">
          <img src={bookData.imagePath} />
          <div className="title-details-links">
            <a
              data-purpose="request-copy"
              className="sc-402984b7-3 kvMbML request-copy-cta"
              href="#"
            >
              Request full copy
            </a>
            <p>|</p>
            <a
              data-purpose="download-sample"
              className="sc-402984b7-3 kvMbML download-sample-cta"
              href="#"
            >
              Download resources
            </a>
          </div>
        </div>
        <div className="title-details-info">
          <h1>
            {bookData.label}, {bookData.edition} edition
          </h1>
          <p className="published-by">
            Published by Pearson (January 17, 2020) © 2021
          </p>
          <ul>
            <li>
              <b>Lisa A. Urry</b> Mills College, Oakland, California
              <span style={{ 'color': '#fff', "fontWeight": 'bold'}}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <b>Michael L. Cain</b> New Mexico State University, Las Cruces,
              New Mexico
            </li>
            <li>
              <b>Steven A. Wasserman</b> University of California, San Diego
              <span style={{ 'color': '#fff', "fontWeight": 'bold'}}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <b>Peter V. Minorsky</b> Mercy College, Dobbs Ferry, New York
            </li>
            <li>
              <b>Rebecca Orr</b> Collin College, McKinney, Texas
            </li>
          </ul>
          <Button
            label="Adopt this title"
            variant="primary"
            size="medium"
            clickHandler={clickHandler}
          />
          <Button
            label="Preview Title"
            variant="secondary"
            size="medium"
            style={{'marginLeft': '1.5rem', 'background': 'white'}}
          />
        </div>
      </div>
      <div className="title-details-tabs">
        <div
          role="tablist"
          id="product-info-tab-list"
          className="tablist Tabs_tab-list___vqf3 Tabs_horizontal__7zbBV"
        >
          <button
            id="title-overview"
            aria-controls="title-overview-tab"
            aria-selected="true"
            tabIndex="0"
            role="tab"
            className="active Tabs_tab__T3OFh Tabs_horizontal__7zbBV Tabs_active__jfNcr"
          >
            <div className="sc-78e236ed-2 bYMfpD">Title overview</div>
          </button>
          <button
            id="digital-features"
            aria-controls="digital-features-tab"
            aria-selected="false"
            tabIndex="-1"
            role="tab"
            className="Tabs_tab__T3OFh Tabs_horizontal__7zbBV"
          >
            <div className="sc-78e236ed-2 bYMfpD">Digital features</div>
          </button>
          <button
            id="table-of-contents"
            aria-controls="table-of-contents-tab"
            aria-selected="false"
            tabIndex="-1"
            role="tab"
            className="Tabs_tab__T3OFh Tabs_horizontal__7zbBV"
          >
            <div className="sc-78e236ed-2 bYMfpD">Table of contents</div>
          </button>
          <button
            id="instructor-resources"
            aria-controls="instructor-resources-tab"
            aria-selected="false"
            tabIndex="-1"
            role="tab"
            className="Tabs_tab__T3OFh Tabs_horizontal__7zbBV"
          >
            <div className="sc-78e236ed-2 bYMfpD">Instructor resources</div>
          </button>
          <button
            id="author-bios"
            aria-controls="author-bios-tab"
            aria-selected="false"
            tabIndex="-1"
            role="tab"
            className="Tabs_tab__T3OFh Tabs_horizontal__7zbBV"
          >
            <div className="sc-78e236ed-2 bYMfpD">Author bios</div>
          </button>
        </div>
        <div
          role="tabpanel"
          aria-labelledby="title-overview"
          tabIndex="0"
          id="title-overview-tab"
          className="tab-content Tabs_tab-content__KOB_3 Tabs_horizontal__7zbBV Tabs_active__jfNcr"
        >
          <div className="sc-d2542e1-0 enZECL">
            <div className="Grid_grid__k2cvh">
              <div className="sc-e164736c-0 leSDTE sc-d2542e1-1 ecevFT">
                <div
                  id="ce4a77ef-d00e-4b66-b323-4196d8dbd852-hiddenBlock"
                  className="sc-f263d653-0 eSkdxn"
                >
                  <div>
                    <div className="Grid_grid__k2cvh">
                      <div className="sc-e164736c-0 leSDTE sc-d2542e1-3 hAgHKv">
                        <div>
                          <p className="p">For courses in general biology.</p>
                          <h3 className="h3">
                            The standard for excellence, accuracy and innovation
                          </h3>
                          <p className="p">
                            <cite>
                              <strong>Campbell Biology</strong>
                            </cite>{" "}
                            delivers a trusted, current and pedagogically
                            innovative experience that guides students to a true
                            understanding of biology. The author team advances
                            Neil Campbell's vision of equipping students at
                            their individual skill levels with tools, visuals,
                            resources and activities that engage them in their
                            learning. This course solution integrates text and
                            artwork to reinforce key concepts, build scientific
                            skills and promote active learning.
                          </p>
                          <p className="p">
                            The <strong>12th Edition</strong> meets student
                            needs with new student-centered features, Reading
                            Guide worksheets emphasizing key concepts and a
                            revised assessment program.
                          </p>
                          <p className="p" aria-hidden="true">
                            <a
                              href="https://www.pearson.com/us/campaigns/dr/campbell-biology-12e.html"
                              aria-hidden="true"
                              tabIndex="-1"
                            >
                              Learn more
                            </a>
                            .
                          </p>
                        </div>
                        <div>
                          <h3 className="h3" aria-hidden="true">
                            Hallmark features of this title
                          </h3>
                          <h4 className="h4" aria-hidden="true">
                            Visual connections
                          </h4>
                          <ul>
                            <li aria-hidden="true">
                              <strong aria-hidden="true">
                                Make Connections Figures
                              </strong>{" "}
                              connect content from different chapters providing
                              a visual representation of big picture
                              relationships.
                            </li>
                            <li aria-hidden="true">
                              <strong aria-hidden="true">
                                Make Connections Questions
                              </strong>{" "}
                              relate chapter content to material presented
                              earlier to show the connections between areas of
                              biology.
                            </li>
                          </ul>
                          <h4 className="h4" aria-hidden="true">
                            A conceptual framework
                          </h4>
                          <ul>
                            <li aria-hidden="true">
                              <strong aria-hidden="true">
                                End-of-chapter Summary of Key Concepts Questions
                              </strong>{" "}
                              reinforce main ideas in each chapter.
                            </li>
                            <li aria-hidden="true">
                              <strong aria-hidden="true">
                                Test Your Understanding Questions
                              </strong>{" "}
                              prepare students for exam-type questions and
                              encourage higher-level thinking.
                            </li>
                          </ul>
                          <h4 className="h4" aria-hidden="true">
                            Key scientific skills
                          </h4>
                          <ul>
                            <li aria-hidden="true">
                              <strong aria-hidden="true">
                                Scientific Skills Exercises
                              </strong>{" "}
                              use real data to help build data analysis,
                              graphing, experimental design and math skills
                              <strong aria-hidden="true">.</strong>
                            </li>
                            <li aria-hidden="true">
                              <strong aria-hidden="true">
                                Problem-Solving Exercises{" "}
                              </strong>
                              guide students to apply scientific skills and
                              interpret real data in the context of a real-world
                              problem.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sc-e164736c-0 leSDTE sc-d2542e1-3 hAgHKv">
                        <div>
                          <h3 className="h3">
                            New and updated features of this title
                          </h3>
                          <h4 className="h4">Visual connections</h4>
                          <ul>
                            <li>
                              <strong>Interactive Visual Activities </strong>
                              give students an engaging way to interact with the
                              text's powerful art program while deepening their
                              understanding of biology.
                            </li>
                            <li>
                              <strong>Additional Figure Walkthroughs</strong>{" "}
                              (1-2-page visual spreads) guide students to a
                              deeper understanding of the ways figures represent
                              biological structures, processes and functions.
                            </li>
                          </ul>
                          <h4 className="h4">A conceptual framework</h4>
                          <ul>
                            <li aria-hidden="true">
                              <strong aria-hidden="true">
                                Chapter Openers
                              </strong>{" "}
                              feature a question answered with a clear, simple
                              image to help students visualize and remember
                              concepts as they move through the chapter. Each
                              opener includes a study tip.
                            </li>
                            <li aria-hidden="true">
                              <strong aria-hidden="true">
                                Test bank questions{" "}
                              </strong>
                              have been analyzed and revised for how students
                              read, analyze and engage with the content,
                              providing a rebuilt assessment program.
                            </li>
                          </ul>
                          <h4 className="h4" aria-hidden="true">
                            Key scientific skills
                          </h4>
                          <ul>
                            <li aria-hidden="true">
                              <strong aria-hidden="true">
                                Science in the Classroom{" "}
                              </strong>
                              presents assignable tutorials in Mastering Biology
                              with annotated journal articles from American
                              Association for the Advancement of Science (AAAS)
                              to make reading and understanding primary
                              literature easier.
                            </li>
                            <li aria-hidden="true">
                              <strong aria-hidden="true">
                                Reading Guide worksheets
                              </strong>{" "}
                              support students in actively reading their biology
                              text. Downloadable worksheets guide their reading
                              to focus them on key information.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sc-f263d653-1 edqyDW show-more">
                  <div className="sc-f263d653-2 cRxKch show-more-line-left"></div>
                  <button
                    aria-labelledby="show-more title-overview"
                    aria-controls="ce4a77ef-d00e-4b66-b323-4196d8dbd852-hiddenBlock"
                    aria-describedby="show-more-instruction"
                    className="sc-f263d653-3 fuFZoe"
                  >
                    <div id="show-more" className="sc-f263d653-4 fzAlCI">
                      Show more
                    </div>
                    <div className="sc-f263d653-5 wHitE"></div>
                    <span
                      id="show-more-instruction"
                      className="sc-f263d653-6 hOyGYP"
                    >
                      content will be revealed above
                    </span>
                  </button>
                  <div className="sc-f263d653-2 cRxKch show-more-line-right"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="sc-6f0ba9ca-6 sc-e164736c-3 kTLsML foumiy p"></div>
        </div>
      </div>
    </div>
  );
};

export default TitleDetailsContent;
