import campbellBiology from "../../assets/images/biology-flower.png";
import campbellBiologyInFocus from "../../assets/images/biology-lizard.png";
import campbellBiologyConceptsConnections from "../../assets/images/biology-giraffe.png";
import biologicalScience from "../../assets/images/biological-science.png";

const pearsonData = {
  titles: [
    {
      value: "1",
      label: "Campbell Biology",
      authors:
        "Lisa A. Urry, Michael L. Cain, Steven A. Wasserman, Peter V. Minorsky, Rebecca Orr",
      imagePath: campbellBiology,
      edition: "12th",
      isbn: "Multiple ISBNs available",
      lmsTemplateName: "Mastering Biology",
      lmsTemplateCreator: "John Smith",
    },
    {
      value: "2",
      label: "Campbell Biology in Focus",
      authors:
        "Lisa A. Urry, Michael L. Cain, Steven A. Wasserman, Peter V. Minorsky",
      imagePath: campbellBiologyInFocus,
      edition: "3rd",
      isbn: "Multiple ISBNs available",
    },
    {
      value: "3",
      label: "Campbell Biology: Concepts & Connections",
      authors: "Martha R Taylor, Eric J. Simon, Jean L. Dickey, Kelly A. Hogan",
      imagePath: campbellBiologyConceptsConnections,
      edition: "10th",
      isbn: "Multiple ISBNs available",
    },
    {
      value: "4",
      label: "Biological Science",
      authors:
        "Scott Freeman, Kim Quillin, Lizabeth Allison, Michael Black, Greg Podgorski, Emily Taylor, Jeff Carmichael",
      imagePath: biologicalScience,
      edition: "7th",
      isbn: "Multiple ISBNs available",
    },
  ],
  courses: [
    {
      value: "1",
      courseName: "Biology 101 Section 8",
      imagePath: campbellBiology,
      bookName: "Campbell Biology",
      authors:
        "Lisa A. Urry, Michael L. Cain, Steven A. Wasserman, Peter V. Minorsky, Rebecca Orr",
      edition: "12th",
      isbn: "Multiple ISBNs available",
      dates: "01/06/2020 - 4/24/2020",
      lmsTemplateCourse: "Biology 101 - Fall 2024 Template Course",
      lmsTemplateName: "Mastering Biology",
      lmsTemplateCreator: "John Smith",
    },
    {
      value: "2",
      courseName: "Biology 101 Section 7",
      imagePath: campbellBiology,
      bookName: "Campbell Biology",
      authors:
        "Lisa A. Urry, Michael L. Cain, Steven A. Wasserman, Peter V. Minorsky, Rebecca Orr",
      edition: "12th",
      isbn: "Multiple ISBNs available",
      dates: "01/14/2021 - 5/13/2021"
    },
    {
      value: "3",
      courseName: "Introduction to Biology",
      imagePath: biologicalScience,
      bookName: "Biological Science",
      authors:
        "Scott Freeman, Kim Quillin, Lizabeth Allison, Michael Black, Greg Podgorski, Emily Taylor, Jeff Carmichael",
      edition: "7th",
      isbn: "Multiple ISBNs available",
      dates: "08/15/2021 - 2/14/2021"
    },
    {
      value: "4",
      courseName: "Biology 101 Spring 2022",
      imagePath: biologicalScience,
      bookName: "Biological Science",
      authors:
        "Scott Freeman, Kim Quillin, Lizabeth Allison, Michael Black, Greg Podgorski, Emily Taylor, Jeff Carmichael",
      edition: "7th",
      isbn: "Multiple ISBNs available",
      dates: "01/15/2022 - 5/14/2022"
    },
    {
      value: "5",
      courseName: "Biology 101 Spring 2022",
      imagePath: biologicalScience,
      bookName: "Biological Science",
      authors:
        "Scott Freeman, Kim Quillin, Lizabeth Allison, Michael Black, Greg Podgorski, Emily Taylor, Jeff Carmichael",
      edition: "7th",
      isbn: "Multiple ISBNs available",
      dates: "01/15/2022 - 5/14/2022"
    },
    {
      value: "6",
      courseName: "Biology 101 - Spring 2024 Template",
      imagePath: campbellBiology,
      bookName: "Campbell Biology",
      authors:
        "Lisa A. Urry, Michael L. Cain, Steven A. Wasserman, Peter V. Minorsky, Rebecca Orr",
      edition: "12th",
      isbn: "Multiple ISBNs available",
      dates: "01/15/2024 - 5/10/2024"
    },
  ],
};

export default pearsonData;
