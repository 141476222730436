import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {setUser } from "../../../redux/slices/userSlice";

import iconInfo from '../../../assets/images/icons/icon_ info.png';

const CreateCourseContent2 = (props) => {
    const dispatch = useDispatch();
    
    const userData = useSelector((state) => state.user);

    const [courseName, setCourseName] = useState(userData.courseName);
    const [courseTimezone, setCourseTimezone] = useState(userData.courseTimezone);
    const [courseStart, setCourseStart] = useState(userData.courseStart);
    const [courseEnd, setCourseEnd] = useState(userData.courseEnd);
    const [enrollStart, setEnrollStart] = useState(userData.enrollStart);
    const [enrollEnd, setEnrollEnd] = useState(userData.enrollEnd);

    const formSubmitHandler = async (event) => {
        await dispatch(setUser({ 
            courseName: courseName, 
            courseTimezone: courseTimezone, 
            courseStart: courseStart, 
            courseEnd: courseEnd, 
            enrollStart: enrollStart, 
            enrollEnd: enrollEnd }));
        props.confirmCreateCrouse();
        //navigate(process.env.PUBLIC_URL + '/setup/course_info');
    }

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 80);
      }, []);

    return (
        <section className="create-course-form">
            <p>
                We've auto-filled your settings from your school catalog. Verify this information and make adjustments to match your course. This information will be seen by your students.
            </p>

            <form 
            // onSubmit={formSubmitHandler}
            >
                <div className="form-control">
                    <label htmlFor="course-name">Course name <img src={iconInfo} alt='' /></label>
                    <input type="text" id="course-name" defaultValue={courseName} onChange={(e) => setCourseName(e.target.value)} />
                </div>
                <div className="form-control">
                    <label htmlFor="course-timezone">Course timezone <img src={iconInfo} alt='' /></label>
                    <select id="course-timezone" defaultValue={courseTimezone} onChange={(e) => setCourseTimezone(e.target.value)}>
                        <option value="Pacific/Honolulu">Pacific/Honolulu GMT-10:00</option>
                        <option value="America/Anchorage">America/Anchorage GMT-9:00</option>
                        <option value="America/Los_Angeles">America/Los_Angeles GMT-8:00</option>
                        <option value="America/Boise">America/Boise GMT-7:00</option>
                        <option value="America/Denver">America/Denver GMT-7:00</option>
                        <option value="America/Phoenix">America/Phoenix GMT-7:00</option>
                        <option value="America/Chicago">America/Chicago GMT-6:00</option>
                        <option value="America/Detroit">America/Detroit GMT-5:00</option>
                        <option value="America/New_York">America/New_York GMT-5:00</option>
                    </select>
                </div>

                <div className="dates">
                    <div className="form-control">
                        <label htmlFor="start-date">Course Start Date <img src={iconInfo} alt='' /></label>
                        <input type="date" id="start-date" defaultValue={courseStart} onChange={(e) => setCourseStart(e.target.value)} />
                    </div>
                    <div className="form-control">
                        <label htmlFor="end-date">Course End Date <img src={iconInfo} alt='' /></label>
                        <input type="date" id="edn-date" defaultValue={courseEnd} onChange={(e) => setCourseEnd(e.target.value)} />
                    </div>
                    <div className="form-control">
                        <label htmlFor="enroll-start-date">Enroll Start Date <img src={iconInfo} alt='' /></label>
                        <input type="date" id="enroll-start-date" defaultValue={enrollStart} onChange={(e) => setEnrollStart(e.target.value)} />
                    </div>
                    <div className="form-control">
                        <label htmlFor="enroll-end-date">Course start date <img src={iconInfo} alt='' /></label>
                        <input type="date" id="enroll-end-date" defaultValue={enrollEnd} onChange={(e) => setEnrollEnd(e.target.value)} />
                    </div>
                </div>

                <div className="form-action">
                    <button 
                        type='submit'
                        className="primary"
                        onClick={formSubmitHandler}
                    >
                            Next
                        </button>
                </div>
            </form>
        </section>
    );
};

export default CreateCourseContent2;