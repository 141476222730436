import React from "react";
import { Link } from "react-router-dom";
import books from "../../../../assets/images/books.png";

const CourseLaunch = () => {
  return (
    <div className="lms-launch">
      <main>
        <div className="image-group">
          <img width="293px" height="283px" src={books} alt="" />
          <p>Select Open Pearson to see your content</p>
        </div>

        <div className="course-group">
          <Link
            to={`${process.env.PUBLIC_URL}/lms/title/select`}
            target="_blank"
            className="launch-btn"
          >
            Open Link
          </Link>
        </div>
      </main>
    </div>
  );
};

export default CourseLaunch;
