import { createSlice } from "@reduxjs/toolkit";
import {
  getCollegeReducer,
  setDefaultCollege,
  updateValidatedSchools,
  setCollegeEmails,
  updateSchoolProperties,
  createSchoolProperties
} from "../reducers/collegeReducer";

// import navbarData from "../data/navbarData";
import collegeData from "../data/collegeData";


/********************************
 * Configure Slice with reducers
 * and Export actions and reducer
 *******************************/
const collegeSlice = createSlice({
  name: "college",
  initialState: collegeData,
  reducers: {
    getCollege: getCollegeReducer, 
    setDefault: setDefaultCollege,
    updateValidated: updateValidatedSchools,
    setEmails: setCollegeEmails,
    updateSchool: updateSchoolProperties,
    createSchool: createSchoolProperties,
  },
});

// Note: pearsonSlice.actions automatically creates actions based on our reducer names
export const { 
  getCollege, 
  setDefault,
  updateValidated,
  setEmails,
  updateSchool,
  createSchool,
} = collegeSlice.actions;

// export  reducer, so it can be added to the store
export default collegeSlice.reducer;