
const SetupSteps = ({flow, step}) => {
    return(
        <ul className='step-indicator'>
            {flow !== "lms/setup/product" ? 
            (<>
            <li className={`${step === 0 ? 'selected' : ''}`}>
                <div>Step 1</div>
                <div>Choose Product Options</div>
            </li>
            <li className={`${step === 1 ? 'selected' : ''}`}>
                <div>Step 2</div>
                <div>Add Course Information</div>
            </li>
            <li className={`${step === 2 ? 'selected' : ''}`}>
                <div>Step 3</div>
                <div>Create Course</div>
                {/*<div>Connect to Bookstore</div>*/}
            </li>
            <li className={`${step === 3 ? 'selected' : ''}`}>
                <div>Step 4</div>
                <div>Send to Bookstore</div>
                {/*<div>Connect to LMS</div>*/}
            </li></>) :(<>
                <li className={`${step === 1 ? 'selected' : ''}`}>
                <div>Step 1</div>
                <div>Create Course</div>
            </li>
            <li className={`${step === 2 ? 'selected' : ''}`}>
                <div>Step 2</div>
                {/* {console.log("FLOW:", flow)} */}
                <div>Connect to Bookstore</div>
            </li></>)}
        </ul>
    )
}

export default SetupSteps;