import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const ConnectBookstoreContent3 = ({
  flow,
  connectToBookStore,
  lmsConnectToBookStore,
  currentStep,
  currentSubStep,
}) => {
  const userData = useSelector((state) => state.user);
  // const lmsMessage = "Sending title information to the book store. This may take a few seconds..."
  //const lmsMessage = `Would you like to sync this information to the ${userData.lmsSchoolName} bookstore?`;
  //const nonLmsMessage = `Let your bookstore know you will be using this title`;

  //   console.log("USERData:", userData);
  const handleConnectToBookstore = (event) => {
    const connectingLmsBookstoreAnimations =
      flow === "lms/setup/product" && currentStep === 2 && currentSubStep === 5;
    console.log(
      "CourseInfoContent: currentStep:",
      currentStep,
      "CurrentSubstep:",
      currentSubStep
    );
    console.log(
      "connectingLmsBookstoreAnimations",
      connectingLmsBookstoreAnimations
    );
    if (connectingLmsBookstoreAnimations) {
      lmsConnectToBookStore(event);
    } else {
      // connectToBookStore(currentStep, currentSubStep);
      connectToBookStore(event);
    }
  };
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 80);
  }, []);

  return (
    <section className="connect-to-bookstore">
    {/*  <p>{flow === "lms/setup/product" ? lmsMessage : nonLmsMessage}</p> */}
    <p>Let your bookstore know you will be using this title.</p>
    <p>Pearson can send your title information to the {flow === "lms/setup/product" ? userData.lmsSchoolName : userData.schoolName} bookstore on your behalf. Your school uses Barnes and Noble Adoption and Insights, once the title is accepted by your bookstore you will receive an email verification from Barnes and Noble.</p>
    <p>Would you like to send your title adoption information to the bookstore?</p>
      <div className="step-actions">
        <button className="disabled secondary" disabled>Not at this time</button>
        <button className="primary" onClick={handleConnectToBookstore}>
          Yes
        </button>
      </div>
    </section>
  );
};

export default ConnectBookstoreContent3;
