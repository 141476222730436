import React, { useState, useEffect } from "react";
import displaySvgIcon from "../../../utilities/displaySvgIcon";
import { Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomChip from "../output/CustomChip";

const InputChips = ({
  register,
  className,
  id,
  testId,
  name,
  label,
  // value,
  changeHandler,
  ref,
  hiddenClass,
  labelClass,
  displayType,
  type,
  required,
  placeholder,
  setChipLabels,
}) => {
  const dispatch = useDispatch();

  const [chips, setChips] = useState([]);
  const [count, setCount] = useState(0);
  const [inputValue, setInputValue] = useState("");

  /**
   * handleDelete removes a chip from the chips array.
   * @param {Object} deletedChip - The chip to be deleted
   */
  const handleDelete = (deletedChip) => {
    const newChips = chips.filter((chip) => chip.key !== deletedChip.key);
    setChips(newChips);
  };

  /**
   * handleKeyPress checks for Enter key press to add new chip.
   * @param {Event} event - The keydown event
   */
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setInputValue(event.currentTarget.value);
      event.currentTarget.value = "";
    }
  };

  /**
   * useEffect hook to add new chip when inputValue changes.
   */
  useEffect(() => {
    if (inputValue && count > -1) {
      setCount((prev) => prev + 1);
      const newChip = { label: inputValue, key: `${inputValue}-${Date.now()}` };
      // Extract just the label values

      // console.log("Label Values:", labelValues);
      // console.log("Chips:", chips);

      setChips([...chips, newChip]);
      setInputValue(""); // Clear the input value after adding the chip
    }
  }, [chips.length, inputValue]);

  useEffect(() => {
    const chipLabels = chips.slice(0, 4).map((chip) => chip.label);
    console.log("Label Values:", chipLabels);
    setChipLabels(chipLabels);
  }, [chips.length]);

  return (
    <>
      <div className={displayType}>
        <div
          className={`col-auto ${labelClass} ${
            hiddenClass ? hiddenClass : ""
          } `}
        >
          <label htmlFor={id}>
            {label}
            <span className="label-icon">
              {displaySvgIcon("question-mark")}
            </span>
          </label>
        </div>
        <div className="col">
          <input
            register={register}
            name="chip-input"
            className="chip-input"
            placeholder={placeholder}
            onKeyDown={(e) => {
              handleKeyPress(e);
            }}
          />
          {/* // col */}
        </div>
        {console.log("Length:", chips.length)}
        {chips?.length > 0 ? (
          <div className="chip-container">
            {chips.map((chip) => (
              <CustomChip
                key={chip.key}
                label={chip.label}
                onDelete={() => handleDelete(chip)}
              />
              // <Chip
              //   key={chip.key}
              //   label={chip.label}
              //   onDelete={() => handleDelete(chip)}
              // />
            ))}
          </div>
        ) : (
          <></>
        )}
        {/* ROW */}
      </div>
    </>
  );
};

export default InputChips;
