import React from "react";
import SchoolDisplayCard from "../../../start/AccountSettings/partials/SchoolEditCard";
import Validated from "../../../../global/output/Validated";
import ActiveTag from "../../../../global/output/ActiveTag";

const SchoolEditCard = ({ school, active }) => {
  // console.log(
  //   "School:",
  //   school,
  //   "Active:",
  //   active,
  //   "setting:",
  //   active ? "active" : "inactive"
  // );
  return (
    <div className="col">
      <div className="school-data">
        <span className="inactive">{school?.label}</span>
        <div className="val-role">
          {school?.validated ? <Validated /> : <></>}{" "}
          <span className="school-edit-text inactive">{school?.role}</span>
        </div>
        <span className="school-edit-text inactive">{school?.email}</span>
      </div>
    </div>
  );
};

export default SchoolEditCard;
